import { FormControlLabel, Switch } from '@material-ui/core';
import { default as React, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import { ProjectType } from '../../../constants/Enums.js';
import {
  addProject,
  exportProject,
  setProject,
} from '../../../redux/actions/projects.js';
import store from '../../../redux/store';
/**
 * Export.
 *
 * Modal for exporting
 *
 * @param modal: parameter for the modal
 * @param toggle: paramater for the toggle effect of the modal
 */
export const ExportModal = ({
  modal,
  toggle,
  assets,
  type,
  project: currentProject,
  projectRow: exportedProject,
  loading,
}) => {
  const { dispatch } = store;
  const [formData, setFormData] = useState({
    project_page: false,
    asset_page: false,
    threat_page: false,
    threat_scenario_page: false,
    vulnerability_page: false,
    control_page: false,
    risk_statistics_page: false,
    analysis_tree_page: false,
    assets: undefined,
  });

  const [entityToggle, setEntityToggle] = useState(false);

  const [filename, setFilename] = useState(undefined);
  const [downloadUrl, setDownloadUrl] = useState(undefined);

  const downloadData = async () => {
    const downloadUrlToSet = await exportProject(
      formData,
      currentProject,
      filename
    );
    setDownloadUrl(downloadUrlToSet);
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const onAnalysisTreeChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
      project_page: event.target.checked,
      risk_statistics_page: false,
    });
  };

  useEffect(() => {
    if (
      modal &&
      currentProject?._id !== exportedProject?._id &&
      !loading &&
      exportedProject !== undefined
    ) {
      dispatch(setProject(exportedProject));
    }
  }, [modal]);

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Export Project</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          Please choose what to export:
          <Form>
            <SwitchForm
              onChange={onAnalysisTreeChange}
              label='Analysis tree'
              name='analysis_tree_page'
              checked={formData.analysis_tree_page}
            />
            {formData.analysis_tree_page && (
              <Fragment>
                <div style={{ marginLeft: 30 }}>
                  <SwitchForm
                    onChange={onChange}
                    label='Risk data'
                    name='risk_statistics_page'
                    checked={formData.risk_statistics_page}
                  />
                </div>
              </Fragment>
            )}
            <SwitchForm
              onChange={(e) => {
                setEntityToggle(e.target.checked);
                setFormData({
                  ...formData,
                  asset_page: false,
                  assets: undefined,
                  threat_page: false,
                  threat_scenario_page: false,
                  vulnerability_page: false,
                  control_page: false,
                });
              }}
              label='Entities'
              checked={entityToggle}
            />
            {entityToggle && (
              <Fragment>
                <div style={{ marginLeft: 30 }}>
                  <FormGroup>
                    <Row>
                      <Col>
                        <FormControlLabel
                          control={
                            <Switch
                              name='asset_page'
                              onChange={(event) => {
                                setFormData({
                                  ...formData,
                                  [event.target.name]: event.target.checked,
                                  assets: undefined,
                                });
                              }}
                            />
                          }
                          label='Asset data'
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {formData.asset_page && (
                    <FormGroup>
                      <Input
                        type='select'
                        multiple={true}
                        name='assets'
                        onChange={(event) => {
                          const values = Array.from(
                            event.target.selectedOptions,
                            (option) => parseInt(option.value)
                          );
                          setFormData({
                            ...formData,
                            assets: values,
                          });
                        }}
                      >
                        {assets?.map((asset) => {
                          return (
                            <option value={asset._id} selected>
                              {asset.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  )}
                  <SwitchForm
                    onChange={onChange}
                    label={`${
                      ProjectType[currentProject?.project_type].ThreatType
                    } data`}
                    name='threat_page'
                    checked={formData.threat_page}
                  />
                  {currentProject?.project_type ===
                    ProjectType.ISO_21434.Value && (
                    <SwitchForm
                      onChange={onChange}
                      label='Threat scenario data'
                      name='threat_scenario_page'
                      checked={formData.threat_scenario_page}
                    />
                  )}
                  <SwitchForm
                    onChange={onChange}
                    label='Vulnerability data'
                    name='vulnerability_page'
                    checked={formData.vulnerability_page}
                  />
                  <SwitchForm
                    onChange={onChange}
                    label='Control data'
                    name='control_page'
                    checked={formData.control_page}
                  />
                </div>
              </Fragment>
            )}
            <FormGroup>
              <Label>Filename</Label>
              <Input
                type='text'
                onChange={(event) => setFilename(`${event.target.value}.xlsx`)}
              />
            </FormGroup>
            <FormGroup>
              <Button
                className='float-right btn-add'
                onClick={() => {
                  downloadData();
                }}
                disabled={
                  //check if filename only has .xlsx
                  filename?.trim().length === 5 ||
                  filename === undefined ||
                  (formData.analysis_tree_page === false &&
                    formData.asset_page === false &&
                    formData.threat_page === false &&
                    formData.threat_scenario_page === false &&
                    formData.vulnerability_page === false &&
                    formData.control_page === false)
                }
              >
                Export
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
          <Form>
            <br />
            <br />
            <div>
              {downloadUrl ? (
                <>
                  <Label>Download File: </Label>
                  <h3>
                    <a
                      download={filename}
                      href={downloadUrl}
                      rel='noopener noreferrer'
                    >
                      {filename}
                    </a>
                  </h3>

                  <Label>
                    Right-click this link and choose Save link as to choose
                    download location or change filename
                  </Label>
                </>
              ) : null}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.project.loading,
});

export default withRouter(
  connect(mapStateToProps, { addProject })(ExportModal)
);

const SwitchForm = ({ onChange, name, label, checked }) => {
  return (
    <FormGroup>
      <Row>
        <Col>
          <FormControlLabel
            control={
              <Switch name={name} onChange={onChange} checked={checked} />
            }
            label={label}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};
