import axios from '../../Axios/axios';
import { DefaultErrorHandling } from '../../components/helpers/genericHelper';
import { CatalogType, ProjectRole } from '../../constants/Enums';
import store from '../store';
import { setAlert } from './alert';
import { getComments } from './comments';
import { setEntity } from './entities';
import { getAdminCatalogs, getControlCatalogs, getReftrees } from './profile';
import { setProject } from './projects';
import {
  ADD_CATALOG_BASELINE,
  ADD_CATALOG_ENTRY,
  ADD_NEW_CATALOG,
  ADD_NEW_PROJECT_CATALOG,
  ADD_NEW_REFTREE,
  ADD_REFTREES,
  DELETE_CATALOG,
  DELETE_CATALOG_BASELINE,
  DELETE_PROJECT_SPECIFIC_CATALOG,
  DELETE_REFTREE,
  LOAD_CATALOG_BASELINE,
  LOAD_ENTITIES,
  REVERT_CATALOG_BASELINE,
  SET_CATALOG,
  SET_CATALOG_BASELINE_MODE,
  SET_CATALOG_ROLE,
  SET_ENTRY,
  SET_REFTREES,
  UPDATE_CATALOG
} from './types';

export const addNewCatalog = (payload) => async (dispatch) => {
  const newCatalog = await axios.catalog.put('', payload).catch((error) => {
    dispatch(
      setAlert(
        `Error creating a new catalog. Error message: ${
          error?.response?.data?.msg ?? error?.response?.data?.message
        }`,
        'danger'
      )
    );
  });

  dispatch({
    payload: newCatalog.data,
    type: ADD_NEW_CATALOG,
  });

  dispatch(setAlert('Catalog successfully added', 'success'));
};

export const addNewProjectCatalog = (payload) => async (dispatch) => {
  const newCatalog = await axios.catalog.put('', payload).catch((error) => {
    dispatch(
      setAlert(
        `Error creating a new project catalog. Error message: ${
          error?.response?.data?.msg ?? error?.response?.data?.message
        }`,
        'danger'
      )
    );
  });

  dispatch({
    payload: newCatalog.data,
    type: ADD_NEW_PROJECT_CATALOG,
  });

  dispatch(setAlert('Catalog successfully added', 'success'));
};

export const deleteProjectCatalog = (payload) => async (dispatch) => {
  axios.catalog
    .delete(`${payload._id}?method=replace`)
    .then((response) => {
      dispatch({
        type: DELETE_PROJECT_SPECIFIC_CATALOG,
        payload: payload,
      });
      dispatch(setAlert('Catalog successfully deleted', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const copyCatalog = (catalogId, payload) => async (dispatch) => {
  const newPayload = {
    name: payload?.name,
    description: payload?.description,
    project_id: payload?.project_id,
  };
  const newCatalog = await axios.catalog
    .post(catalogId, newPayload)
    .catch((error) => {
      dispatch(
        setAlert(
          `EError copying catalog. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
  dispatch({
    type: ADD_NEW_CATALOG,
    payload: newCatalog.data.copied_catalog,
  });
  dispatch({
    type: ADD_REFTREES,
    payload: newCatalog.data.reftrees,
  });
};

export const setBaselineCatalog = (payload, baselineId) => async (dispatch) => {
  axios.catalog
    .get(`${payload._id}/baselines/${baselineId}`)
    .then(async (response) => {
      const baselineData = response.data.data;
      const baselineCatalog = baselineData.Catalog[0];
      const baselineReftrees = baselineData.RefTree;
      const baselineEntities = baselineData.Entity;

      dispatch({
        type: SET_CATALOG_BASELINE_MODE,
        payload: true,
      });

      dispatch({
        type: SET_CATALOG,
        payload: baselineCatalog,
      });
      dispatch({
        type: ADD_NEW_CATALOG,
        payload: baselineCatalog,
      });

      dispatch({
        type: SET_CATALOG_ROLE,
        payload: ProjectRole.Reader.value,
      });

      const state = store.getState();

      //Get all reftrees and replace with baseline entities
      const reftrees = Object.assign([], state.profile.userReftrees);
      baselineReftrees.map((entity) => {
        const item = reftrees.find((item) => item._id === entity._id);
        item === undefined && reftrees.push(entity);
        return entity;
      });
      reftrees.map(
        (reftree) =>
          baselineReftrees.find(
            (replacement) => replacement._id === reftree._id
          ) || reftree
      );
      dispatch({
        type: SET_REFTREES,
        payload: reftrees,
      });

      //Get all entities and replace with baseline entities
      const entities = Object.assign([], state.entities.entities);
      baselineEntities.map((entity) => {
        const item = entities.find((item) => item._id === entity._id);
        item === undefined && entities.push(entity);
        return entity;
      });
      entities.map(
        (entity) =>
          baselineEntities.find(
            (replacement) => replacement._id === entity._id
          ) || entity
      );
      dispatch({
        type: LOAD_ENTITIES,
        payload: entities,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const setCatalog = (payload) => async (dispatch) => {
  axios.catalog
    .get(payload._id)
    .then(async (response) => {
      dispatch({
        type: SET_CATALOG_BASELINE_MODE,
        payload: false,
      });

      const catalog = await getComments(response.data.catalog);
      dispatch({
        type: SET_CATALOG,
        payload: catalog,
      });
      dispatch({
        type: ADD_NEW_CATALOG,
        payload: catalog,
      });
      if (catalog?.project_id !== undefined) {
        dispatch(setProject({ _id: catalog.project_id }, undefined, false));
      }

      const state = store.getState();
      const profileId = state.profile?.profile?.id;
      const catalogUser = response.data?.catalog?.participants?.find(
        (user) => parseInt(user.user_id) === parseInt(profileId)
      );
      dispatch({
        type: SET_CATALOG_ROLE,
        payload: catalogUser?.role,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const updateCatalog = (id, payload) => async (dispatch) => {
  axios.catalog
    .patch(id, payload)
    .then(async (response) => {
      const payload = response.data;
      dispatch({
        type: UPDATE_CATALOG,
        payload: payload,
      });
      dispatch(setAlert('Catalog successfully updated', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const setCatalogEntry = (entry, catalog) => async (dispatch) => {
  axios.refTree
    .get(`${entry._id}?creation_date=false`)
    .then((entry) => {
      dispatch(
        setEntity({
          _id: entry.data.current_version,
        })
      );

      dispatch({
        type: SET_ENTRY,
        payload: entry.data,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const updateEntry = (id, payload) => async (dispatch) => {
  axios.refTree
    .patch(id, payload)
    .then(async (response) => {
      const payload = response.data;
      dispatch({
        type: SET_ENTRY,
        payload: payload.updated_reftree,
      });
      const state = store.getState();
      const userReftrees = state.profile.userReftrees;
      if (userReftrees !== undefined) {
        const reftrees = [...userReftrees];
        const index = reftrees.findIndex(
          (ref) => parseInt(ref._id) === parseInt(id)
        );
        reftrees[index] = payload.updated_reftree;
        dispatch({
          type: SET_REFTREES,
          payload: reftrees,
        });
      } else {
        dispatch(getReftrees());
      }
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const deleteCatalog = (payload) => async (dispatch) => {
  axios.catalog
    .delete(`${payload._id}?method=replace`)
    .then((response) => {
      dispatch({
        type: DELETE_CATALOG,
        payload: payload,
      });
      if (payload?.project_id !== undefined) {
        dispatch({
          type: DELETE_PROJECT_SPECIFIC_CATALOG,
          payload: payload,
        });
      }
      dispatch(setAlert('Catalog successfully deleted', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const addNewCatalogEntry =
  (payload, formData = undefined, catalogType) =>
  async (dispatch) => {
    if (catalogType === CatalogType.ReferenceTree) {
      const entry = payload;
      axios.refTree
        .put('', entry)
        .then((response) => {
          formData.owner = {
            id: response.data.new_reftree._id,
            owner_type: 'reftree',
          };
          axios.entity
            .put('', { vulnerability: formData })
            .then(() => {
              AddReftreeAndCatalogEntries(dispatch, response);
            })
            .catch((error) => {
              dispatch(
                setAlert(
                  `Error creating Reference Tree vulnerability. Error message: ${
                    error?.response?.data?.msg ?? error?.response?.data?.message
                  }`,
                  'danger'
                )
              );
            });
        })
        .catch((error) => {
          dispatch(
            setAlert(
              `Error adding new Reference Tree. Error message: ${
                error?.response?.data?.msg ?? error?.response?.data?.message
              }`,
              'danger'
            )
          );
        });
    } else {
      const entry = payload;
      axios.refTree
        .put('', entry)
        .then((response) => {
          formData.owner = {
            id: response.data.new_reftree._id,
            owner_type: 'reftree',
          };
          axios.entity.put('', { control: formData }).then(() => {
            AddReftreeAndCatalogEntries(dispatch, response);
          });
        })
        .catch((error) => {
          dispatch(
            setAlert(
              `Error adding new Control Catalog entry. Error message: ${
                error?.response?.data?.msg ?? error?.response?.data?.message
              }`,
              'danger'
            )
          );
        });
    }
  };

const AddReftreeAndCatalogEntries = (dispatch, response) => {
  dispatch({
    type: ADD_NEW_REFTREE,
    payload: response.data.new_reftree,
  });
  dispatch({
    type: ADD_CATALOG_ENTRY,
    payload: response.data.new_reftree._id,
  });
};

export const deleteEntry = (payload) => async (dispatch) => {
  axios.refTree
    .delete(`${payload._id}?method=replace`)
    .then(() => {
      dispatch({
        type: DELETE_REFTREE,
        payload: payload._id,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error deleting entry. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const addMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_add: [user] };
  axios.catalog
    .patch(id, payload)
    .then((response) => {
      dispatch({
        type: SET_CATALOG,
        payload: response.data,
      });
      dispatch(setAlert('User successfully added to the catalog', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const adminAddMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_add: [user] };
  const state = store.getState();
  axios.catalog
    .patch(id, payload)
    .then(async (response) => {
      if (state.catalog?.catalog?._id === id) {
        const catalog = await getComments(response.data);

        const currentCatalog = state.catalog.catalog;
        if (catalog._id === currentCatalog._id) {
          dispatch(setCatalog(catalog));
        }
      }
      dispatch(getControlCatalogs());
      dispatch(getReftrees());
      dispatch(getAdminCatalogs());
      dispatch(setAlert('User has been added to the Catalog', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const updateMember = (id, member) => async (dispatch) => {
  const payload = { participants_to_add: [member] };
  axios.catalog
    .patch(id, payload)
    .then((response) => {
      dispatch({
        type: UPDATE_CATALOG,
        payload: response.data,
      });
      const state = store.getState();
      const profileId = state.profile?.profile?.id;
      if (member.user_id === profileId) {
        dispatch({
          type: SET_CATALOG_ROLE,
          payload: member?.role,
        });
      }
      dispatch({
        type: SET_CATALOG,
        payload: response.data,
      });

      dispatch(setAlert('User successfully updated', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const adminUpdateMember = (id, member) => async (dispatch) => {
  const state = store.getState();
  const payload = { participants_to_add: [member] };
  axios.catalog
    .patch(id, payload)
    .then(async (response) => {
      if (state.catalog?.catalog?._id === id) {
        const catalog = await getComments(response.data);

        const currentCatalog = state.catalog.catalog;
        if (catalog._id === currentCatalog._id) {
          dispatch(setCatalog(catalog));
        }
      }
      dispatch(getControlCatalogs());
      dispatch(getReftrees());
      dispatch(getAdminCatalogs());
      dispatch(setAlert('User successfully updated', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const deleteMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_remove: [user] };
  await axios.catalog
    .patch(id, payload)
    .then((response) => {
      dispatch({
        type: SET_CATALOG,
        payload: response.data,
      });
      dispatch(
        setAlert('User successfully deleted from the catalog', 'success')
      );
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const adminDeleteMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_remove: [user] };
  const state = store.getState();
  await axios.catalog
    .patch(id, payload)
    .then(async (response) => {
      if (state.catalog?.catalog?._id === id) {
        const catalog = await getComments(response.data);

        const currentCatalog = state.catalog.catalog;
        if (catalog._id === currentCatalog._id) {
          dispatch({
            type: SET_CATALOG,
            payload: response.data,
          });
        }
      }
      dispatch(getControlCatalogs());
      dispatch(getReftrees());
      dispatch(getAdminCatalogs());
      dispatch(
        setAlert('User successfully deleted from the catalog', 'success')
      );
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const getCurrentVersion = async (id) => {
  const { dispatch } = store;
  return axios.refTree
    .get(id)
    .then((response) => {
      return response.data.current_version;
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const getCatalogBaselines = (catalogId) => async (dispatch) => {
  const requestString = `${catalogId}/baselines/`;
  axios.catalog
    .get(requestString)
    .then((response) => {
      dispatch({
        type: LOAD_CATALOG_BASELINE,
        payload: response.data,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const addCatalogBaseline = (payload) => async (dispatch) => {
  const state = store.getState();
  const catalog = state.catalog.catalog;
  const requestString = `${catalog._id}/baselines/`;
  axios.catalog
    .put(requestString, payload)
    .then((response) => {
      dispatch({
        type: ADD_CATALOG_BASELINE,
        payload: {
          ...payload,
          creation_date: new Date(),
          baseline_id: response.data.baseline_id,
        },
      });
      dispatch(setAlert('Baseline successfully added', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const revertCatalogBaseline = (version) => async (dispatch) => {
  const payload = { baseline_version: version };
  const state = store.getState();
  const catalog = state.catalog.catalog;
  const requestString = `${catalog._id}/baselines/`;
  axios.catalog
    .patch(requestString, payload)
    .then(() => {
      dispatch({
        type: REVERT_CATALOG_BASELINE,
        payload: payload,
      });
      dispatch(setAlert('Baseline has been reverted', 'success'));
    })
    .catch((error) => {
      dispatch(
        setAlert(
          error?.response?.data?.msg ?? error?.response?.data?.message,
          'danger'
        )
      );
    });
};

export const deleteCatalogBaseline = (baselineId) => async (dispatch) => {
  const state = store.getState();
  const catalog = state.catalog.catalog;
  const requestString = `${catalog._id}/baselines/${baselineId}`;
  axios.catalog
    .delete(requestString)
    .then(() => {
      dispatch({
        type: DELETE_CATALOG_BASELINE,
        payload: baselineId,
      });
      dispatch(setAlert('Baseline successfully deleted', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};
