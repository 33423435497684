import { ControlLikelihoodType, RiskViewType } from '../../constants/Enums';
import { setAlert } from '../../redux/actions/alert';
import { idTypes } from '../routing';

//Template used for API calls concerning updating entities
export const entityUpdateTemplate = (entityType, payload) => {
  let object = {};
  if (entityType === 'asset') {
    object = {
      asset: { ...payload },
    };
  } else if (entityType === 'threat') {
    object = {
      threat: { ...payload },
    };
  } else if (entityType === 'vulnerability') {
    object = {
      vulnerability: { ...payload },
    };
  } else if (entityType === 'control') {
    object = {
      control: { ...payload },
    };
  }
  return object;
};

//Checks if an object has no properties
// {} but not undefined
export const isEmpty = (map) => {
  for (var key in map) {
    if (map.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

//Template used for API calls concerning copying or cutting subtrees from within an attack tree
export const copyPasteTemplate = (operation, sourceId, destinationId) => {
  return {
    operation: operation,
    sourceId: sourceId,
    destinationParentId: destinationId,
  };
};

//Filters an array by their name property, returns a new array
export const nameFiltering = (array, filterstring) => {
  if (array === undefined || array?.length < 1) {
    return [];
  }
  if (filterstring === '') {
    return array;
  }

  const filteredArray = [];

  array.map((item) => {
    if (item.name.toLowerCase().includes(filterstring.toLowerCase())) {
      filteredArray.push(item);
    }
    return item;
  });

  return filteredArray;
};

//Checks project if user is the only owner of a project
export const ownerDeletionCheck = (user, project) => {
  let onlyOwner = false;

  //Get user data in project
  const userInProject = project?.participants.find(
    (participant) => participant.user_id === user._id
  );

  //Continues if he is not an owner
  if (userInProject?.role !== 'owner') {
    return onlyOwner;
  }
  //If he is and is the only owner, disallow deletion
  else {
    if (
      project.participants.filter((participant) => participant.role === 'owner')
        .length === 1
    ) {
      onlyOwner = true;
      return onlyOwner;
    } else {
      return onlyOwner;
    }
  }
};

//Checks if the user has the specifc roles to allow/perform certain features
export const isPermitted = (user, project, roles) => {
  let permitted = false;
  //Checks if the current user is a team member in the project
  const projectsOfUser = project?.participants.find(
    (member) => member?.user_id === parseInt(user?.id)
  );

  //Checks if the user has the roles defined and sets the permission to true
  if (projectsOfUser?.role === roles) {
    permitted = true;
    return permitted;
  }
  return permitted;
};

const RFC5322Format =
  "([!#-'*+/-9=?A-Z^-~-]+(\\.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \\t]|(\\\\[\\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\\.[!#-'*+/-9=?A-Z^-~-]+)*|\\[[\\t -Z^-~]*])";
export const emailFormatAllowed = (email) => {
  const matched = email?.match(RFC5322Format);

  if (matched === null) {
    return false;
  }

  return matched[0] === email;
};

export const threatCounter = (
  threats,
  impact,
  feasibilityLower,
  feasibilityUpper,
  riskType,
  riskView = RiskViewType.All
) => {
  let threatCount = 0;

  threats.map((threat) => {
    if (threat?.risk === undefined) {
      return threat;
    }

    let highestImpact = 0;
    let impactCategories = [];
    if (threat.impact !== undefined) {
      Object.entries(threat.impact).map((impactCat) => {
        if (impactCat[1] > highestImpact) {
          highestImpact = impactCat[1];
        }
        return impactCat;
      });
      Object.entries(threat.impact).map((impactCat) => {
        if (highestImpact === impactCat[1]) {
          impactCategories = [...impactCategories, impactCat[0]];
        }
        return impactCat;
      });
    }
    Object.entries(threat?.risk).map(([riskCategory, riskValue]) => {
      //Conditionally checks if category impact is the highest if riskview is set to highest, otherwise accepts all threats
      if (
        threat?.impact?.[riskCategory] === impact &&
        (riskView === RiskViewType.Highest
          ? impactCategories.includes(riskCategory)
          : true)
      ) {
        //if feasibility matches, and impact selection is accepted, add threat count for table cell
        if (
          threat?.[ControlLikelihoodType[riskType]] >= feasibilityLower &&
          threat?.[ControlLikelihoodType[riskType]] <= feasibilityUpper
        ) {
          threatCount++;
        }
      }
      return [riskCategory, riskValue];
    });
    return threat;
  });
  return threatCount;
};

export const hasPermission = (userPermissions, permission) => {
  if (userPermissions === undefined || userPermissions?.length < 1) {
    return false;
  }

  return userPermissions.includes(permission);
};

export const FindPathFromRoutes = (routes, currentPath) => {
  const cleanedPath = currentPath.replace(/[0-9]/g, '');

  routes.map((route) => {
    const clearedRoutePath = clearPathIds(route.path);
    if (clearedRoutePath === cleanedPath) {
      currentPath = route.path;
    } else {
      if (route?.children !== null && route?.children !== undefined) {
        route.children.map((childRoute) => {
          if (clearPathIds(childRoute.path) === cleanedPath) {
            currentPath = childRoute.path;
          }
          return childRoute;
        });
      }
    }
    return route;
  });
  return currentPath;
};

const clearPathIds = (path) => {
  return path.replace(idTypes.project, '');
};

export const capitalizeStart = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const testJSON = (str) => {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
};

// Accepts a string, proper cases each word in the string
// Used for displaying human-readable change-log data
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function stringToDate(stringDate) {
  var newDate = new Date(stringDate);
  var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return newDate.toLocaleDateString(navigator.language, options);
}

export function renameKeys(data) {
  if (data !== undefined) {
    var renameKey = (key) => {
      var newKey = key.replace(/_/g, ' ');
      newKey = newKey[0] === ' ' ? newKey.slice(1) : newKey;
      return toTitleCase(newKey);
    };

    var newData = {};
    var keys = Object.keys(data);
    for (var i = 0; i < keys.length; i++) {
      newData[renameKey(keys[i])] =
        typeof data[keys[i]] !== 'object'
          ? keys[i] === 'creation_date' || keys[i] === 'last_modified'
            ? stringToDate(data[keys[i]])
            : data[keys[i]]
          : renameKeys(data[keys[i]]);
    }
    return newData;
  }
  return undefined;
}

export function setEntityUsage(designation, index) {
  return `${designation}(${index})`;
}

export const DefaultErrorHandling = (error, dispatch) => {
  dispatch(
    setAlert(
      error?.response?.data?.msg ?? error?.response?.data?.message,
      'danger'
    )
  );
};
