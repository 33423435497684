import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { addNewProjectCatalog } from '../../../redux/actions/catalog';
import DraggableModal from '../../entities/EntityModals/DraggableModal';

const AddProjectSpecificCatalog = ({
  toggle,
  modal,
  data,
  addNewProjectCatalog,
}) => {
  const [formData, setFormData] = useState({
    name: undefined,
    description: ' ',
    content_type: undefined,
    project_id: data._id,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const submit = () => {
    addNewProjectCatalog(formData);
  };

  return (
    <DraggableModal
      isOpen={modal}
      toggle={toggle}
      unmountOnClose={false}
      size='lg'
    >
      <ModalHeader>
        <div className='modal-header'>Add Project Specific Catalogue</div>
      </ModalHeader>
      <div className='modal-line' />
      <ModalBody className='modal-body'>
        <FormGroup>
          <Label>Name</Label>
          <Input type='text' name='name' onChange={onChange} />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input type='textarea' name='description' onChange={onChange} />
        </FormGroup>
        <FormGroup>
          <Label>Content Type</Label>
          <Input type='select' name='content_type' onChange={onChange}>
            <option disabled selected>
              Please Select Catalog Type
            </option>
            <option value={'control'}>Control Catalogue</option>
            <option value={'reftree'}>Reference Tree Catalogue</option>
          </Input>
        </FormGroup>
        <div align={'right'}>
          {' '}
          <ButtonGroup>
            <Button
              className='btn-add'
              onClick={(event) => {
                event.preventDefault();
                submit();
                toggle();
              }}
              disabled={
                formData.name === undefined ||
                formData.content_type === undefined
              }
            >
              Save
            </Button>
            <Button className='btn-danger' onClick={toggle}>
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </ModalBody>
    </DraggableModal>
  );
};

export default connect(null, { addNewProjectCatalog })(
  AddProjectSpecificCatalog
);
