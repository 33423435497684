import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, ModalBody, ModalHeader } from 'reactstrap';
import { EntityType } from '../../../constants/Enums';
import { addEntity } from '../../../redux/actions/entities';
import DraggableModal from './DraggableModal';
import DuplicateEntityModal from './DuplicateEntityModal';
import { InputTemplate } from './InputTemplate';

/**
 * Node Context Menu
 *
 * A modal for updating/creating assets
 *
 *  @param modal boolean to display/hide modal
 *  @param toggle toggle for modal display
 *  @param addEntity redux action mapped to state, creation of a new entity
 *
 */
const AssetModal = ({
  modal,
  toggle,
  addEntity,
  assets,
  project,
  selectedProjectType,
}) => {
  const projectType = project?.project_type ?? selectedProjectType;

  const [formData, setFormData] = useState({
    _id: undefined,
    name: undefined,
    description: '',
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value === '' ? undefined : e.target.value,
    });
  };

  const onSubmit = async () => {
    addEntity(formData, EntityType.asset);
  };

  const [sameNameModal, setSameNameModal] = useState(false);
  const toggleSameNameModal = () => setSameNameModal(!sameNameModal);

  const hasSameName =
    assets.find((asset) => asset.name === formData?.name) !== undefined;

  const focusRef = useRef(null);
  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        focusRef.current && focusRef.current.focus();
      }, 1);
    }
  }, [modal]);
  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader>
          <div className='modal-header'>New Asset</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <small>* required field</small>
            <FormGroup>
              <InputTemplate
                label='* Name'
                type='text'
                name='name'
                value={formData.name}
                onChange={onChange}
                innerRef={focusRef}
              />
              <br />
              <InputTemplate
                label='Description'
                type='textarea'
                name='description'
                value={formData.description}
                onChange={onChange}
              />
              <br />
              <Button
                disabled={formData.name === undefined}
                type='submit'
                className='float-right btn-add'
                style={{ marginRight: 0 }}
                onClick={(event) => {
                  if (hasSameName) {
                    event.preventDefault();
                    toggleSameNameModal();
                  } else {
                    onSubmit();
                    toggle();
                  }
                }}
              >
                Save
              </Button>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
      <DuplicateEntityModal
        toggle={toggleSameNameModal}
        modal={sameNameModal}
        parentModalAddEntity={onSubmit}
        parentModalToggle={toggle}
        entityToCreate={formData}
        entityType={EntityType.asset}
        projectType={projectType}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
  selectedProjectType: state.analysistree.selectedProjectType,
  assets: state.assets.assets,
});

export default connect(mapStateToProps, { addEntity })(AssetModal);
