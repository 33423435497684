import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  CardBody,
  CardTitle,
  Container,
  Label,
  NavbarBrand,
  NavbarToggler,
  UncontrolledCollapse,
} from 'reactstrap';
import AssetModal from '../EntityModals/AssetModal';
import EntityTable from '../EntityTable';

// List the Assets

const AssetTable = ({ assets, project }) => {
  const [data, setData] = useState([]);
  const [assetCreationModal, openAssetCreationModal] = useState(false);
  const toggleAssetCreation = () =>
    openAssetCreationModal((prevState) => !prevState);
  useEffect(() => {
    setData(assets);
  }, [assets]);

  // The headers/fields of assets
  const columns = useMemo(() => [
    {
      id: 0,
      label: 'ID',
      value: function (datum) {
        return datum?._id;
      },
      field: '_id',
      type: 'number',
    },
    {
      id: 1,
      label: 'Name',
      value: function (datum) {
        return datum?.name;
      },
      field: 'name',
      type: 'text',
    },
    {
      id: 2,
      label: 'Description',
      value: function (datum) {
        return datum?.description;
      },
      field: 'description',
      type: 'text',
    },
    {
      id: 3,
      label: 'Review',
      value: function (datum) {
        return datum?.review_state;
      },
      field: 'review_state',
      type: 'checkbox',
    },
    {
      id: 4,
      label: 'Review Comments',
      value: function (datum) {
        let count = 0;
        if (datum.comments?.autoid) {
          count = parseInt(datum.comments.autoid);
        }
        return count;
      },
      field: 'comments',
      type: 'number',
    },
  ]);

  // set column headers as ID, Name and Review
  const [headers, setHeaders] = useState([columns[0], columns[1], columns[3]]);
  // on select other headers
  const changeHeaders = (chosenColumns, isChecked) => {
    if (chosenColumns) {
      if (!isChecked) {
        setHeaders([...headers, chosenColumns]);
      } else {
        setHeaders(
          headers?.filter((header) => header?.label !== chosenColumns?.label)
        );
      }
    } else {
      setHeaders([]);
    }
  };

  return (
    <Container fluid='md'>
      {assetCreationModal && (
        <AssetModal modal={assetCreationModal} toggle={toggleAssetCreation} />
      )}
      <CardTitle tag='h1' className='mb-0'>
        <h1>Assets of {project?.name}</h1>
      </CardTitle>
      <div className='card-actions float-right'></div>
      <NavbarBrand
        className='mr-auto float-right'
        style={{ marginTop: '-3rem' }}
      >
        Options
        <NavbarToggler id='assetTableNavToggle' className='mr-2 float-right'>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
      </NavbarBrand>
      <UncontrolledCollapse toggler='#assetTableNavToggle'>
        <Label>Select Columns</Label>
        <ul>
          {columns.map((column) => {
            var selected = columns?.filter(
              (filteredColumn) =>
                Array.isArray(headers) &&
                headers?.some(
                  (header) => header?.label === filteredColumn?.label
                )
            );
            var isChecked = selected
              .map((header) => header.label)
              .includes(column.label);
            return (
              <li style={{ marginLeft: 50 }}>
                <input
                  type='checkbox'
                  className='btn-icon'
                  checked={isChecked}
                  onClick={() => changeHeaders(column, isChecked)}
                />
                <font style={{ marginLeft: 10 }}>{column.label}</font>
              </li>
            );
          })}
        </ul>
      </UncontrolledCollapse>
      <CardBody>
        <div style={{ marginBottom: '0.5rem', textAlign: 'right' }}>
          <Button className='btn-add' onClick={toggleAssetCreation}>
            Create Asset
          </Button>
        </div>
        <EntityTable title={'Assets'} headers={headers} data={data} />
      </CardBody>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  project: state.project.project,
});
export default withRouter(connect(mapStateToProps, {})(AssetTable));
