import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { UserPermissions } from '../../constants/Enums';
import { addMember } from '../../redux/actions/catalog';
import { getProfiles } from '../../redux/actions/profile';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import { hasPermission } from '../helpers/genericHelper';
import CustomDataTableStyles from '../layout/DataTableStyles';
import AutoCompleteInput from '../projects/project-components/AutoCompleteInput';
import RoleSelect from '../projects/project-components/RoleSelect';

/**
 * Add Member Modal
 *
 * Modal for adding new members on a catalog in the catalog details page
 *
 * @param modal: sets the body of the modal
 * @param toggle: triggers/toggles the modal to close/open
 * @param addMember: redux action to add new members on the catalog
 * @param catalog: redux state to retrieve specific catalog
 */

const AddMember = ({
  modal,
  toggle,
  addMember,
  catalog,
  profiles,
  getProfiles,
  currentMembers,
}) => {
  const [selectedRows] = useState([]);
  useEffect(() => {
    getProfiles();
  }, [selectedRows]);

  const [member, setMember] = useState({
    user: undefined,
    role: '',
  });

  //Event handling for inputs
  const onChange = (event) => {
    setMember({ ...member, [event.target.name]: event.target.value });
  };

  //method for adding new catalog
  const onSubmit = async () => {
    addMember(catalog?._id, member, catalog);
  };

  const columns = React.useMemo(() => [
    {
      name: 'User Name',
      selector: 'name',
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <Button
            id={`member${row._id}`}
            onClick={() => {
              selection(row);
            }}
          >
            <FontAwesomeIcon
              icon={member?.user?._id === row._id ? faCheckSquare : faSquare}
            />
          </Button>
          <UncontrolledTooltip target={`member${row._id}`}>
            Select
          </UncontrolledTooltip>
        </>
      ),
    },
  ]);

  const conditionalRowStyles = [
    {
      when: (row) => row._id === member?.user?._id,
      style: {
        backgroundColor: 'var(--highlight-color)',
      },
    },
  ];

  const selection = (row) => {
    setMember({
      ...member,
      user: row,
    });
  };

  var options = profiles.map((profile) => profile.name);
  const [searchText, setSearchText] = React.useState('');
  const searchItems =
    profiles &&
    profiles?.filter(
      (member) =>
        member.role?.toString().toLowerCase().includes(searchText) ||
        (member.name &&
          member.name.toLowerCase().includes(searchText.toLowerCase()))
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <AutoCompleteInput
        options={options}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search ISAT User'
      />
    );
  }, [searchText]);

  return (
    <Fragment>
      <DraggableModal isOpen={modal} toggle={toggle} unmountOnClose={false}>
        <ModalHeader>
          <div className='modal-header'>Add New Member</div>
        </ModalHeader>
        <div className='modal-line' />
        <ModalBody className='modal-body'>
          <Form>
            <FormGroup>
              <Label>Name:</Label>
              <DataTable
                columns={columns}
                data={searchItems?.filter(
                  (profile) =>
                    !currentMembers.some(
                      (member) => member.user_id === profile._id
                    )
                )}
                conditionalRowStyles={conditionalRowStyles}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={CustomDataTableStyles}
              />
              <Label>Role: </Label>
              <RoleSelect
                onChange={onChange}
                isCreator={hasPermission(
                  member?.user?.permissions,
                  UserPermissions.Creator
                )}
              />
            </FormGroup>
            <FormGroup>
              <Button className='float-right btn-danger' onClick={toggle}>
                Cancel
              </Button>
              {member?.user?._id !== '' && member?.role !== '' ? (
                <Button
                  className='float-right btn-add'
                  onClick={() => {
                    onSubmit();
                    toggle();
                  }}
                >
                  Add Member
                </Button>
              ) : (
                <Button type='submit' className='float-right btn-add' disabled>
                  Add Member
                </Button>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
      </DraggableModal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  catalog: state.catalog.catalog,
  profiles: state.profile.profiles,
});

export default connect(mapStateToProps, { addMember, getProfiles })(AddMember);
