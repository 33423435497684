export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_LICENSE_ALERT = 'SET_LICENSE_ALERT';
export const SET_DATABASE_ID = 'SET_DATABASE_ID';
export const REMOVE_LICENSE_ALERT = 'REMOVE_LICENSE_ALERT';
export const SET_LICENSE_EXPIRY = 'SET_LICENSE_EXPIRY';
export const SET_BACKEND_VERSION = 'SET_BACKEND_VERSION';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const DISMISS_LOGIN_ERROR = 'DISMISS_LOGIN_ERROR';
export const DISMISS_REGISTER_ERROR = 'DISMISS_REGISTER_ERROR';
export const RESET_REGISTER = 'RESET_REGISTER';
export const DISMISS_FORGOT_PASSWORD_ERROR = 'DISMISS_FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';
export const DISMISS_UPDATE_PASSWORD_ERROR = 'DISMISS_UPDATE_PASSWORD_ERROR';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET';

//Profile
export const SET_PROFILE = 'SET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const ACCOUNT_DELETED = ' ACCOUNT_DELETED';
export const GET_PROFILES = 'GET_PROFILES';
export const LOAD_PROFILES = 'LOAD_PROFILES';
export const DEL_PROFILE = 'DEL_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_USER_ASSIGNMENT = 'UPDATE_USER_ASSIGNMENT';
export const DELETE_USER_ASSIGNMENT = 'DELETE_USER_ASSIGNMENT';
export const GET_USER_ASSIGNMENT = 'GET_USER_ASSIGNMENT';
export const SET_PROJECT_ROLE = 'SET_PROJECT_ROLE';
export const SET_PROJECT_SPECIFIC_CATALOGS = 'SET_PROJECT_SPECIFIC_CATALOGS';
export const SET_REFTREE_CATALOGS = 'SET_REFTREE_CATALOGS';
export const SET_CONTROL_CATALOGS = 'SET_CONTROL_CATALOGS';
export const SET_REFTREES = 'SET_REFTREES';
export const ADD_REFTREES = 'ADD_REFTREES';
export const LOAD_ADMIN_PROJECTS = 'LOAD_ADMIN_PROJECTS';
export const LOAD_ADMIN_CATALOGS = 'LOAD_ADMIN_CATALOGS';

//PROJECT
export const ADD_PROJECT = 'ADD_PROJECT';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECT_REFTREE_CATALOG = 'SET_PROJECT_REFTREE_CATALOG';
export const SET_PROJECT_CONTROL_CATALOG = 'SET_PROJECT_CONTROL_CATALOG';
export const SET_PROJECT_COMMENTS = 'SET_PROJECT_COMMENTS';
export const DEL_PROJECT = 'DEL_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const DEL_MEMBER = 'DEL_MEMBER';
export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const ADD_PROJECT_BASELINE = 'ADD-ADD_PROJECT_BASELINE';
export const LOAD_PROJECT_BASELINE = 'LOAD_PROJECT_BASELINE';
export const REVERT_PROJECT_BASELINE = 'REVERT_PROJECT_BASELINE';
export const DELETE_PROJECT_BASELINE = 'DELETE_PROJECT_BASELINE';
export const GET_NEWS = 'GET_NEWS';
export const IMPORT_PROJECT = 'IMPORT_PROJECT';
export const SET_RISK_VIEW_TYPE = 'SET_RISK_VIEW_TYPE';
export const SET_PROJECT_BASELINE_MODE = 'SET_PROJECT_BASELINE_MODE';
export const PROJECT_LOADING_ERROR = 'PROJECT_LOADING_ERROR';

//ENTITY
export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOAD_CONTROLS = 'LOAD_CONTROLS';
export const LOAD_VULNERABILITIES = 'LOAD_VULNERABILITIES';
export const LOAD_THREATS = 'LOAD_THREATS';
export const ADD_ASSET = 'ADD_ASSET';
export const ADD_THREAT = 'ADD_THREAT';
export const ADD_VULNERABILITY = 'ADD_VULNERABILITY';
export const ADD_CONTROL = 'UPDATE_CONTROL';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_THREAT = 'UPDATE_THREAT';
export const UPDATE_VULNERABILITY = 'UPDATE_VULNERABILITY';
export const UPDATE_CONTROL = 'UPDATE_CONTROL';
export const ADD_COMMENT = 'ADD_COMMENT';
export const LOAD_ENTITIES = 'LOAD_ENTITIES';

export const GET_ENTITY = 'GET_ENTITY';
export const DEL_ENTITY = 'DEL_ENTITY';
export const SET_ENTITY = 'SET_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const ENTITY_FAIL = 'ENTITY_FAIL';
export const ENTITY_ERROR = 'ENTITY_ERROR';
export const GET_THREAT = 'GET_THREAT';
export const DEL_THREAT = 'DEL_THREAT';
export const THREAT_ERROR = 'THREAT_ERROR';

export const SET_NODE_DATA = 'SET_NODE_DATA';

//ATTACK TREE
export const LOAD_ATTACKTREE = 'LOAD_ATTACKTREE';
export const UPDATE_ATTACKTREE = 'UPDATE_ATTACKTREE';
export const FILTER_ATTACKTREE = 'FILTER_ATTACKTREE';
export const SELECT_SUBTREE = 'SELECT_SUBTREE';
export const UNSELECT_SUBTREE = 'UNSELECT_SUBTREE';
export const PASTE_SUBTREE = 'PASTE_SUBTREE';
export const SAVE_ATTACKTREE = 'SAVE_ATTACKTREE';
export const TOGGLE_POPOVER = 'TOGGLE_POPOVER';
export const VIEW_SELECTION_CHANGE = 'VIEW_SELECTION_CHANGE';
export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA';
export const UPDATE_SEARCH_IDS = 'UPDATE_SEARCH_IDS';
export const CLEAR_SEARCH_IDS = 'CLEAR_SEARCH_IDS';
export const DISPLAY_REFERENCE_TREES = 'DISPLAY_REFERENCE_TREES';
export const SET_REFTREE_MOD_PARAMS = 'SET_REFTREE_MOD_PARAMS';
export const CLEAR_REFTREE_MOD_PARAMS = 'CLEAR_REFTREE_MOD_PARAMS';
export const TOGGLE_REFTREE_MOD_WARNING = 'TOGGLE_REFTREE_MOD_WARNING';
export const SET_REFTREE_FEASIBILITY_VIEW = 'SET_REFTREE_FEASIBILITY_VIEW';
export const TOGGLE_PLACEMENT_REVIEWS = 'TOGGLE_PLACEMENT_REVIEWS';
export const SET_IMPACT_LEVEL_VIEWS = 'SET_IMPACT_LEVEL_VIEWS';
export const TOGGLE_TREE_ANIMATIONS = 'TOGGLE_TREE_ANIMATIONS';
export const SET_TREE_LAYOUT = 'SET_TREE_LAYOUT';

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE';
export const SIDEBAR_VISIBILITY_SHOW = 'SIDEBAR_VISIBILITY_SHOW';
export const SIDEBAR_VISIBILITY_HIDE = 'SIDEBAR_VISIBILITY_HIDE';
export const NO_SIDEBAR = 'NO_SIDEBAR';
export const WITH_SIDEBAR = 'WITH_SIDEBAR';
export const SET_SELECTED_SIDEBAR_PATH = 'SET_SELECTED_SIDEBAR_PATH';

// Catalogs
export const ADD_NEW_CATALOG = 'ADD_NEW_CATALOG';
export const ADD_NEW_PROJECT_CATALOG = 'ADD_NEW_PROJECT_CATALOG';
export const SET_CATALOG = 'SET_CATALOG';
export const UPDATE_CATALOG = 'UPDATE_CATALOG';
export const SET_ENTRY = 'SET_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const DELETE_CATALOG = 'DELETE_CATALOG';
export const DELETE_PROJECT_SPECIFIC_CATALOG =
  'DELETE_PROJECT_SPECIFIC_CATALOG';
export const ADD_CATALOG_ENTRY = 'ADD_CATALOG_ENTRY';
export const ADD_NEW_REFTREE = 'ADD_NEW_REFTREE';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const DELETE_REFTREE = 'DELETE_REFTREE';
export const SET_CATALOG_ROLE = 'SET_CATALOG_ROLE';
export const ADD_CATALOG_BASELINE = 'ADD_CATALOG_BASELINE';
export const LOAD_CATALOG_BASELINE = 'LOAD_CATALOG_BASELINE';
export const REVERT_CATALOG_BASELINE = 'REVERT_CATALOG_BASELINE';
export const DELETE_CATALOG_BASELINE = 'DELETE_CATALOG_BASELINE';
export const SET_CATALOG_BASELINE_MODE = 'SET_CATALOG_BASELINE_MODE';

// Logging
export const LOAD_ADMIN_LOGS = 'LOAD_ADMIN_LOGS';
export const LOAD_SECURITY_LOGS = 'LOAD_SECURITY_LOGS';
export const LOAD_ADMIN_DELETION_LOGS = 'LOAD_ADMIN_DELETION_LOGS';
export const LOAD_PROJECT_DELETION_LOGS = 'LOAD_PROJECT_DELETION_LOGS';
export const LOAD_CATALOG_DELETION_LOGS = 'LOAD_CATALOG_DELETION_LOGS';
export const LOAD_PROJECT_CHANGE_LOGS = 'LOAD_PROJECT_CHANGE_LOGS';
export const LOAD_CATALOG_CHANGE_LOGS = 'LOAD_CATALOG_CHANGE_LOGS';
export const REMOVE_RECYCLE_BIN_ENTRY = 'REMOVE_RECYCLE_BIN_ENTRY';
export const EMPTY_RECYCLE_BIN = 'EMPTY_RECYCLE_BIN';

export const LOAD_DELETED_USERS = 'LOAD_DELETED_USERS';
export const RESTORE_DELETED_USER = 'RESTORE_DELETED_USER';

export const UNSAVED_CHANGES = 'UNSAVED_CHANGES';
export const REROUTE = 'REROUTE';
export const CANCEL_REROUTE = 'CANCEL_REROUTE';
export const CANCEL_UNSAVED_CHANGES = 'CANCEL_UNSAVED_CHANGES';
