import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { GenerateNodeId } from '../../../constants/StringConstants';
import CustomDataTableStyles from '../../layout/DataTableStyles';

/**
 * Catalog Table Component
 *
 * A Table component for catalogs and reftree selection
 *
 *  @param data the data provided by the parent
 *  @param columns The columns of the component
 *  @param onRefTreeSelect event action toggled when a reftree is selected in the component
 *  @param userReftrees redux state mapped to props, the user's accessible ref trees
 *  @param selectedRefTree currently selected reftree from parent component
 *
 */
const CatalogTableComponent = ({
  data,
  columns,
  onRefTreeSelect,
  userReftrees,
  selectedRefTree,
  filteredReftrees = undefined,
}) => {
  return (
    <Fragment>
      {selectedRefTree && (
        <Row>
          Selected Node:
          <br />
          {selectedRefTree.name}
        </Row>
      )}
      <DataTable
        expandableRows
        expandOnRowClicked
        expand
        pagination
        columns={columns}
        data={data}
        defaultSortAsc={true}
        persistTableHead
        expandableRowExpanded={(row) =>
          filteredReftrees &&
          filteredReftrees.some(
            (reftree) => parseInt(reftree.catalog_id) === parseInt(row._id)
          )
        }
        expandableRowsComponent={
          <CatalogTableExpandedComponent
            data={data}
            reftrees={userReftrees}
            reftreeSelect={onRefTreeSelect}
            selectedRefTree={selectedRefTree}
            filteredReftrees={filteredReftrees}
          />
        }
        customStyles={CustomDataTableStyles}
      />
    </Fragment>
  );
};

const CatalogTableExpandedComponent = ({
  data,
  reftrees,
  reftreeSelect,
  selectedRefTree,
  filteredReftrees = undefined,
}) => {
  const getTree = (id) => {
    return reftrees.find((tree) => tree._id === id);
  };
  const [children, setChildren] = useState();
  useEffect(() => {
    let catalogChildren;
    if (filteredReftrees) {
      catalogChildren = data.children.filter((child) =>
        filteredReftrees.some(
          (reftree) => parseInt(child) === parseInt(reftree._id)
        )
      );
      setChildren(catalogChildren);
    } else {
      catalogChildren = data.children;
      setChildren(catalogChildren);
    }
  }, [filteredReftrees]);

  return (
    <Container fluid='xs'>
      <Table bordered size='xs'>
        <tr>
          <th>Control ID</th>
          <th>Name</th>
        </tr>{' '}
        {children?.map((child) => {
          const childReftree = getTree(child);
          return (
            <tr>
              <td
                className={
                  selectedRefTree?._id === child ? 'bg-secondary' : null
                }
              >
                {child}
              </td>
              <td
                className={
                  selectedRefTree?._id === child ? 'bg-secondary' : null
                }
              >
                <Link
                  onClick={() => reftreeSelect(childReftree)}
                  id={`CatalogTableReftreeDescription${GenerateNodeId(
                    childReftree?._id
                  )}`}
                >
                  {childReftree?.name}
                </Link>
                {childReftree?.description !== '' && (
                  <UncontrolledTooltip
                    placement='right'
                    target={`CatalogTableReftreeDescription${GenerateNodeId(
                      childReftree?._id
                    )}`}
                    disabled={childReftree?.description === undefined}
                    className='display-linebreak'
                  >
                    {childReftree?.description}
                  </UncontrolledTooltip>
                )}
              </td>
            </tr>
          );
        })}{' '}
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userReftrees: state.profile.userReftrees,
});

export default connect(mapStateToProps, {})(CatalogTableComponent);
