import axios1 from 'axios';
import { default as axios } from '../../Axios/axios';
import {
  ChangeTreeRoot,
  setChildren,
} from '../../components/helpers/AnalysisTreeHelper';
import {
  DefaultErrorHandling,
  setEntityUsage,
  testJSON,
} from '../../components/helpers/genericHelper';
import { CatalogType, EntityType, ProjectRole } from '../../constants/Enums';
import store from '../store';
import { setAlert } from './alert';
import { getComments } from './comments';
import { getAdminProjects, getControlCatalogs } from './profile';
import {
  ADD_PROJECT,
  ADD_PROJECT_BASELINE,
  ADD_REFTREES,
  DELETE_PROJECT_BASELINE,
  DEL_PROJECT,
  GET_NEWS,
  IMPORT_PROJECT,
  LOAD_ASSETS,
  LOAD_ATTACKTREE,
  LOAD_CONTROLS,
  LOAD_ENTITIES,
  LOAD_PROJECTS,
  LOAD_PROJECT_BASELINE,
  LOAD_THREATS,
  LOAD_VULNERABILITIES,
  PROJECT_LOADING,
  PROJECT_LOADING_ERROR,
  SET_PROJECT,
  SET_PROJECT_BASELINE_MODE,
  SET_PROJECT_COMMENTS,
  SET_PROJECT_CONTROL_CATALOG,
  SET_PROJECT_REFTREE_CATALOG,
  SET_PROJECT_ROLE,
  SET_REFTREES,
  SET_RISK_VIEW_TYPE,
  UPDATE_MEMBER,
  UPDATE_PROJECT,
} from './types';

// GET available projects
export const getProjects = () => async (dispatch) => {
  const data = {};
  const projectsResponse = await axios.project
    .get(
      '?_id=True&name=True&project_type=True&last_modified=True&description=True&scope=True&participants=True',
      data
    )
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  const projects = [];
  if (Array.isArray(projectsResponse?.data)) {
    for (const projectString of projectsResponse.data) {
      let projectObject = undefined;
      try {
        projectObject = projectString;
      } catch {
        continue;
      }
      projects.push(projectObject);
    }
  }
  projects.sort((a, b) => (a.name > b.name ? 1 : -1));

  //Parses all the JSON project data and dispatches them to the store
  dispatch({
    type: LOAD_PROJECTS,
    payload: projects,
  });
};

//Selects project to be set on the attack tree and entity lists
//Loads user's role to redux for page configurations
export const setProject =
  (project, rootId = undefined, loadTree = true) =>
  async (dispatch) => {
    dispatch({
      type: PROJECT_LOADING,
      payload: true,
    });
    dispatch({ type: SET_PROJECT_BASELINE_MODE, payload: false });

    const projectResponse = await axios.project
      .get(project._id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
        dispatch({
          type: PROJECT_LOADING_ERROR,
        });
      });
    await dispatch(getControlCatalogs(project._id));

    const reftrees = await axios.refTree
      .get(`?project_id=${project._id}`)
      .then(async (response) => {
        dispatch({
          type: SET_REFTREES,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting user reference trees. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
        dispatch({
          type: PROJECT_LOADING_ERROR,
        });
      });

    const state = store.getState();
    const profileId = state.profile?.profile?.id;
    let projectRole = undefined;
    projectResponse.project._id = project?._id;

    const projectUser = projectResponse?.project?.participants?.find(
      (user) => user.user_id?.toString() === profileId?.toString()
    );
    projectRole = projectUser?.role;

    //If viewing a baseline, operations shouldn't be available
    if (projectResponse.project?.baseline !== undefined) {
      projectRole = ProjectRole.Reader.value;
    }
    projectResponse.project = await getComments(projectResponse.project);

    dispatch({ type: SET_PROJECT_ROLE, payload: projectRole });
    dispatch({
      type: SET_PROJECT,
      payload: projectResponse.project,
    });

    const refTreeCatalogs = projectResponse.catalogs?.filter(
      (catalog) => catalog.content_type === CatalogType.ReferenceTree
    );

    dispatch({
      type: SET_PROJECT_REFTREE_CATALOG,
      payload: refTreeCatalogs,
    });

    const controlCatalogs = projectResponse.catalogs?.filter(
      (catalog) => catalog.content_type === CatalogType.Control
    );
    dispatch({
      type: SET_PROJECT_CONTROL_CATALOG,
      payload: controlCatalogs,
    });

    dispatch({
      type: SET_PROJECT_COMMENTS,
      payload: projectResponse.project.comments,
    });

    if (projectRole !== ProjectRole.Reader.value) {
      await setProjectNews(projectResponse.project, dispatch);
    }
    await setProjectAndTreeEntityData(
      projectResponse.project,
      dispatch,
      rootId,
      undefined,
      reftrees,
      loadTree
    );

    dispatch({
      type: PROJECT_LOADING,
      payload: false,
    });
  };

// ADD new Project
export const addProject = (project) => async (dispatch) => {
  axios.project
    .put('', project)
    .then(async (response) => {
      const projectResponse = response.data;
      const state = store.getState();
      const profileId = state.profile?.profile?.id;
      let projectRole = undefined;
      const projectUser = projectResponse?.project?.participants?.find(
        (user) => user.user_id.toString() === profileId.toString()
      );
      projectRole = projectUser?.role;

      dispatch({ type: SET_PROJECT_ROLE, payload: projectRole });
      dispatch({
        type: SET_PROJECT,
        payload: projectResponse.project,
      });
      dispatch(setAlert('Project successfully added', 'success'));
      dispatch(getProjects());
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });

  dispatch(getProjects());
};

export const duplicateProject = (project) => async (dispatch) => {
  const payload = {
    name: project.name,
    project_type: project.project_type,
    description: project.description,
    scope: project.scope,
    accepted_entity_types: project.accepted_entity_types,
    inherit_reftrees: project.inherit_reftrees,
  };
  axios.project
    .post(project._id, payload)
    .then(() => {
      dispatch({
        type: ADD_PROJECT,
        payload: project,
      });
      dispatch(setAlert('Project successfully added', 'success'));
      //Loads the remaining projects
      dispatch(getProjects());
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const updateProject = (id, project) => async (dispatch) => {
  axios.project
    .patch(id, project)
    .then(async (response) => {
      let project = response.data;
      if (project?.project_type !== undefined) {
        dispatch(setProject({ _id: id }));
        return;
      }
      project = await getComments(response.data);
      dispatch({
        type: UPDATE_PROJECT,
        payload: project,
      });
      const state = store.getState();
      const analysistree = Object.assign({}, state.analysistree.analysistree);
      //If root node is a project node
      if (analysistree?.projectType !== undefined) {
        analysistree.name = project.name;
        analysistree.projectType = project.project_type;
        analysistree.description = project.description;
        dispatch({
          type: LOAD_ATTACKTREE,
          payload: analysistree,
        });
      }
      dispatch(setAlert('Project successfully updated', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const getMembers = async (project) => {
  const teamMember = Object.assign({}, project);
  const newMembers = [];
  if (Array.isArray(teamMember.participants)) {
    const members = teamMember.participants;
    for (const participant of members) {
      if (Array.isArray(participant?.children)) {
        for (let index = 0; index < participant.children.length; index++) {
          const memberIndex = members.findIndex(
            (member) =>
              parseInt(member.user_id) === parseInt(participant.children[index])
          );
          if (memberIndex >= 0) {
            participant.children[index] = members[memberIndex];
            members[memberIndex].child = true;
          }
        }
      }
      newMembers.push(participant);
    }
  }
  teamMember.participant = newMembers;
  return teamMember;
};

export const deleteMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_remove: [user] };
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      dispatch(setAlert('Project Member successfully deleted', 'success'));
      const project = response.data;
      dispatch({
        type: UPDATE_PROJECT,
        payload: project,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const adminDeleteMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_remove: [user] };
  const state = store.getState();
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      if (state.project?.project?._id === id) {
        //reload main project
        const project = response.data;
        const state = store.getState();
        const currentProject = state.project.project;
        if (project._id === currentProject._id) {
          dispatch({
            type: UPDATE_PROJECT,
            payload: undefined,
          });
        }
      }
      dispatch(getProjects());
      dispatch(getAdminProjects());
      dispatch(setAlert('User has been removed from the Project', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const addMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_add: [user] };
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      dispatch(setAlert('Project Member successfully added', 'success'));
      const project = response.data;
      dispatch({
        type: UPDATE_PROJECT,
        payload: project,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const adminAddMember = (id, user) => async (dispatch) => {
  const payload = { participants_to_add: [user] };
  const state = store.getState();
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      if (state.project?.project?._id === id) {
        //reload main project
        const project = response.data;

        const state = store.getState();
        const currentProject = state.project.project;
        if (project._id === currentProject._id) {
          dispatch(setProject(project));
        }
      }
      dispatch(getProjects());
      dispatch(getAdminProjects());
      dispatch(setAlert('User has been added to the Project', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const updateMember = (id, member) => async (dispatch) => {
  const payload = { participants_to_add: [member] };
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      dispatch({
        type: UPDATE_MEMBER,
        payload: payload,
      });

      const state = store.getState();
      const profileId = state.profile?.profile?.id;
      const project = response.data;
      const projectUser = project?.participants?.find(
        (user) => user.user_id.toString() === profileId.toString()
      );
      dispatch({
        type: SET_PROJECT_ROLE,
        payload: projectUser.role,
      });
      dispatch(setAlert('Project Member successfully updated', 'success'));
      dispatch({
        type: UPDATE_PROJECT,
        payload: project,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const adminUpdateMember = (id, member) => async (dispatch) => {
  const state = store.getState();
  const payload = { participants_to_add: [member] };
  axios.project
    .patch(id, payload)
    .then(async (response) => {
      if (state.project?.project?._id === id) {
        //reload main project
        const project = response.data;

        const state = store.getState();
        const currentProject = state.project.project;
        if (project._id === currentProject._id) {
          dispatch(setProject(project));
        }
      }
      dispatch(getProjects());
      dispatch(getAdminProjects());
      dispatch(setAlert('User role updated', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const deleteProject = (id) => async (dispatch) => {
  axios.project
    .delete(id)
    .then(
      (response) => {
        dispatch(deleteProjectDetails());
        dispatch(setAlert('Project successfully deleted', 'success'));
        //Loads the remaining projects
        dispatch(getProjects());
      },
      function (error) {
        if (error.response && error.response.status === 401) {
          dispatch(setAlert('Permission Denied', 'danger'));
        } else {
          DefaultErrorHandling(error, dispatch);
        }
      }
    )
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export function deleteProjectDetails() {
  return {
    type: DEL_PROJECT,
  };
}

export const applyProjectBaseline =
  (projectId, baselineId) => async (dispatch) => {
    const requestString = `${projectId}/baselines/${baselineId}`;
    axios.project
      .get(requestString)
      .then((response) => {
        const baseline = response.data;
        dispatch({ type: SET_PROJECT_BASELINE_MODE, payload: true });
        dispatch(setBaselineProject(baseline));
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting baseline. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

export const setBaselineProject = (baselineData) => async (dispatch) => {
  dispatch({
    type: PROJECT_LOADING,
    payload: true,
  });

  dispatch({ type: SET_PROJECT_ROLE, payload: ProjectRole.Reader.value });

  const projectResponse = await axios.project
    .get(baselineData.data.Project[0]._id)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  await dispatch(getControlCatalogs(baselineData.data.Project[0]._id));

  await axios.refTree
    .get(`?project_id=${baselineData.data.Project[0]._id}`)
    .then(async (response) => {
      const reftrees = response.data;
      reftrees.map(
        (reftree) =>
          baselineData.data.RefTree.find(
            (replacement) => replacement._id === reftree._id
          ) || reftree
      );
      dispatch({
        type: SET_REFTREES,
        payload: reftrees,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting user reference trees. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

  dispatch({
    type: SET_PROJECT,
    payload: baselineData.data.Project[0],
  });

  const refTreeCatalogs = projectResponse.catalogs?.filter(
    (catalog) => catalog.content_type === CatalogType.ReferenceTree
  );
  const replacementRTCatalogs = baselineData.data.Catalog?.filter(
    (catalog) => catalog.content_type === CatalogType.ReferenceTree
  );

  refTreeCatalogs.map(
    (catalog) =>
      replacementRTCatalogs.find(
        (replacement) => replacement._id === catalog._id
      ) || catalog
  );

  dispatch({
    type: SET_PROJECT_REFTREE_CATALOG,
    payload: refTreeCatalogs,
  });

  const controlCatalogs = projectResponse.catalogs?.filter(
    (catalog) => catalog.content_type === CatalogType.Control
  );

  const replacementCCatalogs = baselineData.data.Catalog?.filter(
    (catalog) => catalog.content_type === CatalogType.Control
  );

  controlCatalogs.map(
    (catalog) =>
      replacementCCatalogs.find(
        (replacement) => replacement._id === catalog._id
      ) || catalog
  );

  dispatch({
    type: SET_PROJECT_CONTROL_CATALOG,
    payload: controlCatalogs,
  });

  const entities = baselineData.data.Entity;
  await setProjectAndTreeEntityData(
    baselineData.data.Project[0],
    dispatch,
    undefined,
    entities
  );

  dispatch({
    type: PROJECT_LOADING,
    payload: false,
  });
};

export const getProjectBaselines =
  (id = undefined) =>
  async (dispatch) => {
    const state = store.getState();
    const project = state.project.project;
    const projectId = id !== undefined ? id : project._id;
    const requestString = `${projectId}/baselines/`;
    axios.project
      .get(requestString)
      .then((response) => {
        dispatch({
          type: LOAD_PROJECT_BASELINE,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error getting projects baselines. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

export const addProjectBaseline = (payload) => async (dispatch) => {
  const state = store.getState();
  const project = state.project.project;
  const requestString = `${project._id}/baselines/`;
  axios.project
    .put(requestString, payload)
    .then((response) => {
      dispatch({
        type: ADD_PROJECT_BASELINE,
        payload: {
          ...payload,
          creation_date: new Date(),
          baseline_id: response.data.baseline_id,
        },
      });
      dispatch(setAlert('Baseline successfully added', 'success'));
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const revertProjectBaseline =
  (version, projectId = undefined, loadProject = true) =>
  async (dispatch) => {
    const payload = { baseline_version: version };
    const state = store.getState();
    const project = state.project.project;
    const requestString = (projectId ?? project._id) + '/baselines/';
    if (projectId !== undefined && project._id === projectId) {
      loadProject = true;
    }
    axios.project
      .patch(requestString, payload)
      .then(() => {
        if (loadProject) {
          setProject({ _id: project._id });
        }
        dispatch(setAlert('Baseline has been reverted', 'success'));
      })
      .catch((error) => {
        DefaultErrorHandling(error, dispatch);
      });
  };

export const deleteProjectBaseline = (baselineId) => async (dispatch) => {
  const state = store.getState();
  const project = state.project.project;
  const requestString = `${project._id}/baselines/${baselineId}`;
  axios.project
    .delete(requestString)
    .then(() => {
      dispatch({
        type: DELETE_PROJECT_BASELINE,
        payload: baselineId,
      });
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const reloadAnalysisTree =
  (rootId = undefined, reftreeId = undefined) =>
  async (dispatch) => {
    if (reftreeId !== undefined) {
      dispatch(setRefTreeData({ _id: reftreeId }));
      return;
    }

    const state = store.getState();
    const project = state.project.project;
    if (rootId === undefined) {
      rootId = parseInt(
        new URLSearchParams(window.location.search).get('rootId')
      );
    }
    if (project === undefined) {
      setAlert('Error: No selected project', 'danger');
      return;
    }

    await setProjectAndTreeEntityData(project, dispatch, rootId);
    //TODO: loading global reftree subtree
  };

const setProjectAndTreeEntityData = async (
  project,
  dispatch,
  rootId = undefined,
  replacementEntities = undefined,
  userReftrees = undefined,
  loadTree = true
) => {
  //Gets all entities in hashmap structure
  const analysistreeResponse = await axios.project
    .get(`${project._id}/entities/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(setAlert(error.response?.data?.msg, 'danger'));
    });
  let entityArray = undefined;
  try {
    //Parse response to object
    entityArray = Object.assign({}, analysistreeResponse);

    if (replacementEntities) {
      replacementEntities.map((entity) => {
        if (entityArray?.[entity._id] === undefined) {
          entityArray[entity._id] = entity;
        }
        return entity;
      });
      Object.entries(entityArray).map(([id, entity]) => {
        const replacement = replacementEntities.find(
          (replacement) => replacement._id.toString() === id
        );

        if (replacement !== undefined) {
          entityArray[id] = replacement;
        }
        return [id, entity];
      });
    }

    //Goes through entity array and separates entities and sets them in their respective stores
    const data = {
      assets: [],
      threats: [],
      vulnerabilities: [],
      controls: [],
    };

    const state = store.getState();
    //Uses reftrees provided from set project call if userReftrees parameter is used
    const reftrees = userReftrees ?? state.profile.userReftrees;
    //Sort items to attack tree and set to store

    let analysisTreeAssets = [];

    //Sets the assets for the tree and the entity lists
    Object.values(
      Object.entries(entityArray).filter(([key, value]) => {
        if (value.entity_type === EntityType.asset) {
          const indexedAsset = { ...value, index: 1 };
          analysisTreeAssets.push(indexedAsset);
          data.assets.push(indexedAsset);
        }
        return [key, value];
      })
    );

    //Sets children onto tree
    analysisTreeAssets = setChildren(analysisTreeAssets, entityArray, true);

    //Sets threats, vulnerabilities, and controls into the entity lists
    for (const [, value] of Object.entries(entityArray)) {
      switch (value.entity_type) {
        case EntityType.threat:
          data.threats.push(value);
          break;
        case EntityType.vulnerability:
          if (value.owner.owner_type === 'reftree') {
            value.owner.usage = setEntityUsage('R', value.index);
          } else {
            value.owner.usage = 'A';
          }
          data.vulnerabilities.push(value);
          break;
        case EntityType.control:
          if (value.owner.owner_type === 'reftree') {
            const reftree = reftrees.find(
              (reftree) => parseInt(reftree._id) === parseInt(value.owner.id)
            );
            if (reftree?.tree_type === 'control') {
              value.owner.usage = setEntityUsage('C', value.index);
            } else if (reftree?.tree_type === 'vulnerability') {
              value.owner.usage = setEntityUsage('R', value.index);
            }
          } else {
            value.owner.usage = 'A';
          }
          data.controls.push(value);
          break;
        default:
          break;
      }
    }
    dispatch({
      type: LOAD_ASSETS,
      payload: data.assets,
    });
    dispatch({
      type: LOAD_THREATS,
      payload: data.threats,
    });
    dispatch({
      type: LOAD_VULNERABILITIES,
      payload: data.vulnerabilities,
    });
    dispatch({
      type: LOAD_CONTROLS,
      payload: data.controls,
    });
    dispatch({
      type: LOAD_ENTITIES,
      payload: Object.entries(entityArray).map((entity) => entity[1]),
    });

    if (loadTree) {
      const analysisTreeData = {
        name: project.name,
        projectType: project.project_type,
        description: project.description,
        children: analysisTreeAssets,
      };

      if (rootId === undefined || isNaN(rootId)) {
        dispatch({
          type: LOAD_ATTACKTREE,
          payload: analysisTreeData,
        });
      } else {
        const subtree = ChangeTreeRoot(analysisTreeData, rootId);
        if (subtree !== null) {
          dispatch({
            type: LOAD_ATTACKTREE,
            payload: subtree,
          });
        } else {
          return;
        }
      }
    }
  } catch (error) {
    const analysisTreeData = {
      name: project.name,
      projectType: project.project_type,
      description: project.description,
      children: [],
      review_state: false,
    };

    dispatch({
      type: LOAD_ATTACKTREE,
      payload: analysisTreeData,
    });

    DefaultErrorHandling(error, dispatch);
  }
};

export const setRefTreeData = (reftree) => async (dispatch) => {
  const tree = await axios.refTree
    .get(`${reftree._id}?creation_date=false`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  dispatch({
    type: ADD_REFTREES,
    payload: [tree],
  });
  let rootNode = await axios.entity
    .get(tree.current_version)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  const reftreeEntityResponse = await axios.refTree
    .get(`${reftree._id}/entities/`)
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  rootNode = setChildren([rootNode], reftreeEntityResponse.data)[0];

  dispatch({
    type: LOAD_ATTACKTREE,
    payload: rootNode,
  });

  dispatch({
    type: PROJECT_LOADING,
    payload: false,
  });
};

export const importProject = (payload) => async (dispatch) => {
  if (!testJSON(payload)) {
    dispatch(
      setAlert(
        'No file of the correct type or format has been selected.',
        'danger'
      )
    );
    return;
  }
  axios.import
    .put('', payload)
    .then(() => {
      dispatch({
        type: IMPORT_PROJECT,
        payload: payload,
      });
      dispatch(setAlert('Project successfully imported', 'success'));
      //Loads the remaining projects
      dispatch(getProjects());
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const exportProject = async (payload, project, filename) => {
  const { dispatch } = store;
  return axios1
    .post(`/api/project/${project?._id}/export/xls/${filename}`, payload, {
      headers: {
        'Content-Disposition': 'attachment; filename=template',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      var blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      return window.URL.createObjectURL(blob);
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

export const exportProjectJSON = async (projectId) => {
  const { dispatch } = store;

  return axios1
    .get(`/api/project/${projectId}/export/json/`, {
      headers: {
        'Content-Disposition': 'attachment; filename=template',
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      var blob = new Blob([response.data], {
        type: 'application/json',
      });
      return window.URL.createObjectURL(blob);
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
};

const setProjectNews = async (project, dispatch) => {
  const news = await axios.project
    .get(`${project._id}/news/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });
  dispatch({
    type: GET_NEWS,
    payload: news,
  });
};

export const updateNews = (projectId, newsId, type) => async (dispatch) => {
  axios.project
    .patch(`${projectId}/news/`, {
      news_id: newsId,
      action: type,
    })
    .then(() => {
      dispatch(
        setProject({
          _id: projectId,
        })
      );
    });
};

export const changeRiskView = (type) => async (dispatch) => {
  dispatch({
    type: SET_RISK_VIEW_TYPE,
    payload: type,
  });
};
