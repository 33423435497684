import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CardBody,
  CardTitle,
  Container,
  Label,
  NavbarBrand,
  NavbarToggler,
  UncontrolledCollapse,
} from 'reactstrap';
import {
  LikelihoodEstimation,
  OwnerType,
  ProjectType,
} from '../../../constants/Enums';
import {
  AllControlLikelihood,
  ID,
  Name,
  NoControlLikelihood,
  Review,
} from '../../../constants/StringConstants';
import EntityTable from '../EntityTable';
// Lists vulnerability Table

const VulnerabilityTable = ({
  vulnerabilities,
  project,
  threats,
  selectedProjectType,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const data = [];
    if (
      (project?.project_type ?? selectedProjectType) ===
      ProjectType.ISO_21434.Value
    ) {
      getVulList(vulnerabilities, data);
    } else {
      getVulList(threats, data);
    }
    setData(data);
  }, [vulnerabilities, project]);

  const getVulList = (entities, entityList, parentNode = undefined) => {
    for (const entity of entities) {
      if (
        entity.owner.owner_type === OwnerType.RefTree &&
        parentNode === undefined &&
        threats.findIndex((threat) =>
          threat.children.some(
            (child) =>
              parseInt(entity._id) === parseInt(child) ||
              parseInt(entity._id) === parseInt(child?._id)
          )
        ) < 0
      ) {
        continue;
      }
      for (const child of entity.children) {
        const vul = vulnerabilities.find(
          (vulnerability) =>
            parseInt(vulnerability._id) === parseInt(child) ||
            parseInt(vulnerability._id) === parseInt(child?._id)
        );
        if (vul) {
          const newVul = JSON.parse(JSON.stringify(vul));
          let parent = parentNode;
          if (newVul.owner.owner_type === OwnerType.RefTree) {
            if (parent === undefined) {
              parent = entity;
              if (parent.owner.owner_type === OwnerType.RefTree) {
                parent = threats.find((threat) =>
                  threat.children.some(
                    (child) =>
                      parseInt(child) === parseInt(entity._id) ||
                      parseInt(child?._id) === parseInt(entity._id)
                  )
                );
              }
              newVul.parent_node = parent;
            } else {
              newVul.parent_node = parent;
            }
            entityList.push(newVul);
            getVulList([newVul], entityList, parent);
          } else {
            vul.parent_node = entity;
            entityList.push(vul);
            if (
              (project?.project_type ?? selectedProjectType) ===
              ProjectType.ISO_27000_series.Value
            ) {
              getVulList([vul], entityList, entity);
            }
          }
        }
      }
    }
  };

  //Columns
  const columns = useMemo(() => [
    {
      label: ID,
      value: function (datum) {
        return datum?._id;
      },
      field: '_id',
      type: 'number',
    },
    {
      label: 'Parent Node',
      value: function (datum) {
        return datum?.parent_node.name;
      },
      field: 'parent',
      type: 'text',
    },
    {
      label: Name,
      value: function (datum) {
        return datum?.name;
      },
      field: 'name',
      type: 'text',
    },
    {
      label: 'Description',
      value: function (datum) {
        return datum?.description;
      },
      field: 'description',
      type: 'text',
    },
    {
      label: Review,
      value: function (datum) {
        return datum?.review_state;
      },
      field: 'review_state',
      type: 'checkbox',
    },
    {
      label: 'Review Comments',
      value: function (datum) {
        let count = 0;
        if (datum.comments?.autoid) {
          count = parseInt(datum.comments.autoid);
        }
        return count;
      },
      field: 'comments',
      type: 'number',
    },
    {
      label: 'Operator',
      value: function (datum) {
        return datum.operator;
      },
      field: 'operator',
      type: 'select',
      enum: (
        <>
          <option value='OR'>OR</option>
          <option value='AND'>AND</option>
        </>
      ),
    },
    {
      label: 'Likelihood Estimation Method',
      value: function (datum) {
        return datum.likelihood_estimation_method;
      },
      field: 'likelihood_estimation_method',
      type: 'select',
      enum: Object.entries(LikelihoodEstimation).map((method) => {
        return <option value={method[1]}>{method[1]}</option>;
      }),
    },
    {
      label: 'Likelihood Category Ratings',
      value: function (datum) {
        if (
          datum.likelihood_estimation_method !==
          LikelihoodEstimation['Category Based']
        ) {
          return 'N/A';
        } else {
          return `Window of Oppurtunity: ${
            ProjectType[project?.project_type ?? selectedProjectType]
              .OpportunityWindow[datum.window_of_opportunity]
          }, Elapsed Time: ${
            ProjectType[project?.project_type ?? selectedProjectType]
              .ElapsedTime[datum.elapsed_time]
          }, Equipment: ' +
                ${
                  ProjectType[project?.project_type ?? selectedProjectType]
                    .Equipment[datum.equipment]
                } , Specialist Expertise: ${
            ProjectType[project?.project_type ?? selectedProjectType]
              .SpecialistExpertise[datum.specialist_knowledge]
          }, Knowledge of Item: ${
            ProjectType[project?.project_type ?? selectedProjectType]
              .ComponentKnowledge[datum.item_knowledge]
          }`;
        }
      },
      field: 'likelihood_category_rating',
      type: 'text',
    },
    {
      label: NoControlLikelihood,
      value: function (datum) {
        return datum?.no_control_likelihood;
      },
      field: 'no_control_likelihood',
      type: 'text',
    },
    {
      label: 'Implemented Control Likelihood',
      value: function (datum) {
        return datum?.implemented_control_likelihood;
      },
      field: 'implemented_control_likelihood',
      type: 'text',
    },
    {
      label: 'Proposed Control Likelihood',
      value: function (datum) {
        return datum?.proposed_control_likelihood;
      },
      field: 'proposed_control_likelihood',
      type: 'text',
    },
    {
      label: AllControlLikelihood,
      value: function (datum) {
        return datum?.all_control_likelihood;
      },
      field: 'all_control_likelihood',
      type: 'text',
    },
    {
      label: 'Assignment',
      value: function (datum) {
        return datum.owner.usage;
      },
      field: 'assignment',
      type: 'select',
      enum: (
        <>
          <option value='A'>Analysis Tree</option>
          <option value='R'>Ref Tree</option>
          <option value='C'>Catalog</option>
        </>
      ),
    },
  ]);

  const [headers, setHeaders] = useState([
    columns.find((column) => column.label === ID),
    columns.find((column) => column.label === Name),
    columns.find((column) => column.label === NoControlLikelihood),
    columns.find((column) => column.label === AllControlLikelihood),
    columns.find((column) => column.label === Review),
  ]);

  const changeHeaders = (chosenColumns, isChecked) => {
    if (chosenColumns) {
      if (!isChecked) {
        setHeaders([...headers, chosenColumns]);
      } else {
        setHeaders(
          headers?.filter((header) => header?.label !== chosenColumns?.label)
        );
      }
    } else {
      setHeaders([]);
    }
  };

  return (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Vulnerabilities of {project?.name}</h1>
      </CardTitle>
      <NavbarBrand
        className='mr-auto float-right'
        style={{ marginTop: '-3rem' }}
      >
        Options
        <NavbarToggler id='optionsNavToggle' className='mr-2 float-right'>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
      </NavbarBrand>
      <UncontrolledCollapse toggler='#optionsNavToggle'>
        <Label>Select Columns</Label>{' '}
        <ul>
          {columns.map((column) => {
            var selected = columns?.filter(
              (filterColumn) =>
                Array.isArray(headers) &&
                headers?.some((header) => header?.label === filterColumn?.label)
            );
            var isChecked = selected
              .map((header) => header.label)
              .includes(column.label);
            return (
              <li style={{ marginLeft: 50 }}>
                <input
                  type='checkbox'
                  className='btn-icon'
                  checked={isChecked}
                  onClick={() => changeHeaders(column, isChecked)}
                />
                <font style={{ marginLeft: 10 }}>{column.label}</font>
              </li>
            );
          })}
        </ul>
      </UncontrolledCollapse>
      <CardBody>
        <EntityTable title={'Vulnerabilities'} headers={headers} data={data} />
      </CardBody>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  project: state.project.project,
  threats: state.threats.threats,
});
export default withRouter(connect(mapStateToProps)(VulnerabilityTable));
