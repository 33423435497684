// Asset list main page
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setProject } from '../../../redux/actions/projects';
import VulnerabilityTable from './VulnerabilityTable';
//view for vulerability
const Vulnerabilities = ({
  vulnerabilities,
  loading,
  project,
  setProject,
  profile,
}) => {
  useEffect(() => {
    if (profile !== null) {
      if ((project === undefined || project === null) && loading === false) {
        var url = window.location.pathname;
        var splitUrl = url.split('/');
        var projectId = splitUrl[1];
        if (projectId !== undefined) {
          const projectTemplate = { _id: projectId };
          setProject(projectTemplate);
        }
      }
    }
  }, [project, loading, profile]);
  return (
    <div style={{ backfaceVisibility: 'visible', background: 'white' }}>
      <VulnerabilityTable vulnerabilities={vulnerabilities} />{' '}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vulnerabilities: state.vulnerabilities.vulnerabilities,
  loading: state.project.loading,
  project: state.project.project,
  profile: state.profile.profile,
});
export default connect(mapStateToProps, { setProject })(Vulnerabilities);
