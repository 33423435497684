export const OK = 'OK';
export const SaveAll = 'Save All';
export const Cancel = 'Cancel';

export const USER_MANAGEMENT = 'User Management';
export const RECYCLE_BIN = 'Recycle Bin';
export const SECURITY_LOGS = 'Security Logs';
export const CHANGE_LOGS = 'Change Logs';
export const LEGAL_NOTICE = 'Legal Notice';

export const ascending = 'ascending';
export const descending = 'descending';

export const selectedColor = 'var(--highlight-color)';

export const ID = 'ID';
export const Name = 'Name';
export const SecurityGoalText = 'Security Goal';
export const ImpactCategoryText = 'Impact Category';
export const ImpactLevel = 'Impact Level';
export const Review = 'Review';
export const NoControlLikelihood = 'No Control Likelihood';
export const AllControlLikelihood = 'All Control Likelihood';
export const Likelihood = 'Likelihood';
export const Proposed = 'Proposed';
export const Implemented = 'Implemented';

//Generates node ids for id/targets for unique tooltip/menu triggers
export const GenerateNodeId = (nodeId, index = undefined) => {
  return `${nodeId}_${index}`;
};

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};
export const LocalTimeWithoutSeconds = (dateValue) =>
  new Date(dateValue).toLocaleDateString([], dateOptions);

export const maxFraction = (input) =>
  input === undefined
    ? undefined
    : input.toLocaleString('en-US', {
        maximumFractionDigits: 4,
      });
