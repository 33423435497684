import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { loginUrl } from '../../constants/URLConstants';
import Dashboard from '../helpers/Dashboard';
import Page404 from '../helpers/Page404';
import ScrollToTop from '../helpers/ScrollToTop';
import Sidebar from '../layout/Sidebar';
import { dashboard as dashboardRoutes } from './index';

export const history = createHistory();

const childRoutes = (Layout, routes) =>
  routes.map(
    (
      { name, children, path, component: Component, isProtected = false },
      index
    ) =>
      children ? (
        // Route item with children
        children.map(({ rights, path, component: Component }, index) =>
          isProtected ? (
            <ProtectedRoute
              rights={rights}
              name={name}
              isProtected={isProtected}
              path={path}
              key={index}
              exact
              component={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          ) : (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        )
      ) : // Route item without children
      isProtected ? (
        <ProtectedRoute
          name={name}
          path={path}
          key={index}
          exact
          component={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = (isAuthenticated) => (
  <>
    <Router history={history}>
      <Sidebar />
      {isAuthenticated ? (
        <ScrollToTop>
          <Switch>
            {childRoutes(Dashboard, dashboardRoutes)}
            {/*If routes are not found, redirects to 404 page*/}
            <Route render={() => <Page404 />} />
          </Switch>
        </ScrollToTop>
      ) : (
        <>
          <ScrollToTop>
            <Switch>
              {childRoutes(Dashboard, dashboardRoutes)}
              {/*If routes are not found, redirects to 404 page*/}
              <Route render={() => <Page404 />} />
            </Switch>
          </ScrollToTop>
          <Redirect to='/'></Redirect>
        </>
      )}
    </Router>
  </>
);

const ProtectedRoute = ({
  rights: Rights,
  name: Name,
  component: Component,
  ...props
}) => (
  <Route
    {...props}
    render={(props) =>
      // TODO: authorization checks
      // Auth.getAuth() === true ? (
      true ? (
        Name === 'Home' ? (
          <Component {...props} />
        ) : /*TODO: admin checks
              Auth.isAdmin() ===*/
        true ? (
          <Component {...props} />
        ) : /*TODO: change with project selection(?)
              hasRights(Rights)*/
        true ? (
          <Component {...props}></Component>
        ) : (
          <Route render={() => <Page404 />} />
        )
      ) : (
        window.location.replace(loginUrl)
      )
    }
  />
);

export default Routes;
