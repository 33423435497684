import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { UserPermissions } from '../../constants/Enums';
import {
  RECYCLE_BIN,
  SECURITY_LOGS,
  USER_MANAGEMENT,
} from '../../constants/StringConstants';
import { INVENSITYUrl } from '../../constants/URLConstants';
import Logo from '../../img/logo.png';
import { logout } from '../../redux/actions/auth';
import { reRoute } from '../../redux/actions/save';
import store from '../../redux/store';
import { hasPermission } from '../helpers/genericHelper';
import { history } from '../routing/Routes';
// the Navbar for the welcome page
const Navbar = ({
  auth: { isAuthenticated, loading },
  logoutMethod,
  profile,
  project,
  catalog,
  unsavedChanges,
}) => {
  const { dispatch } = store;
  const clickNavbar = (event, pathname) => {
    if (unsavedChanges?.length > 0) {
      event.preventDefault();
      dispatch(reRoute(pathname));
    }
    if (window.location.pathname !== pathname) {
      history.push(pathname);
    }
  };
  const pathToProjects = '/projects';
  const pathToProfile = '/profile';
  const pathToUserManagement = '/admin/user-management';
  const pathToSecurityLogs = '/admin/security-logs';
  const pathToRecyleBin = '/log/recycle-bin';
  const navbarStyle = (pathname) => {
    var navbarColor;
    switch (pathname) {
      case pathToProjects:
      case pathToProfile:
      case pathToUserManagement:
      case pathToSecurityLogs:
      case pathToRecyleBin:
        navbarColor = window.location.pathname === pathname ? '#7ac142' : '';
        break;
      default:
        navbarColor = '';
    }
    return { color: navbarColor };
  };
  const authLinks = (
    <Row>
      <Col>
        <button
          className='btn-navbar'
          onClick={() => {
            window.open(INVENSITYUrl);
          }}
          style={{ width: '200px' }}
        >
          <img src={Logo} />
        </button>
      </Col>
      <Col>
        <button
          className='btn-navbar'
          onClick={(event) => {
            clickNavbar(event, pathToProjects);
          }}
          style={navbarStyle(pathToProjects)}
        >
          Home
        </button>
      </Col>
      <Col>
        <button
          className='btn-navbar'
          onClick={(event) => {
            clickNavbar(event, pathToProfile);
          }}
          style={navbarStyle(pathToProfile)}
        >
          Account Page
        </button>
      </Col>
      {hasPermission(profile?.permissions, UserPermissions.Admin) && (
        <Fragment>
          {' '}
          <Col>
            <button
              className='btn-navbar'
              onClick={(event) => {
                clickNavbar(event, pathToUserManagement);
              }}
              style={navbarStyle(pathToUserManagement)}
            >
              {USER_MANAGEMENT}
            </button>
          </Col>
          <Col>
            <button
              className='btn-navbar'
              onClick={(event) => {
                clickNavbar(event, pathToSecurityLogs);
              }}
              style={navbarStyle(pathToSecurityLogs)}
            >
              {SECURITY_LOGS}
            </button>
          </Col>
          <Col>
            <button
              className='btn-navbar'
              onClick={(event) => {
                clickNavbar(event, pathToRecyleBin);
              }}
              style={navbarStyle(pathToRecyleBin)}
            >
              {RECYCLE_BIN}
            </button>
          </Col>
        </Fragment>
      )}
      <Col>
        <button
          className='btn-navbar'
          onClick={(event) => {
            if (unsavedChanges?.length > 0) {
              event.preventDefault();
              dispatch(reRoute('logout'));
            } else {
              logoutMethod();
            }
          }}
        >
          <i className='fas fa-sign-out-alt'></i>{' '}
        </button>
      </Col>
    </Row>
  );

  if (!isAuthenticated) {
    return <></>;
  } else {
    return (
      <nav
        className='navbar bg-dark'
        style={{
          zIndex: 200,
          opacity: 100,
          minHeight: '60px',
        }}
      >
        {
          <div>
            <Fragment>{authLinks}</Fragment>
          </div>
        }
      </nav>
    );
  }
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile.profile,
  project: state.project.project,
  catalog: state.catalog.catalog,
  unsavedChanges: state.save.unsavedChanges,
});
export default connect(mapStateToProps, { logoutMethod: logout })(Navbar);
