import {
  faAsterisk,
  faBookOpen,
  faBullhorn,
  faExclamationTriangle,
  faFileContract,
  faFolderOpen,
  faHome,
  faList,
  faPortrait,
  faSitemap,
  faTasks,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import {
  LEGAL_NOTICE,
  RECYCLE_BIN,
  SECURITY_LOGS,
  USER_MANAGEMENT,
} from '../../constants/StringConstants';
import { passwordResetUrl } from '../../constants/URLConstants';
import LegalNotice from '../admin/LegalNotice';
import RecycleBin from '../admin/RecycleBin';
import SecurityLog from '../admin/SecurityLog';
import UserManagement from '../admin/UserManagement';
import AnalysisTree from '../analysistree/analysisTree';
import NodeDetailsPage from '../analysistree/nodetypes/nodeObjects/NodeDetailsPage';
import CatalogDetails from '../catalogues/CatalogDetails';
import ControlCatalog from '../catalogues/ControlCatalog';
import EntryDetails from '../catalogues/EntryDetails';
import RefTreeCatalog from '../catalogues/RefTreeCatalogs';
import Profile from '../dashboard/Profile';
import Assets from '../entities/Asset/Assets';
import ControlTable from '../entities/Control/Controls';
import Threats from '../entities/Threat/Threats';
import ThreatScenarios from '../entities/ThreatScenarios/ThreatScenarios';
import Vulnerabilities from '../entities/Vulnerability/Vulnerabilities';
import async from '../helpers/async';
import Dashboard from '../helpers/Dashboard';
import PasswordReset from '../layout/PasswordReset';
import ProjectDetail from '../projects/project-forms/ProjectDetail';
import Projects from '../projects/Projects';
import RiskList from '../risk/RiskList';
import Advisory from '../support/Advisory/Advisory';
import TARA from '../support/TARA/TARA';
import Tool from '../support/Tool/Tool';

export const idTypes = {
  reftree: ':refTreeId',
  node: ':nodeId',
  project: ':projectId',
  catalog: ':catalogId',
  entry: ':entryId',
};

// Dashboards
const Default = async(() => import('../layout/Landing'));

export const dashboardRoutes = {
  path: '/',
  name: 'Home',
  header: 'Main',
  icon: faHome,
  component: Default,
};

export const passwordResetRoute = {
  path: `${passwordResetUrl}/:key`,
  name: 'Reset Password',
  component: PasswordReset,
};

export const profileRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  header: 'Dashboard',
  icon: faPortrait,
  component: Dashboard,
};

export const profilePrivateRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  header: 'Dashboard',
  children: [
    {
      path: '/profile',
      name: 'Accounte Page',
      component: Profile,
    },
  ],
};

export const adminPrivateRoutes = {
  path: '/admin',
  name: 'Admin',
  header: 'Admin',
  children: [
    {
      path: '/admin/user-management',
      name: USER_MANAGEMENT,
      component: UserManagement,
    },
    {
      path: '/admin/security-logs',
      name: SECURITY_LOGS,
      component: SecurityLog,
    },
    {
      path: '/admin/legal-notice',
      name: LEGAL_NOTICE,
      component: LegalNotice,
    },
  ],
};

export const legalNoticeRoute = {
  path: '/legal-notice',
  name: LEGAL_NOTICE,
  component: LegalNotice,
  icon: faFileContract,
};

export const loggingRoutes = {
  path: '/log',
  children: [
    {
      path: '/log/recycle-bin',
      name: RECYCLE_BIN,
      component: RecycleBin,
    },
  ],
};

const riskRoutes = {
  path: `/${idTypes.project}/risk`,
  name: 'Risks',
  header: 'Risks',
  icon: faExclamationTriangle,
  component: RiskList,
};

const entitiesRoutes = {
  path: `/${idTypes.project}/entities`,
  name: 'Entities',
  header: 'Entities',
  icon: faFolderOpen,
  children: [
    {
      path: `/${idTypes.project}/entities/assets`,
      name: 'Assets',
      component: Assets,
    },
    {
      path: `/${idTypes.project}/entities/threats`,
      name: 'Threats',
      component: Threats,
    },
    {
      path: `/${idTypes.project}/entities/threat-scenarios`,
      name: 'Threat Scenarios',
      component: ThreatScenarios,
    },
    {
      path: `/${idTypes.project}/entities/vulnerabilities`,
      name: 'Vulnerabilities',
      component: Vulnerabilities,
    },
    {
      path: `/${idTypes.project}/entities/controls`,
      name: 'Controls',
      component: ControlTable,
    },
  ],
};

const catalogRoutes = {
  path: '/catalogues',
  name: 'Catalogues',
  header: 'Catalogues',
  icon: faBookOpen,
  children: [
    {
      path: '/catalogues/controls',
      name: 'Controls',
      component: ControlCatalog,
    },
    {
      path: '/catalogues/refTree',
      name: 'Reference Trees',
      component: RefTreeCatalog,
    },
  ],
};

const catalogPrivateRoutes = {
  path: '/catalogues',
  name: 'Catalogues',
  header: 'Catalogues',
  children: [
    {
      path: `/catalogues/${idTypes.catalog}/details`,
      name: 'Catalog Detail',
      component: CatalogDetails,
    },
    {
      path: `/catalogues/${idTypes.catalog}/entry/${idTypes.entry}`,
      name: 'Entry Detail',
      component: EntryDetails,
    },
  ],
};

export const projectRoutes = {
  path: '/projects',
  name: 'Projects',
  icon: faList,
  //   auth: true,
  component: Projects,
};

const projectDetailRoutes = {
  name: 'Project Details',
  path: `/${idTypes.project}/project`,
  icon: faTasks,
  component: ProjectDetail,
};

export const analysisTreeRoutes = {
  path: `/${idTypes.project}/analysisTree`,
  name: 'Analysis Tree',
  icon: faSitemap,
  children: null,
  component: AnalysisTree,
};

const refTreePrivateRoutes = {
  path: `/catalogues/${idTypes.catalog}/reftree/${idTypes.reftree}`,
  name: 'Ref Tree',
  children: null,
  component: AnalysisTree,
};

const nodePrivateRoutes = {
  path: `/${idTypes.project}/analysisTree`,
  name: 'Node Details',
  children: [
    {
      path: `/${idTypes.project}/analysisTree/node-details/${idTypes.node}`,
      component: NodeDetailsPage,
    },
    {
      path: `/catalogues/${idTypes.catalog}/reftree/${idTypes.reftree}/node-details/${idTypes.node}`,
      component: NodeDetailsPage,
    },
  ],
};

export const toolRoute = {
  path: '/application',
  name: 'Application',
  component: Tool,
  icon: faToolbox,
};

export const TARARoute = {
  path: '/tara',
  name: 'TARA',
  component: TARA,
  icon: faAsterisk,
};

export const advisoryRoute = {
  path: '/advisory',
  name: 'Advisory',
  component: Advisory,
  icon: faBullhorn,
};

//Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  passwordResetRoute,
  projectRoutes,
  projectDetailRoutes,
  analysisTreeRoutes,
  riskRoutes,
  entitiesRoutes,
  catalogRoutes,
  profileRoutes,
  profilePrivateRoutes,
  nodePrivateRoutes,
  adminPrivateRoutes,
  legalNoticeRoute,
  loggingRoutes,
  catalogPrivateRoutes,
  refTreePrivateRoutes,
  toolRoute,
  TARARoute,
  // advisoryRoute,
];

//routes seen in sidebar
export const sidebar = [projectRoutes, catalogRoutes];

export const sidebarSupport = [
  toolRoute,
  TARARoute,
  // advisoryRoute
];

export const sidebarNotice = [legalNoticeRoute];

// routes seen in sidebar for when project is selected
// this list replaces the initial sidebar project routes
export const projectSelected = [
  projectRoutes,
  projectDetailRoutes,
  analysisTreeRoutes,
  riskRoutes,
  entitiesRoutes,
];
