import axios from '../../Axios/axios';
import {
  addEntityInTree,
  clearNodeParams,
  updateEntityValueInTree,
} from '../../components/helpers/AnalysisTreeHelper';
import {
  DefaultErrorHandling,
  entityUpdateTemplate,
} from '../../components/helpers/genericHelper';
import {
  EntityType,
  RefTreeActionType,
  RefTreeModType,
} from '../../constants/Enums';
import store from '../store';
import { setAlert } from './alert';
import {
  AddEntityToReftree,
  ModificationAction,
  setEstimationMethodAutomatic,
} from './analysistree';
import { getComments } from './comments';
import {
  ADD_ASSET,
  ADD_CONTROL,
  ADD_THREAT,
  ADD_VULNERABILITY,
  LOAD_ATTACKTREE,
  SET_ENTITY,
  UPDATE_ASSET,
  UPDATE_CONTROL,
  UPDATE_ENTITY,
  UPDATE_THREAT,
  UPDATE_VULNERABILITY,
} from './types';

// set entity
export const setEntity = (entity) => async (dispatch) => {
  let newEntity = await axios.entity
    .get(entity?._id)
    .then((entity) => {
      return entity?.data;
    })
    .catch((error) => {
      DefaultErrorHandling(error, dispatch);
    });

  if (newEntity) {
    newEntity = await getComments(newEntity);
  }
  dispatch({
    type: SET_ENTITY,
    payload: newEntity,
  });
};

// Create new entity
export const addEntity =
  (entity, entityType, parent = null, parentEstimationChange = false) =>
  async (dispatch) => {
    const state = store.getState();
    const project = state.project.project;
    const analysisTree = Object.assign({}, state.analysistree.analysistree);

    if (parentEstimationChange) {
      await setEstimationMethodAutomatic(parent, analysisTree, dispatch);
    }

    let payloadEntity = {};
    if (entityType === EntityType.asset) {
      payloadEntity = Object.assign(
        {},
        {
          ...entity,
          owner: {
            id: parseInt(project._id),
            owner_type: 'project',
          },
        }
      );
    } else {
      payloadEntity = Object.assign({}, entity);
    }
    //Removes payload entity undefined id to avoid bad requests in call
    delete payloadEntity._id;
    delete payloadEntity.review_state;
    delete payloadEntity.comments;
    const payload = entityUpdateTemplate(entityType, payloadEntity);

    let requestString = parent !== null ? parent._id : '';

    let newRootNode = undefined;
    //Ref tree modification actions for parent
    const refTreeModification = state.analysistree.refTreeModificationParams;
    if (
      refTreeModification?.type === RefTreeModType.Addition &&
      parent._id === refTreeModification.params.node._id
    ) {
      //Double check if root node is correct
      newRootNode = await ModificationAction(
        refTreeModification.action,
        refTreeModification.type,
        refTreeModification.params,
        dispatch,
        analysisTree
      );
      requestString = newRootNode._id;
      parent._id = newRootNode._id;
      if (refTreeModification.action === RefTreeActionType.Edit) {
        payload.change_reftree_version = false;
      }
    }

    axios.entity
      .put(requestString, payload)
      .then((response) => {
        const changedObjects = response.data?.changed_entities;
        if (typeof changedObjects === 'object') {
          try {
            Object.entries(changedObjects).map((entityArray) => {
              const id = entityArray[0];
              const entityObject = entityArray[1];
              entityObject._id = parseInt(entityArray[0]);
              if (Number(id) === response.data.new_id) {
                return entityDispatches(
                  dispatch,
                  analysisTree,
                  entityObject,
                  parent,
                  false
                );
              } else {
                return entityDispatches(
                  dispatch,
                  analysisTree,
                  entityObject,
                  null,
                  true
                );
              }
            });
          } catch {}
        }
        //TODO: Remove once asset creation endpoint is aligned with backend
        else if (
          changedObjects === undefined &&
          entityType === EntityType.asset
        ) {
          payloadEntity.review_state = false;
          payloadEntity.entity_type = entityType;
          payloadEntity._id = response.data.new_id;
          entityDispatches(dispatch, analysisTree, payloadEntity, null, false);
        }

        dispatch({
          type: LOAD_ATTACKTREE,
          payload: analysisTree,
        });
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error creating ${entityType} encountered. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

//Update an entity
export const updateEntity = (entity, entityType) => async (dispatch) => {
  const state = store.getState();
  const analysistree = state.analysistree.analysistree;
  const selectedEntity = state.entities.entity;

  //Patching data that includes the _id of the entity results in a bad request
  const payloadEntity = Object.assign({}, entity);
  delete payloadEntity._id;
  delete payloadEntity.comments;
  //In case data comes from tree node, clears node tree data
  clearNodeParams(payloadEntity);

  const payload = entityUpdateTemplate(entityType, payloadEntity);
  let requestString = entity._id;
  let rootId = null;
  let newRootNode = undefined;
  const refTreeModification = state.analysistree.refTreeModificationParams;

  //Modification actions before update
  if (
    refTreeModification?.type === RefTreeModType.Editing &&
    parseInt(entity._id) === parseInt(refTreeModification.params.node._id)
  ) {
    //Double check if root node is correct
    newRootNode = await ModificationAction(
      refTreeModification.action,
      refTreeModification.type,
      refTreeModification.params,
      dispatch,
      analysistree
    );
    requestString = newRootNode?._id;
    rootId = newRootNode?.root_id;
    if (refTreeModification.action === RefTreeActionType.Edit) {
      payload.change_reftree_version = false;
    } else {
      payload.change_reftree_version = true;
    }
  }

  return axios.entity
    .patch(requestString, payload)
    .then(async (response) => {
      //Update action for reftree items
      if (response.data?.copy_of_entity?.owner.owner_type === 'reftree') {
        const treeType = state.profile.userReftrees.find(
          (reftree) =>
            parseInt(reftree?._id) ===
            parseInt(response?.data?.copy_of_entity.owner.id)
        )?.tree_type;
        if (treeType === 'control' || treeType === 'vulnerability') {
          entityUpdateReftreeActions(
            dispatch,
            refTreeModification,
            requestString,
            rootId
          );
        }
      }
      //Update action for regular entities
      else {
        const changedObjects = response.data;
        if (typeof changedObjects === 'object') {
          Object.entries(changedObjects).map((entityArray) => {
            const entityObject = entityArray[1];
            entityObject._id = parseInt(entityArray[0]);
            return entityDispatches(
              dispatch,
              analysistree,
              entityObject,
              undefined,
              true
            );
          });
        }

        dispatch({
          type: LOAD_ATTACKTREE,
          payload: analysistree,
        });

        if (response.data?.[selectedEntity._id] !== undefined) {
          dispatch({
            type: UPDATE_ENTITY,
            payload: response.data?.[selectedEntity._id],
          });
        }
      }
      return true;
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error updating Entity ID# ${requestString} encountered. Error message: 
            ${error?.response?.data?.msg ?? error?.response?.data?.message}`,
          'danger'
        )
      );
      return false;
    });
};

const entityUpdateReftreeActions = (
  dispatch,
  refTreeModification,
  requestString,
  rootId
) => {
  if (refTreeModification?.action === RefTreeActionType.NewReftree) {
    dispatch(setEntity({ _id: requestString }));
    AddEntityToReftree(
      refTreeModification.reftree,
      refTreeModification.catalog,
      { _id: rootId },
      dispatch
    );
    dispatch(
      setAlert(`Entity Successfully Updated ID# ${requestString}`, 'success')
    );
  } else {
    dispatch(setEntity({ _id: requestString }));
    dispatch(
      setAlert(`Entity Successfully Updated ID# ${requestString}`, 'success')
    );
  }
};

export const entityDispatches = (
  dispatch,
  analysistree,
  entity,
  parent = undefined,
  update = false
) => {
  update && updateEntityValueInTree(analysistree, entity, entity.entity_type);
  switch (entity.entity_type) {
    case EntityType.asset:
      if (!update) {
        if (analysistree?.children === undefined) {
          analysistree.children = [entity];
        } else {
          analysistree.children.push(entity);
        }
      }
      dispatch({
        type: update ? UPDATE_ASSET : ADD_ASSET,
        payload: entity,
      });
      break;
    case EntityType.threat:
      !update && addEntityInTree(analysistree, entity, parent);
      dispatch({
        type: update ? UPDATE_THREAT : ADD_THREAT,
        payload: entity,
      });
      break;
    case EntityType.vulnerability:
      !update && addEntityInTree(analysistree, entity, parent);
      dispatch({
        type: update ? UPDATE_VULNERABILITY : ADD_VULNERABILITY,
        payload: entity,
      });
      break;
    case EntityType.control:
      !update && addEntityInTree(analysistree, entity, parent);
      dispatch({
        type: update ? UPDATE_CONTROL : ADD_CONTROL,
        payload: entity,
      });
      break;
    default:
      break;
  }
};

export const checkName = (name, type) => async (dispatch) => {
  const state = store.getState();
  const project = state.project.project;
  if (project === undefined) {
    return undefined;
  }

  const payload = {
    entity_type: type,
    proposed_name: name,
    project_id: project._id,
    similarity_cutoff: 80,
  };
  const sameName = await axios.entity
    .post('similar-entity/', payload)
    .then((response) => {
      return response.data;
    });
  if (sameName && sameName.length > 0) {
    return sameName;
  } else {
    return undefined;
  }
};
