import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Tree from 'react-d3-tree';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  NavbarBrand,
  NavbarToggler,
  Row,
  Spinner,
  UncontrolledCollapse,
} from 'reactstrap';
import { OwnerType, ProjectType, SearchType } from '../../constants/Enums';
import {
  changeReftreeFeasibilityView,
  changeTreeLayout,
  changeViewSelection,
  clearSearchIds,
  setSearchIds,
  toggleModificationWarning,
  toggleReferenceTreeView,
  toggleTreeAnimations,
  updateImpactLevelView,
} from '../../redux/actions/analysistree';
import { setCatalog } from '../../redux/actions/catalog';
import { getControlCatalogs, getReftrees } from '../../redux/actions/profile';
import { setProject, setRefTreeData } from '../../redux/actions/projects';
import { PROJECT_LOADING } from '../../redux/actions/types';
import store from '../../redux/store';
import { InputTemplate } from '../entities/EntityModals/InputTemplate';
import {
  expandTree,
  getFilteredIdsFromTree,
  getFilteredTree,
  GetNodeCoordinates,
} from '../helpers/AnalysisTreeHelper';
import NodeLabelComponent from './NodeLabelComponent';
import {
  baseFocusX,
  baseFocusY,
  fastAnimation,
  noAnimation,
  NodeDistanceX,
  NodeDistanceY,
  nodeHeightAdjustment,
  NodeOffsetX,
  NodeOffsetY,
  nodeSeparationNonSiblings,
  nodeSeparationSiblings,
  nodeWidthAdjustment,
} from './nodetypes/nodeObjects/NodeConstants';
import SearchDropdowns from './SearchDropdowns';

/**
 * Analysis tree.
 *
 * Main page to show the analysis tree
 *
 *  @param analysistree the node of the control
 *  @param viewSelection redux store mapped to state, used to filter risk and likelihood displays in nodes
 *  @param searchIds redux store mapped to state, search IDs obtained from searching
 *  @param changeViewSelection redux action mapped to state, used to adjust view selection value in store
 *  @param clearSearchIds redux action mapped to state, clears current search IDs
 *  @param setSearchIds redux action mapped to state, sets search IDs
 *  @param loading redux store mapped to state, used to hide tree when data has not loaded
 *  @param project redux action mapped to state, used to show project details
 *
 */
const AnalysisTree = ({
  analysistree,
  viewSelection,
  searchIds,
  changeViewSelection,
  clearSearchIds,
  setSearchIds,
  loading,
  project,
  entity,
  showReferenceTrees,
  toggleReferenceTreeView,
  setProject,
  profile,
  RefTreeCatalogs,
  getControlCatalogs,
  getReftrees,
  reftrees,
  disableRefTreeWarnings,
  toggleModificationWarning,
  collapsedReviews,
  reftreeFeasibilitySelection,
  changeReftreeFeasibilityView,
  updateImpactLevelView,
  impactViews,
  selectedProjectType,
  setCatalog,
  setRefTreeData,
  toggleTreeAnimations,
  treeAnimations,
  treeLayout,
  changeTreeLayout,
}) => {
  const { dispatch } = store;
  const { refTreeId, catalogId, projectId } = useParams();
  const query = new URLSearchParams(window.location.search);
  const focusId = query.get('focusNode');
  const editMode = query.get('edit');
  const treeRef = useRef(null);
  useEffect(() => {
    if (editMode) {
      toggleModificationWarning();
    }
  }, [editMode]);

  useEffect(() => {
    if (RefTreeCatalogs === undefined || RefTreeCatalogs?.length === 0) {
      let id = undefined;
      if (isFinite(projectId)) {
        id = projectId;
      }
      getControlCatalogs(id);
      getReftrees(id);
    }

    if (focusId && analysistree !== undefined && treeRef.current !== null) {
      const focusCoordinates = { x: undefined, y: undefined };
      const { data } = treeRef?.current?.state;
      const treeLayout = Object.assign({}, data[0]);
      GetNodeCoordinates(treeLayout, focusId, focusCoordinates);
      if (
        focusCoordinates.x !== undefined &&
        focusCoordinates.y !== undefined
      ) {
        //changes the focus of the tree
        //Coordinates are interchanged (y is x, x is y) and reversed in sign from the tree data

        //Y coordinate provided is missing roughly 40% of the value to display correctly.
        //Adding the 0.4 multiplier roughly addresses the issue
        changeFocus(focusCoordinates.y * -1, focusCoordinates.x * -1.4);
      }
    }
  }, [treeRef, analysistree]);

  useEffect(() => {
    if (profile !== undefined && profile !== null && refTreeId) {
      setCatalog({
        _id: catalogId,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (analysistree === undefined && !loading && reftrees && RefTreeCatalogs) {
      const reftree = reftrees.find(
        (ref) => parseInt(ref?._id) === parseInt(refTreeId)
      );
      const catalog = RefTreeCatalogs.find(
        (catalog) => parseInt(catalog?._id) === parseInt(reftree?.catalog_id)
      );
      if (catalog?.project_id) {
        setProject({ _id: catalog?.project_id }, reftree?.current_version);
      }
      if (refTreeId) {
        dispatch({
          type: PROJECT_LOADING,
          payload: true,
        });
        setRefTreeData({ _id: refTreeId });
      }
    }
  }, [reftrees, RefTreeCatalogs]);

  const TreeDisplayName = () => {
    const projectName = project?.name;
    const currentReftree = reftrees?.find(
      (tree) =>
        tree._id === analysistree?.owner?.id &&
        analysistree?.owner?.owner_type === OwnerType.RefTree
    );
    const treeText =
      projectName !== undefined && currentReftree !== undefined
        ? `${projectName} / ${currentReftree.name}`
        : projectName !== undefined
        ? projectName
        : currentReftree === undefined
        ? currentReftree.name
        : undefined;
    return (
      <Fragment>
        {' '}
        Analysis tree {treeText === undefined ? '' : `of ${treeText}`}
      </Fragment>
    );
  };

  const [selectedSearch, setSearchIndex] = useState(undefined);

  const SetSearchIndexAndFocus = (index, treeData, focusCoordinates) => {
    //Finds coordinates of id to focus on (searchIds[index]) and updates the focusCoordinates Object

    const searchedId = searchIds[index];
    const usageCount = searchIds
      .slice(0, index + 1)
      .reduce(function (result, value, index) {
        if (value === searchedId) {
          result =
            Object.keys(result).length === 0 && result.constructor === Object
              ? 1
              : result + 1;
        }
        return result;
      }, {});
    GetNodeCoordinates(treeData, searchedId, focusCoordinates, usageCount);
    if (focusCoordinates.x !== undefined && focusCoordinates.y !== undefined) {
      //changes the focus of the tree
      //Coordinates are interchanged (y is x, x is y) and reversed in sign from the tree data
      changeFocus(focusCoordinates.y * -1, focusCoordinates.x * -1);
    }
    //Sets the current index searched, applies the search layout of the tree
    setSearchIndex(index);
  };

  //Changes the selected focus of search
  //Not using an indexOffset sets it at the first match
  //Uses a tree layout saved from searching
  const changeSearchIndex = (treeData, indexOffset = undefined) => {
    const { data } = treeData.state;
    const focusCoordinates = { x: undefined, y: undefined };
    if (indexOffset === undefined) {
      SetSearchIndexAndFocus(0, data[0], focusCoordinates);
    } else {
      const index = selectedSearch + indexOffset;
      if (index === searchIds?.length) {
        SetSearchIndexAndFocus(0, data[0], focusCoordinates);
      } else if (index === -1) {
        SetSearchIndexAndFocus(
          searchIds?.length - 1,
          data[0],
          focusCoordinates
        );
      } else {
        SetSearchIndexAndFocus(index, data[0], focusCoordinates);
      }
    }
  };

  const [focus, setFocus] = useState({
    x: baseFocusX,
    y: baseFocusY,
  });

  const changeFocus = (x, y) => {
    setFocus({ x: x + nodeWidthAdjustment, y: y + nodeHeightAdjustment });
  };

  useEffect(() => {
    if (analysistree !== undefined && treeRef.current !== null) {
      const { data } = treeRef.current.state;
      const treeData = data[0];
      expandTree(treeData, showReferenceTrees);
      forceNodesRender();
    }
  }, [showReferenceTrees]);

  //View selection
  const [viewDropdownOpen, setViewDropdownOpen] = useState(false);
  const toggleViewDropdown = () =>
    setViewDropdownOpen((prevState) => !prevState);
  const [viewSelectionData, setViewSelectionData] = useState({
    noControls: false,
    implementedControls: false,
    proposedControls: false,
    allControls: false,
  });

  //View selection
  const [impactDropdown, setImpactDropdown] = useState(false);
  const toggleImpactDropdown = () =>
    setImpactDropdown((prevState) => !prevState);

  const onViewSelectionChange = (e) => {
    const updatedSelectionData = {
      ...viewSelectionData,
      [e.target.name]: e.target.checked,
    };

    //if first three are disabled, defaults to enable all
    if (
      !updatedSelectionData.noControls &&
      !updatedSelectionData.implementedControls &&
      !updatedSelectionData.proposedControls
    ) {
      updatedSelectionData.allControls = true;
    }

    setViewSelectionData(updatedSelectionData);
    changeViewSelection(updatedSelectionData);
  };

  //Runs at render
  //Takes view selection data from store
  useEffect(() => {
    setViewSelectionData({
      ...viewSelectionData,
      noControls: viewSelection?.noControls,
      implementedControls: viewSelection?.implementedControls,
      proposedControls: viewSelection?.proposedControls,
      allControls: viewSelection?.allControls,
    });
  }, []);

  const SearchItems = (refTree) => {
    clearSearchIds();
    const { data } = refTree.state;
    //Creates a duplicate of tree data to use as layout
    const treeLayout = Object.assign({}, data[0]);
    expandTree(data[0], showReferenceTrees, true);
    const matchedIds = [];
    //Gets all matching ids from tree based on criteria
    getFilteredIdsFromTree(searchArray, data[0], matchedIds);
    //Adjusts tree layout based on matching search IDs
    getFilteredTree(
      treeLayout,
      matchedIds,
      showChildNodeSearch,
      showReferenceTrees
    );
    //Sets search IDs in redux store
    setSearchIds(matchedIds);
    //Sets search layout in component
    changeTreeLayout(treeLayout);
  };

  //Once search IDs are updated in redux, changes search index(and adjusts focus)
  useEffect(() => {
    if (treeLayout === undefined) {
      if (treeRef.current?.state) {
        const { data } = treeRef.current.state;
        const treeLayout = Object.assign({}, data[0]);
        expandTree(treeLayout, showReferenceTrees, false);
        changeTreeLayout(treeLayout);
      }
    }
    if (searchIds?.length > 0) {
      changeSearchIndex(treeRef.current, undefined);
    }
  }, [searchIds, treeLayout]);

  //Depth changes
  const minDepth = 0;
  //Undefined will expand tree to full depth
  const maxDepth = undefined;
  const [treeRender, setTreeRender] = useState(false);
  const [depth, setDepth] = useState(maxDepth);

  //Changes default depth of tree and rerenders
  const changeDepth = (depth) => {
    setDepth(depth);
    setTreeRender(!treeRender);
  };

  //A call used to rerender nodes in tree
  const [, updateNodeState] = useState();
  const forceNodesRender = useCallback(() => updateNodeState({}), []);

  //Node click handler
  const expandChildren = (targetNode, tree, data) => {
    /* eslint-disable */
    targetNode._collapsed = !targetNode._collapsed;

    const treeInternalState = tree.internalState;

    if (treeInternalState) {
      internalState.targetNode = targetNode;
    }
    /* eslint-enable */
    forceNodesRender();
  };

  const handleNodeClick = (nodeId, tree) => {
    const { data } = tree.state;
    const matches = tree.findNodesById(nodeId, data, []);
    const targetNode = matches[0];

    /* eslint-disable no-underscore-dangle */
    const children = targetNode._children;
    /* eslint-enable no-underscore-dangle */

    if (children && children.length > 0) {
      expandChildren(targetNode, treeRef, data);
      changeTreeLayout(data[0]);
    }
  };

  const handleTreeClick = (nodeObj, evt) => {
    const collapseButton = '.CollapseButton';
    //Checks if the svg, the font icon, or the button are the ones being clicked
    const collapseTrigger =
      evt.target.name === collapseButton ||
      evt.target.parentElement.name === collapseButton ||
      evt.target.parentElement.parentElement.name === collapseButton;
    return collapseTrigger && handleNodeClick(nodeObj.id, treeRef.current);
  };

  //Option to show child nodes of matching nodes on search
  const [showChildNodeSearch, setShowChildNodeSearch] = useState(false);
  const toggleShowChildOnSearch = () =>
    setShowChildNodeSearch(!showChildNodeSearch);

  //Search criterias
  const [searchArray, setSearchArray] = useState([{}]);
  const addSearchField = () => setSearchArray([...searchArray, {}]);
  const removeSearchField = (index) => {
    const newArray = Object.assign([], searchArray);
    newArray.splice(index, 1);
    setSearchArray(newArray);
  };

  const changeSearchType = (index, searchType) => {
    const newArray = Object.assign([], searchArray);
    newArray[index]['searchType'] = searchType;

    if (
      searchType === SearchType.Risk ||
      searchType === SearchType.Likelihood
    ) {
      newArray[index]['payload'] = { upper: 0, lower: 0 };
    } else {
      newArray[index]['payload'] = undefined;
    }
    setSearchArray(newArray);
  };

  const updateSearchField = (index, payload) => {
    const newArray = Object.assign([], searchArray);
    newArray[index].payload = payload;
    setSearchArray(newArray);
  };

  const updateLowerBoundSearchField = (index, lowerBound) => {
    const newArray = Object.assign([], searchArray);

    newArray[index].payload['lower'] = lowerBound;

    setSearchArray(newArray);
  };

  const updateUpperBoundSearchField = (index, upperBound) => {
    const newArray = Object.assign([], searchArray);
    newArray[index].payload['upper'] = upperBound;

    setSearchArray(newArray);
  };

  if (
    (project === undefined || project === null) &&
    loading === false &&
    profile !== null
  ) {
    const rootId = parseInt(
      new URLSearchParams(window.location.search).get('rootId')
    );
    if (projectId !== undefined && !isNaN(projectId)) {
      const projectTemplate = { _id: projectId };
      setProject(projectTemplate, rootId);
    }
  }

  const buttonScale = 'scale(1.5)';

  return (
    <Fragment>
      {loading || analysistree === undefined ? (
        <Container>
          <Spinner className='spinner' />
        </Container>
      ) : (
        <Container>
          <Card className='no-border display-block'>
            <h1 style={{ marginLeft: '10px' }}>
              <TreeDisplayName />
            </h1>
            {disableRefTreeWarnings && (
              <Fragment>
                <Alert color='danger' style={{ marginLeft: 0 }}>
                  <h3>
                    Warning! When you make changes to this reference tree{' '}
                    {project !== undefined
                      ? 'all changes will be applied to all instances of this reference tree within the project.'
                      : 'each project in which this reference tree is used will be notified about those changes.'}
                  </h3>
                </Alert>
                <br />
              </Fragment>
            )}
            <NavbarBrand
              className='mr-auto float-right'
              style={{ marginTop: '-3rem' }}
            >
              Options
              <NavbarToggler
                id='attackTreeNavToggle'
                className='mr-2 float-right'
              >
                <FontAwesomeIcon icon={faBars} />
              </NavbarToggler>
            </NavbarBrand>

            <UncontrolledCollapse toggler='#attackTreeNavToggle'>
              <Card>
                <CardBody>
                  <Col>
                    <Row
                      className='button-stack'
                      style={{
                        margin: 'auto',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col style={{ maxWidth: '210px' }}>
                        <Row>
                          <Col>
                            <Dropdown
                              isOpen={viewDropdownOpen}
                              toggle={toggleViewDropdown}
                            >
                              <DropdownToggle
                                caret
                                className='btn-add'
                                style={{ borderRadius: '6px', width: '180px' }}
                              >
                                Select View
                              </DropdownToggle>
                              <DropdownMenu
                                modifiers={{
                                  fixedWidth: {
                                    enabled: true,
                                    order: 840,
                                    fn: (data) => {
                                      return {
                                        ...data,
                                        styles: {
                                          ...data.styles,
                                          overflow: 'auto',
                                          width: '475px',
                                          maxWidth: '475px',
                                        },
                                      };
                                    },
                                  },
                                }}
                              >
                                <div style={{ marginLeft: 10 }}>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        checked={viewSelectionData.noControls}
                                        type='checkbox'
                                        name='noControls'
                                        onChange={(e) =>
                                          onViewSelectionChange(e)
                                        }
                                      />{' '}
                                      Likelihood and Risk without any controls
                                    </Label>
                                  </FormGroup>
                                </div>
                                <DropdownItem divider />
                                <div style={{ marginLeft: 10 }}>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        checked={
                                          viewSelectionData.implementedControls
                                        }
                                        type='checkbox'
                                        name='implementedControls'
                                        onChange={(e) =>
                                          onViewSelectionChange(e)
                                        }
                                      />{' '}
                                      Likelihood and Risk with implemented
                                      controls
                                    </Label>
                                  </FormGroup>
                                </div>
                                <DropdownItem divider />
                                <div style={{ marginLeft: 10 }}>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        checked={
                                          viewSelectionData.proposedControls
                                        }
                                        type='checkbox'
                                        name='proposedControls'
                                        onChange={(e) =>
                                          onViewSelectionChange(e)
                                        }
                                      />{' '}
                                      Likelihood and Risk with implemented and
                                      proposed controls
                                    </Label>
                                  </FormGroup>
                                </div>
                                <DropdownItem divider />
                                <div style={{ marginLeft: 10 }}>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        checked={viewSelectionData.allControls}
                                        type='checkbox'
                                        name='allControls'
                                        onChange={(e) =>
                                          onViewSelectionChange(e)
                                        }
                                      />{' '}
                                      Likelihood and Risk with all controls
                                    </Label>
                                  </FormGroup>
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Dropdown
                              isOpen={impactDropdown}
                              toggle={toggleImpactDropdown}
                            >
                              <DropdownToggle
                                caret
                                className='btn-add'
                                style={{ borderRadius: '6px', width: '180px' }}
                              >
                                Impact Level View
                              </DropdownToggle>
                              <DropdownMenu
                                modifiers={{
                                  fixedWidth: {
                                    enabled: true,
                                    order: 840,
                                    fn: (data) => {
                                      return {
                                        ...data,
                                        styles: {
                                          ...data.styles,
                                          overflow: 'auto',
                                          maxWidth: '180px',
                                        },
                                      };
                                    },
                                  },
                                }}
                              >
                                {Object.entries(
                                  ProjectType[
                                    project?.project_type ?? selectedProjectType
                                  ].ImpactLevelTypes
                                ).map((impactEntry) => {
                                  const entries = Object.entries(
                                    ProjectType[
                                      project?.project_type ??
                                        selectedProjectType
                                    ].ImpactLevelTypes
                                  );
                                  return (
                                    <Fragment>
                                      <div style={{ marginLeft: 10 }}>
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              checked={impactViews?.includes(
                                                impactEntry[0]
                                              )}
                                              type='checkbox'
                                              name='noControls'
                                              onChange={() =>
                                                updateImpactLevelView(
                                                  impactEntry[0]
                                                )
                                              }
                                            />{' '}
                                            {impactEntry[1]}
                                          </Label>
                                        </FormGroup>
                                      </div>
                                      {entries[entries.length - 1][1] !==
                                        impactEntry[1] && (
                                        <DropdownItem divider />
                                      )}
                                    </Fragment>
                                  );
                                })}
                              </DropdownMenu>
                            </Dropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              onClick={() => changeDepth(maxDepth)}
                              style={{ width: '180px' }}
                            >
                              Expand all nodes
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              onClick={() => changeDepth(minDepth)}
                              style={{ width: '180px' }}
                            >
                              Collapse all nodes
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <SearchDropdowns
                            index={0}
                            searchField={searchArray[0]}
                            changeSearch={changeSearchType}
                            removeField={removeSearchField}
                            addField={addSearchField}
                            updateField={updateSearchField}
                            updateUpperBound={updateUpperBoundSearchField}
                            updateLowerBound={updateLowerBoundSearchField}
                            projectType={
                              project?.project_type ?? selectedProjectType
                            }
                          />
                        </Row>
                        {searchArray.map((searchField, index) => {
                          if (index === 0) {
                            return null;
                          }
                          return (
                            <Row>
                              <SearchDropdowns
                                index={index}
                                searchField={searchField}
                                changeSearch={changeSearchType}
                                removeField={removeSearchField}
                                addField={addSearchField}
                                updateField={updateSearchField}
                                updateUpperBound={updateUpperBoundSearchField}
                                updateLowerBound={updateLowerBoundSearchField}
                                projectType={
                                  project?.project_type ?? selectedProjectType
                                }
                              />
                            </Row>
                          );
                        })}
                      </Col>
                      <Col xs={3}>
                        <Row>
                          {searchIds?.length > 0 && (
                            <div>
                              <Button
                                disabled={searchIds?.length === 0}
                                onClick={() =>
                                  changeSearchIndex(treeRef.current, -1)
                                }
                              >
                                Previous
                              </Button>
                              {`${selectedSearch + 1}/${
                                searchIds?.length
                              }'   '`}
                              <Button
                                disabled={searchIds?.length === 0}
                                onClick={() =>
                                  changeSearchIndex(treeRef.current, 1)
                                }
                              >
                                Next
                              </Button>
                            </div>
                          )}
                          <Button onClick={() => SearchItems(treeRef.current)}>
                            <FontAwesomeIcon icon={faSearch} /> Search
                          </Button>
                        </Row>
                        <Row>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type='checkbox'
                                onClick={toggleShowChildOnSearch}
                                checked={showChildNodeSearch}
                                style={{ transform: buttonScale }}
                              />
                              Show child nodes on search
                            </Label>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type='checkbox'
                                onClick={toggleTreeAnimations}
                                checked={treeAnimations}
                                style={{ transform: buttonScale }}
                              />
                              Enable analysis tree animation
                            </Label>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type='checkbox'
                                onClick={() =>
                                  toggleReferenceTreeView(!showReferenceTrees)
                                }
                                checked={showReferenceTrees}
                                style={{ transform: buttonScale }}
                              />
                              Show reference trees
                            </Label>
                          </FormGroup>
                        </Row>
                      </Col>
                    </Row>
                    {analysistree?.owner?.owner_type === OwnerType.RefTree && (
                      <Fragment>
                        <Row style={{ marginLeft: 2, marginTop: 8 }}>
                          <Col>
                            <Button onClick={toggleModificationWarning}>
                              Editing mode
                            </Button>
                          </Col>
                        </Row>
                        <Row style={{ marginLeft: 2, marginTop: 8 }}>
                          <Col>
                            {/* selected attack feasibility type */}
                            <InputTemplate
                              label='Select feasibility type'
                              type='select'
                              value={reftreeFeasibilitySelection}
                              style={{ width: '180px' }}
                              onChange={(event) => {
                                changeReftreeFeasibilityView(
                                  event.target.value
                                );
                              }}
                            >
                              {Object.entries(ProjectType).map((type) => {
                                return (
                                  <option
                                    key={type[1].Value}
                                    value={type[1].Value}
                                  >
                                    {type[1].Name}
                                  </option>
                                );
                              })}
                            </InputTemplate>
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
            <CardBody style={{ paddingLeft: 0 }}>
              <Container fluid className='analysis-tree-container'>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      flex: '1 1 auto',
                      height: '82.5vh',
                      border: '2px solid rgb(240,240,240)',
                      borderRadius: '5px',
                    }}
                  >
                    <Tree
                      data={analysistree}
                      //Key used to force remount when search is applied
                      key={treeRender}
                      //Used to start how deep the tree opens
                      initialDepth={depth}
                      //Changed to focus on search
                      translate={{ x: focus.x, y: focus.y }}
                      allowForeignObjects
                      nodeSvgShape={{ shape: 'none' }}
                      pathFunc='step'
                      nodeSize={{
                        x: NodeDistanceX,
                        y: NodeDistanceY(
                          viewSelectionData,
                          analysistree,
                          collapsedReviews,
                          impactViews
                        ),
                      }}
                      nodeLabelComponent={{
                        render: <NodeLabelComponent />,
                        //foreignObjectWrapper sets node offset in relation to node svg shape
                        foreignObjectWrapper: {
                          x: NodeOffsetX,
                          y: NodeOffsetY,
                        },
                      }}
                      separation={{
                        siblings: nodeSeparationSiblings,
                        nonSiblings: nodeSeparationNonSiblings,
                      }}
                      //Custom click handler
                      onClick={handleTreeClick}
                      ref={treeRef}
                      collapsible={false}
                      transitionDuration={
                        treeAnimations ? fastAnimation : noAnimation
                      }
                    />
                  </div>
                </div>
              </Container>
            </CardBody>
          </Card>
        </Container>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  analysistree: state.analysistree.analysistree,
  treeLayout: state.analysistree.treeLayout,
  project: state.project.project,
  profile: state.profile.profile,
  loading: state.project.loading,
  viewSelection: state.analysistree.viewSelection,
  searchIds: state.analysistree.searchIds,
  entity: state.entities.entity,
  showReferenceTrees: state.analysistree.showReferenceTrees,
  RefTreeCatalogs: state.profile.userRefTreeCatalogs,
  reftrees: state.profile.userReftrees,
  disableRefTreeWarnings: state.analysistree.disableRefTreeWarnings,
  collapsedReviews: state.analysistree.collapsedReviews,
  reftreeFeasibilitySelection: state.analysistree.reftreeFeasibilitySelection,
  impactViews: state.analysistree.impactViews,
  selectedProjectType: state.analysistree.selectedProjectType,
  treeAnimations: state.analysistree.treeAnimations,
});
export default connect(mapStateToProps, {
  changeViewSelection,
  clearSearchIds,
  setSearchIds,
  toggleReferenceTreeView,
  setProject,
  getControlCatalogs,
  getReftrees,
  toggleModificationWarning,
  changeReftreeFeasibilityView,
  updateImpactLevelView,
  setCatalog,
  setRefTreeData,
  toggleTreeAnimations,
  changeTreeLayout,
})(AnalysisTree);
