import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CardBody,
  CardTitle,
  Container,
  Label,
  NavbarBrand,
  NavbarToggler,
  UncontrolledCollapse,
} from 'reactstrap';
import {
  ImpactCategory,
  LikelihoodEstimation,
  ProjectType,
  SecurityGoal,
} from '../../../constants/Enums';
import {
  AllControlLikelihood,
  ID,
  ImpactCategoryText,
  ImpactLevel,
  Name,
  NoControlLikelihood,
  Review,
  SecurityGoalText,
} from '../../../constants/StringConstants';
import EntityTable from '../EntityTable';
// Lists vulnerability Table
const ThreatScenarioTable = ({ vulnerabilities, project, threats }) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const changeHeaders = (chosenColumns, isChecked) => {
    if (chosenColumns) {
      if (!isChecked) {
        setHeaders([...headers, chosenColumns]);
      } else {
        setHeaders(
          headers?.filter((header) => header?.label !== chosenColumns?.label)
        );
      }
    } else {
      setHeaders([]);
    }
  };

  useEffect(() => {
    const data = vulnerabilities.map((vulnerability) => ({
      ...vulnerability,
      parent_node: threats.find((threat) =>
        threat?.children?.some(
          (child) =>
            parseInt(child) === parseInt(vulnerability._id) ||
            parseInt(child?._id) === parseInt(vulnerability._id)
        )
      ),
    }));
    setData(data);
    const urlParams = new URLSearchParams(window.location.search);
    const columnHeader = [
      columns.find((column) => column.label === ID),
      columns.find((column) => column.label === Name),
      columns.find((column) => column.label === SecurityGoalText),
      columns.find((column) => column.label === ImpactCategoryText),
      columns.find((column) => column.label === ImpactLevel),
      columns.find((column) => column.label === Review),
    ];
    if (urlParams.get('risk') === 'all') {
      setHeaders([
        ...columnHeader,
        columns.find((column) => column.label === 'All Control Risk'),
        columns.find((column) => column.label === 'All Control Feasibility'),
      ]);
    } else if (urlParams.get('risk') === 'proposed') {
      setHeaders([
        ...columnHeader,
        columns.find((column) => column.label === 'Proposed Control Risk'),
        columns.find(
          (column) => column.label === 'Proposed Control Feasibility'
        ),
      ]);
    } else if (urlParams.get('risk') === 'implemented') {
      setHeaders([
        ...columnHeader,
        columns.find((column) => column.label === 'Implemented Control Risk'),
        columns.find(
          (column) => column.label === 'Implemented Control Feasibility'
        ),
      ]);
    } else if (urlParams.get('risk') === 'no_control') {
      setHeaders([
        ...columnHeader,
        columns.find((column) => column.label === 'No Control Risk'),
        columns.find((column) => column.label === 'No Control Feasibility'),
      ]);
    } else {
      setHeaders([
        columns.find((column) => column.label === ID),
        columns.find((column) => column.label === Name),
        columns.find((column) => column.label === NoControlLikelihood),
        columns.find((column) => column.label === AllControlLikelihood),
        columns.find((column) => column.label === Review),
      ]);
    }
  }, [vulnerabilities]);

  //Columns
  const columns = useMemo(() => [
    {
      label: ID,
      value: function (datum) {
        return datum?._id;
      },
      field: '_id',
      type: 'number',
    },
    {
      label: 'Parent Node',
      value: function (datum) {
        return datum?.parent_node?.name;
      },
      field: 'parent',
      type: 'text',
    },
    {
      label: Name,
      value: function (datum) {
        return datum?.name;
      },
      field: 'name',
      type: 'text',
    },
    {
      label: 'Description',
      value: function (datum) {
        return datum?.description;
      },
      field: 'description',
      type: 'text',
    },
    {
      label: Review,
      value: function (datum) {
        return datum?.review_state;
      },
      field: 'review_state',
      type: 'checkbox',
    },
    {
      label: 'Review Comments',
      value: function (datum) {
        let count = 0;
        if (datum.comments?.autoid) {
          count = parseInt(datum.comments.autoid);
        }
        return count;
      },
      field: 'comments',
      type: 'number',
    },
    {
      label: ImpactLevel,
      value: function (datum) {
        return datum.impact_level;
      },
      field: 'impact_level',
      type: 'select',
      enum:
        ProjectType[project?.project_type]?.ImpactLevelTypes &&
        Object.entries(
          ProjectType[project?.project_type]?.ImpactLevelTypes
        ).map((option) => {
          return <option value={option[1]}>{option[1]}</option>;
        }),
    },
    {
      label: ImpactCategoryText,
      value: function (datum) {
        return datum.impact_category;
      },
      field: 'impact_category',
      type: 'select',
      enum: Object.entries(ImpactCategory).map((goal) => {
        return <option value={goal[1].value}>{goal[1].value}</option>;
      }),
    },
    {
      label: SecurityGoalText,
      value: function (datum) {
        return datum.security_goal;
      },
      field: 'security_goal',
      type: 'select',
      enum: Object.entries(SecurityGoal).map((goal) => {
        return <option value={goal[1].value}>{goal[1].value}</option>;
      }),
    },
    {
      label: 'Operator',
      value: function (datum) {
        return datum.operator;
      },
      field: 'operator',
      type: 'select',
      enum: (
        <>
          <option value='OR'>OR</option>
          <option value='AND'>AND</option>
        </>
      ),
    },
    {
      label: 'Likelihood Estimation Method',
      value: function (datum) {
        return datum.likelihood_estimation_method;
      },
      field: 'likelihood_estimation_method',
      type: 'select',
      enum: Object.entries(LikelihoodEstimation).map((method) => {
        return <option value={method[1]}>{method[1]}</option>;
      }),
    },
    {
      label: 'Likelihood Category Ratings',
      value: function (datum) {
        if (
          datum.likelihood_estimation_method !==
          LikelihoodEstimation['Category Based']
        ) {
          return 'N/A';
        } else {
          return `Window of Oppurtunity: ${
            ProjectType[project?.project_type].OpportunityWindow[
              datum.window_of_opportunity
            ]
          }, Elapsed Time: ${
            ProjectType[project?.project_type].ElapsedTime[datum.elapsed_time]
          }, Equipment: ' +
            ${
              ProjectType[project?.project_type].Equipment[datum.equipment]
            } , Specialist Expertise: ${
            ProjectType[project?.project_type].SpecialistExpertise[
              datum.specialist_knowledge
            ]
          }, Knowledge of Item: ${
            ProjectType[project?.project_type].ComponentKnowledge[
              datum.item_knowledge
            ]
          }`;
        }
      },
      field: 'likelihood_category_rating',
      type: 'text',
    },
    {
      label: NoControlLikelihood,
      value: function (datum) {
        return datum?.no_control_likelihood;
      },
      field: 'no_control_likelihood',
      type: 'text',
    },
    {
      label: 'Implemented Control Likelihood',
      value: function (datum) {
        return datum?.implemented_control_likelihood;
      },
      field: 'implemented_control_likelihood',
      type: 'text',
    },
    {
      label: 'Proposed Control Likelihood',
      value: function (datum) {
        return datum?.proposed_control_likelihood;
      },
      field: 'proposed_control_likelihood',
      type: 'text',
    },
    {
      label: AllControlLikelihood,
      value: function (datum) {
        return datum?.all_control_likelihood;
      },
      field: 'all_control_likelihood',
      type: 'text',
    },
    {
      label: 'No Control Feasibility',
      value: function (datum) {
        return ProjectType[project?.project_type]?.AttackFeasibility.find(
          (feasibility) =>
            datum?.no_control_likelihood <= feasibility.upper &&
            datum?.no_control_likelihood > feasibility.lower
        )?.value;
      },
      field: 'no_control_feasibility',
      type: 'select',
      enum: ProjectType[project?.project_type]?.AttackFeasibility.map(
        (goal) => {
          return <option value={goal.value}>{goal.value}</option>;
        }
      ),
    },
    {
      label: 'Implemented Control Feasibility',
      value: function (datum) {
        return ProjectType[project?.project_type]?.AttackFeasibility.find(
          (feasibility) =>
            datum?.implemented_control_likelihood <= feasibility.upper &&
            datum?.implemented_control_likelihood > feasibility.lower
        )?.value;
      },
      field: 'implemented_control_feasibility',
      type: 'select',
      enum: ProjectType[project?.project_type]?.AttackFeasibility.map(
        (goal) => {
          return <option value={goal.value}>{goal.value}</option>;
        }
      ),
    },
    {
      label: 'Proposed Control Feasibility',
      value: function (datum) {
        return ProjectType[project?.project_type]?.AttackFeasibility.find(
          (feasibility) =>
            datum?.proposed_control_likelihood <= feasibility.upper &&
            datum?.proposed_control_likelihood > feasibility.lower
        )?.value;
      },
      field: 'proposed_control_feasibility',
      type: 'select',
      enum: ProjectType[project?.project_type]?.AttackFeasibility.map(
        (goal) => {
          return <option value={goal.value}>{goal.value}</option>;
        }
      ),
    },
    {
      label: 'All Control Feasibility',
      value: function (datum) {
        return ProjectType[project?.project_type]?.AttackFeasibility.find(
          (feasibility) =>
            datum?.all_control_likelihood <= feasibility.upper &&
            datum?.all_control_likelihood > feasibility.lower
        )?.value;
      },
      field: 'all_control_feasibility',
      type: 'select',
      enum: ProjectType[project?.project_type]?.AttackFeasibility.map(
        (goal) => {
          return <option value={goal.value}>{goal.value}</option>;
        }
      ),
    },
    {
      label: 'No Control Risk',
      value: function (datum) {
        return datum.no_control_risk;
      },
      field: 'no_control_risk',
      type: 'number',
    },
    {
      label: 'Implemented Control Risk',
      value: function (datum) {
        return datum.implemented_control_risk;
      },
      field: 'implemented_control_risk',
      type: 'number',
    },
    {
      label: 'Proposed Control Risk',
      value: function (datum) {
        return datum.proposed_control_risk;
      },
      field: 'proposed_control_risk',
      type: 'number',
    },
    {
      label: 'All Control Risk',
      value: function (datum) {
        return datum.all_control_risk;
      },
      field: 'all_control_risk',
      type: 'number',
    },
    {
      label: 'Assignment',
      value: function (datum) {
        return datum.owner.usage;
      },
      field: 'assignment',
      type: 'select',
      enum: (
        <>
          <option value='A'>Analysis Tree</option>
          <option value='R'>Ref Tree</option>
          <option value='C'>Catalog</option>
        </>
      ),
    },
  ]);

  return (
    <Container fluid='md'>
      <CardTitle tag='h1' className='mb-0'>
        <h1>Threat Scenarios of {project?.name}</h1>
      </CardTitle>
      <NavbarBrand
        className='mr-auto float-right'
        style={{ marginTop: '-3rem' }}
      >
        Options
        <NavbarToggler id='assetTableNavToggle' className='mr-2 float-right'>
          <FontAwesomeIcon icon={faBars} />
        </NavbarToggler>
      </NavbarBrand>

      <UncontrolledCollapse toggler='#assetTableNavToggle'>
        <Label>Select Columns</Label>{' '}
        <ul>
          {columns.map((column) => {
            var selected = columns?.filter(
              (column) =>
                Array.isArray(headers) &&
                headers?.some((header) => header?.label === column?.label)
            );
            var isChecked = selected
              .map((header) => header.label)
              .includes(column.label);
            return (
              <li style={{ marginLeft: 50 }}>
                <input
                  type='checkbox'
                  className='btn-icon'
                  checked={isChecked}
                  onClick={() => changeHeaders(column, isChecked)}
                />
                <font style={{ marginLeft: 10 }}>{column.label}</font>
              </li>
            );
          })}
        </ul>
      </UncontrolledCollapse>
      <CardBody>
        <EntityTable title={'Threat Scenario'} headers={headers} data={data} />
      </CardBody>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  project: state.project.project,
  threats: state.threats.threats,
});
export default withRouter(connect(mapStateToProps)(ThreatScenarioTable));
