import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  CardTitle,
  Container,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { UserPermissions } from '../../constants/Enums';
import { LocalTimeWithoutSeconds } from '../../constants/StringConstants';
import { setAlert } from '../../redux/actions/alert';
import { dismissRegisterError, resetRegister } from '../../redux/actions/auth';
import {
  deleteUser,
  getAdminCatalogs,
  getAdminProjects,
  getProfiles,
  revokeSelfAdmin,
  updateUser,
} from '../../redux/actions/profile';
import { withSidebar } from '../../redux/actions/sidebar';
import RegisterModal from '../auth/RegisterModal';
import DraggableModal from '../entities/EntityModals/DraggableModal';
import {
  emailFormatAllowed,
  hasPermission,
  ownerDeletionCheck,
} from '../helpers/genericHelper';
import CustomDataTableStyles from '../layout/DataTableStyles';
import AutoCompleteInput from '../projects/project-components/AutoCompleteInput';
import DeleteModal from '../projects/project-components/DeleteModal';
import CreatorModal from './CreatorModal';
import ExpandedUserManagementComponent from './ExpandedUserManagementComponent';

/**
 * Admin User Management page
 *
 * Page used to manage users and roles
 *
 *  @param isVisible redux state mapped to props, value to show sidebar or not
 *  @param sidebar redux action mapped to props, toggles sidebar
 *  @param projects redux state mapped to props, project data obtained from backend
 *  @param setAlert redux action to create alerts
 *  @param profile redux state mapped to props, current user data
 *
 */

const MailComponent = ({ value, onChange }) => (
  <>
    <Input
      id='mail'
      name='mail'
      type='mail'
      value={value}
      onChange={onChange}
      autoFocus
    />
  </>
);

const UserManagement = ({
  isVisible,
  sidebar,
  projects,
  setAlert,
  profile,
  profiles,
  getProfiles,
  deleteUser,
  updateUser,
  loading,
  revokeSelfAdmin,
  getAdminProjects,
  getAdminCatalogs,
  registered,
  registerError,
  dismissRegisterError,
  resetRegister,
  registerErrorMessage,
}) => {
  if (registered) {
    resetRegister();
  }

  useEffect(() => {
    getProfiles();
    getAdminProjects();
    getAdminCatalogs();
  }, []);

  const history = useHistory();

  const isCreator = (row) =>
    hasPermission(row?.permissions, UserPermissions.Creator);

  //Methods for Deleting a user
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const toggleDeleteModal = (row) => {
    setDeleteId(row ? row._id : -1);
    setDeleteModal(!deleteModal);
  };

  const deleteUserMethod = (row) => {
    deleteUser(row);
  };

  const [grantCreatorModal, setGrantCreatorModal] = useState(false);
  const toggleGrantCreatorModal = () =>
    setGrantCreatorModal(!grantCreatorModal);
  const [grantCreatorRow, setGrantCreatorRow] = useState(false);

  //Methods for granting a user admin rights
  const [grantAdminModal, setGrantAdminModal] = useState(false);
  const [grantId, setGrantId] = useState(false);
  const toggleGrantAdminModal = (row) => {
    setGrantId(row ? row._id : -1);
    setGrantAdminModal(!grantAdminModal);
  };

  const onGrant = async (row) => {
    updateUser(row._id, { permissions: [...row.permissions, 'user_admin'] });
  };

  //Methods for Revoking user's admin rights
  const [revokeAdminModal, setRevokeAdminModal] = useState(false);
  const [revokeId, setRevokeId] = useState(false);
  const toggleRevokeAdminModal = (row) => {
    setRevokeId(row ? row._id : -1);
    setRevokeAdminModal(!revokeAdminModal);
  };

  const onRevoke = async (row) => {
    const newPermissions = row?.permissions.filter(
      (role) => role !== UserPermissions.Admin
    );
    revokeSelfAdmin(row._id, { permissions: newPermissions });
  };

  const [selfRevokeModal, setSelfRevokeModal] = useState(false);
  const [selfRevokeId, setSelfRevokeId] = useState(false);
  const toggleSelfRevokeModal = (row) => {
    setSelfRevokeId(row ? row._id : -1);
    setSelfRevokeModal(!selfRevokeModal);
  };

  const [addUserModal, setAddUserModal] = useState(false);
  const toggleAddUserModal = () => setAddUserModal(!addUserModal);

  const [editUserMode, setEditUserMode] = useState(false);
  const [editId, setEditId] = useState(false);
  const cancel = () => {
    setEditUserMode(false);
  };

  const editInputTrigger = (row) => {
    setEditId(row ? row._id : -1);
    setEditUserMode(true);
  };
  const [formData, setFormData] = useState({
    mail: '',
  });

  //Event handling for inputs
  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onSubmit = async (row) => {
    updateUser(row, formData);
  };

  const userDeleteOwnerCheck = (user, projects) => {
    let onlyOwner = false;

    projects.every((project) => {
      onlyOwner = ownerDeletionCheck(user, project);
      return !onlyOwner;
    });
    return onlyOwner;
  };

  const revokeRightsString = 'Revoke admin rights';

  const columns = useMemo(() => [
    {
      name: 'Username',
      selector: 'name',
      sortable: true,
      maxWidth: '300px',
    },
    {
      name: 'Email',
      selector: 'mail',
      sortable: true,
      maxWidth: '300px',
      //Actions for every project
      cell: (row) =>
        editUserMode && editId === row._id ? (
          <MailComponent
            value={formData.mail ?? row.mail}
            onChange={onChange}
          />
        ) : (
          <Fragment>{row.mail}</Fragment>
        ),
    },
    {
      name: 'Creation Date',
      selector: 'creation_date',
      sortable: true,
      maxWidth: '150px',
      format: (date) => LocalTimeWithoutSeconds(date.creation_date),
    },
    {
      //Actions for every project
      cell: (row) => (
        <Fragment>
          {editUserMode && editId === row._id ? (
            <Fragment>
              <Button
                id={`saveButton${row._id}`}
                className='btn-add'
                onClick={() => {
                  onSubmit(row._id);
                  setEditUserMode(false);
                }}
                disabled={!emailFormatAllowed(formData.mail)}
              >
                <i className='fa fa-save' />
              </Button>
              <UncontrolledTooltip target={`saveButton${row._id}`}>
                Save
              </UncontrolledTooltip>
              <Button
                id={`cancelButton${row._id}`}
                className='btn-danger'
                onClick={() => {
                  cancel();
                }}
              >
                <i className='fa fa-times' />
              </Button>
              <UncontrolledTooltip target={`cancelButton${row._id}`}>
                Cancel
              </UncontrolledTooltip>
            </Fragment>
          ) : (
            <>
              <Button
                id={`editUserButton${row._id}`}
                onClick={() => {
                  editInputTrigger(row);
                }}
              >
                <i className='fa fa-edit' />
              </Button>
              <UncontrolledTooltip target={`editUserButton${row._id}`}>
                Edit
              </UncontrolledTooltip>
            </>
          )}
          <Button
            id={`deleteUserButton${row._id}`}
            className='btn-danger'
            onClick={() => {
              hasPermission(profile?.permissions, UserPermissions.Admin)
                ? !userDeleteOwnerCheck(row, projects)
                  ? toggleDeleteModal(row)
                  : setAlert(
                      'In each project and each global catalogue, there has to be at least one team member with the role owner at all times!',
                      'danger'
                    )
                : setAlert('You are not allowed to delete this User', 'danger');
            }}
          >
            <i className='fa fa-trash' />
          </Button>
          <UncontrolledTooltip target={`deleteUserButton${row._id}`}>
            Delete
          </UncontrolledTooltip>

          <DeleteModal
            isOpen={deleteModal && deleteId === row._id}
            toggle={toggleDeleteModal}
            onClick={() => {
              deleteUserMethod(row._id);
              toggleDeleteModal();
            }}
            message='Are you sure you want to remove this user from ISAT?'
            header='Remove User'
          />

          {!hasPermission(row?.permissions, UserPermissions.Admin) ? (
            <div>
              <Button
                id={`grantAdminRightsButton${row._id}`}
                onClick={() => {
                  toggleGrantAdminModal(row);
                }}
                className='btn-add'
              >
                <i className='fa fa-user-check btn-icon' />
              </Button>
              <UncontrolledTooltip target={`grantAdminRightsButton${row._id}`}>
                Grant admin rights
              </UncontrolledTooltip>
              <DraggableModal
                isOpen={grantAdminModal && grantId === row._id}
                toggle={toggleGrantAdminModal}
              >
                <ModalHeader>
                  <div className='confirm-modal-header'>Grant admin rights</div>
                </ModalHeader>
                <div className='confirm-modal-line' />
                <ModalBody className='confirm-modal-body'>
                  Are you sure you want to grant admin rights to this user?
                  <FormGroup>
                    <br />
                    <Button
                      type='submit'
                      className='btn-add'
                      onClick={() => {
                        toggleGrantAdminModal();
                        onGrant(row);
                      }}
                    >
                      Ok
                    </Button>
                    <Button
                      className='btn-danger'
                      onClick={() => {
                        toggleGrantAdminModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                </ModalBody>
              </DraggableModal>
            </div>
          ) : (
            <div>
              <Button
                id={`revokeAdminRightsButton${row._id}`}
                className='btn-danger'
                onClick={() => {
                  if (row._id !== 0) {
                    row._id !== parseInt(profile?.id)
                      ? toggleRevokeAdminModal(row)
                      : toggleSelfRevokeModal(row);
                  }
                }}
              >
                <i className='fa fa-user-times btn-icon' />
              </Button>
              <UncontrolledTooltip target={`revokeAdminRightsButton${row._id}`}>
                {row._id === 0
                  ? 'It is not possible to revoke admin rights from the root admin.'
                  : revokeRightsString}
              </UncontrolledTooltip>
              <DraggableModal
                isOpen={revokeAdminModal && revokeId === row._id}
                toggle={toggleRevokeAdminModal}
              >
                <ModalHeader>
                  <div className='confirm-modal-header'>
                    {revokeRightsString}
                  </div>
                </ModalHeader>
                <div className='confirm-modal-line' />
                <ModalBody className='confirm-modal-body'>
                  Are you sure you want to revoke admin rights of this user?
                  <FormGroup>
                    <Button
                      type='submit'
                      className='btn-confirm-delete mr-15'
                      onClick={() => {
                        toggleRevokeAdminModal();
                        onRevoke(row);
                      }}
                    >
                      Ok
                    </Button>
                    <Button
                      className='btn-cancel-delete ml-15'
                      onClick={() => {
                        toggleRevokeAdminModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </FormGroup>
                </ModalBody>
              </DraggableModal>
              <DraggableModal
                isOpen={selfRevokeModal && selfRevokeId === row._id}
                toggle={toggleSelfRevokeModal}
              >
                <ModalHeader>
                  <div className='confirm-modal-header'>
                    {revokeRightsString}
                  </div>
                </ModalHeader>
                <div className='confirm-modal-line' />
                <ModalBody className='confirm-modal-body'>
                  You are about to revoke your own admin rights. You won't be
                  able to perform any admin actions anymore and will be
                  redirected to the projects page. Are you sure you want to
                  proceed?
                  <FormGroup>
                    <br />
                    <Button
                      className='btn-cancel-delete mr-15'
                      onClick={() => {
                        toggleSelfRevokeModal();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      className='btn-confirm-delete ml-15'
                      onClick={() => {
                        toggleSelfRevokeModal();
                        onRevoke(row);
                        history.push('/projects');
                      }}
                    >
                      Ok
                    </Button>
                  </FormGroup>
                </ModalBody>
              </DraggableModal>
            </div>
          )}
          <Button
            id={`grantCreatorRightsButton${row._id}`}
            onClick={() => {
              if (row._id !== 0) {
                toggleGrantCreatorModal();
                setGrantCreatorRow(row);
              }
            }}
            className={isCreator(row) ? 'btn-danger' : 'btn-add'}
          >
            {isCreator(row) ? (
              <i className='fa fa-minus-square btn-icon' />
            ) : (
              <i className='fa fa-plus-square btn-icon' />
            )}
          </Button>
          <UncontrolledTooltip target={`grantCreatorRightsButton${row._id}`}>
            {row._id === 0
              ? 'It is not possible to revoke creator rights from the root admin.'
              : isCreator(row)
              ? 'Revoke creator rights'
              : 'Grant creator rights'}
          </UncontrolledTooltip>
        </Fragment>
      ),
    },
  ]);

  //Method for search input
  var options = profiles.map((profile) => profile.name);
  const [searchText, setSearchText] = React.useState('');
  const [resetPaginationToggle] = React.useState(false);
  const searchItems =
    profiles &&
    profiles?.filter((profile) =>
      profile.name.toString().toLowerCase().includes(searchText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <AutoCompleteInput
        options={options}
        inputValue={searchText}
        onInputChange={(e, newValue) => {
          setSearchText(newValue);
        }}
        label='Search ISAT User'
      />
    );
  }, [searchText, resetPaginationToggle]);

  useEffect(() => {
    sidebar();
  }, [isVisible, sidebar]);
  return loading || profiles === undefined ? (
    <Container>
      <Spinner className='spinner' />
    </Container>
  ) : (
    <Container fluid='md'>
      <div className='card-actions float-right'>
        <Button className='btn-add' onClick={toggleAddUserModal}>
          Add new ISAT user
        </Button>
        <RegisterModal
          modal={addUserModal}
          toggle={toggleAddUserModal}
          addUser={true}
        />
      </div>
      <CardTitle tag='h1' className='mb-0'>
        <h1>User Management</h1>
      </CardTitle>
      {registerError === true && (
        <Alert
          color='danger'
          isOpen={registerError}
          toggle={dismissRegisterError}
          style={{ width: '100%', marginLeft: 0 }}
        >
          {registerErrorMessage}
        </Alert>
      )}
      {!emailFormatAllowed(formData.mail) && (
        <Alert
          color='danger'
          style={{ width: '100%' }}
          isOpen={
            formData.mail === '' ? false : !emailFormatAllowed(formData.mail)
          }
        >
          Email address does not match the expected format.
        </Alert>
      )}
      {/* User Management Table  */}
      <div className='project_table'>
        <DataTable
          defaultSortAsc='_id'
          pagination
          columns={columns}
          persistTableHead
          data={searchItems}
          subHeader
          paginationResetDefaultPage={resetPaginationToggle}
          subHeaderComponent={subHeaderComponentMemo}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={
            <ExpandedUserManagementComponent data={searchItems} />
          }
          customStyles={CustomDataTableStyles}
        />
      </div>
      <CreatorModal
        modal={grantCreatorModal}
        toggle={toggleGrantCreatorModal}
        user={grantCreatorRow}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  sideBarVisible: state.sidebar.isVisible,
  projects: state.profile.adminProjects,
  profile: state.profile.profile,
  profiles: state.profile.profiles,
  loading: state.profile.loading,
  registerError: state.auth.registerError,
  registerErrorMessage: state.auth.registerErrorMessage,
  registered: state.auth.registered,
});

export default connect(mapStateToProps, {
  getProfiles,
  deleteUser,
  sidebar: withSidebar,
  setAlert,
  updateUser,
  revokeSelfAdmin,
  getAdminProjects,
  getAdminCatalogs,
  resetRegister,
  dismissRegisterError,
})(UserManagement);
