import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Input,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  EntityType,
  ProjectRole,
  ProjectType,
  RefTreeModType,
} from '../../constants/Enums';
import { ascending, descending } from '../../constants/StringConstants';
import { clearSearchIds } from '../../redux/actions/analysistree';
import { setEntity } from '../../redux/actions/entities';
import { getReftrees } from '../../redux/actions/profile';
import { SET_NODE_DATA } from '../../redux/actions/types';
import store from '../../redux/store';
import NodeDeletionModal from '../analysistree/nodetypes/nodeObjects/NodeDeletionModal';
import { EditConfirm } from '../analysistree/nodetypes/nodeObjects/NodeDetailsPage';
import RefTreeModificationModal from '../analysistree/RefTreeModificationModal';
import CopyReftreeModal from './EntityModals/CopyReftreeModal';
import { InputOptions } from './InputOptions';
import './table.css';

// reusable table component
const EntityTable = ({
  title,
  headers,
  data,
  project,
  setEntity,
  profile,
  reftrees,
  clearSearchIds,
}) => {
  // the colum headers
  const [columns, setColumns] = useState(headers);

  // filter properties
  const [filterProperties, setFilterProperties] = useState([]);

  // sort configuration
  const [sortConfig, setSortConfig] = useState({
    key: columns[0],
    direction: ascending,
  });

  // distinct value
  const [distinctValue, setDistinctValue] = useState(null);

  // table data
  const [tableRowData, setTableRowData] = useState([]);

  const [showFilter, setShowFilter] = useState(true);

  const history = useHistory();

  const { dispatch } = store;

  useEffect(() => {
    setColumns(headers);
  }, [headers]);

  useEffect(() => {
    if (reftrees === undefined) {
      dispatch(getReftrees(project?._id));
    }
  }, [reftrees]);

  // removes duplicate
  const removeDuplicates = (data, distinctValue) => {
    const tableData = data.filter((obj, pos, arr) => {
      return (
        arr
          .map((mapObj) => mapObj[distinctValue.field])
          .indexOf(obj[distinctValue.field]) === pos
      );
    });
    for (const row of tableData) {
      var hiddenRows = data.filter(
        (asset) => asset[distinctValue.field] === row[distinctValue.field]
      );
      if (hiddenRows.length > 1) {
        row[distinctValue.field] = `${row[distinctValue.field]?.toString()} (${
          hiddenRows.length
        }) `;
      }

      row.hiddenRows = hiddenRows.filter((datum) => datum !== row);

      row.showRows = false;
    }
    return tableData;
  };

  const selectOne = 'select-one';

  // filters data
  const filter = (data) => {
    var filteredData = [...data];
    for (let property of filterProperties) {
      if (property.propertyType === 'riskFilter') {
        if (property.propertyValue === 'all') {
          filteredData = filteredData.filter(
            (data) =>
              data.all_control_risk !== null &&
              data.all_control_risk !== undefined
          );
        }
        if (property.propertyValue === 'proposed') {
          filteredData = filteredData.filter(
            (data) =>
              data.proposed_control_risk !== null &&
              data.proposed_control_risk !== undefined
          );
        }
        if (property.propertyValue === 'implemented') {
          filteredData = filteredData.filter(
            (data) =>
              data.implemented_control_risk !== null &&
              data.implemented_control_risk !== undefined
          );
        }
        if (property.propertyValue === 'no_control') {
          filteredData = filteredData.filter(
            (data) =>
              data.no_control_risk !== null &&
              data.no_control_risk !== undefined
          );
        }
      }
      if (
        property.propertyType === 'text' ||
        property.propertyType === selectOne
      ) {
        filteredData = filteredData.filter((data) =>
          data[property.propertyName]
            ?.toString()
            ?.toLowerCase()
            ?.includes(property.propertyValue?.toLowerCase())
        );
      }
      if (property.propertyType === 'number') {
        filteredData = filteredData.filter((data) => {
          if (property.range1 !== null && property.range2 !== null) {
            var number = 0;
            number = data[property.propertyName];

            if (property.range1 > property.range2) {
              return property.range1 >= number && number >= property.range2;
            } else if (property.range1 < property.range2) {
              return property.range1 <= number && number <= property.range2;
            } else {
              return parseInt(property.range1) === parseInt(number);
            }
          } else {
            return true;
          }
        });
      }
    }
    return filteredData;
  };

  const cloneData = (data) => {
    // Convert the data into a string first
    var jsonString = JSON.stringify(data);

    //  Parse the string to create a new instance of the data
    return JSON.parse(jsonString);
  };

  const changeFilterProperties = (properties) =>
    setFilterProperties(properties);

  // sorts the data
  useEffect(() => {
    let sortableData = [];
    let newData = [];
    newData = cloneData(data);
    for (const row of newData) {
      if (Array.isArray(columns)) {
        for (const header of columns) {
          row[header?.field] = header?.value(row);
        }
      }
    }

    if (Array.isArray(newData)) {
      sortableData = [...newData];
      if (
        Array.isArray(sortableData) &&
        (sortableData[0]?.entity_type === EntityType.threat ||
          title === 'Threat Scenario')
      ) {
        let tempData = [];
        for (const datum of sortableData) {
          if (
            datum?.impact !== null &&
            datum?.impact !== undefined &&
            Object.keys(datum?.impact).length !== 0
          ) {
            const copies = Object.entries(datum?.impact).map((data) => ({
              ...datum,
              impact_level:
                ProjectType[project?.project_type]?.ImpactLevelTypes[
                  parseInt(data[1])
                ],
              impact_category: data[0],
              all_control_risk: datum.risk[data[0]]?.all_controls,
              no_control_risk: datum.risk[data[0]]?.no_controls,
              proposed_control_risk: datum.risk[data[0]]?.proposed_controls,
              implemented_control_risk:
                datum.risk[data[0]]?.implemented_controls,
            }));
            tempData = tempData.concat(copies);
          } else {
            tempData.push(datum);
          }
        }
        sortableData = tempData;
      }
      if (distinctValue !== null) {
        sortableData = removeDuplicates(sortableData, distinctValue);
      }

      if (filterProperties?.length > 0) {
        sortableData = filter(sortableData);
      }
    }
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key?.field] < b[sortConfig.key?.field]) {
          return sortConfig.direction === ascending ? -1 : 1;
        }
        if (a[sortConfig.key?.field] > b[sortConfig.key?.field]) {
          return sortConfig.direction === ascending ? 1 : -1;
        }
        return 0;
      });
    }
    setTableRowData(sortableData);
  }, [data, filterProperties, sortConfig, distinctValue, columns]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const impactLevel = urlParams.get('impact_level');
    const feasibility = urlParams.get('feasibility');
    const riskType = urlParams.get('risk');
    let properties = [];
    if (riskType !== null) {
      let filterProperty = {
        propertyName: 'risk',
        propertyType: 'riskFilter',
        propertyValue: riskType,
      };
      properties.push(filterProperty);
    }
    if (impactLevel !== null) {
      for (let property of document.getElementsByName('impact_level')) {
        property.value = impactLevel;
        let filterProperty = {
          propertyName: 'impact_level',
          propertyType: 'select-one',
          propertyValue: impactLevel,
        };
        properties.push(filterProperty);
      }
    }
    if (feasibility !== null) {
      if (riskType === 'all') {
        for (let property of document.getElementsByName(
          'all_control_feasibility'
        )) {
          property.value = feasibility;
          let filterProperty = {
            propertyName: 'all_control_feasibility',
            propertyType: 'select-one',
            propertyValue: feasibility,
          };
          properties.push(filterProperty);
        }
      } else if (riskType === 'proposed') {
        for (let property of document.getElementsByName(
          'proposed_control_feasibility'
        )) {
          property.value = feasibility;
          let filterProperty = {
            propertyName: 'proposed_control_feasibility',
            propertyType: 'select-one',
            propertyValue: feasibility,
          };
          properties.push(filterProperty);
        }
      } else if (riskType === 'implemented') {
        for (let property of document.getElementsByName(
          'implemented_control_feasibility'
        )) {
          property.value = feasibility;
          let filterProperty = {
            propertyName: 'implemented_control_feasibility',
            propertyType: 'select-one',
            propertyValue: feasibility,
          };
          properties.push(filterProperty);
        }
      } else if (riskType === 'no_control') {
        for (let property of document.getElementsByName(
          'no_control_feasibility'
        )) {
          property.value = feasibility;
          let filterProperty = {
            propertyName: 'no_control_feasibility',
            propertyType: 'select-one',
            propertyValue: feasibility,
          };
          properties.push(filterProperty);
        }
      } else {
        return;
      }
    }
    changeFilterProperties(properties);
  }, [data]);

  // checks if ascending or descending
  const requestSort = (key) => {
    let direction = ascending;
    if (sortConfig?.key === key && sortConfig.direction === ascending) {
      direction = descending;
    }
    setSortConfig({ key, direction });
  };

  const requestDistinct = (column, event) => {
    if (event.target.checked) {
      setDistinctValue(column);
    } else {
      setDistinctValue(null);
    }
  };

  // gets classname if header is ascending or descending
  const getClassNamesFor = (key) => {
    if (!sortConfig) {
      return undefined;
    }
    return sortConfig?.key === key ? sortConfig.direction : undefined;
  };

  // property of the filter
  const propertyChange = (property) => {
    var properties = [...filterProperties];
    if (property.target.value) {
      const index = properties.findIndex(
        (currentProperty) =>
          currentProperty.propertyName === property.target.name
      );

      if (index >= 0) {
        properties[index].propertyValue = property.target.value;
        if (property.target?.id === 'range1') {
          properties[index].range1 = property.target.value;
        }
        if (property.target?.id === 'range2') {
          properties[index].range2 = property.target.value;
        }
      } else {
        properties.push({
          propertyName: property.target.name,
          propertyType: property.target.type,
          propertyValue: property.target.value,
          range1:
            property.target.id === 'range1' ? property.target.value : null,
          range2:
            property.target.id === 'range2' ? property.target.value : null,
        });
      }
    } else {
      if (property.target.type === 'number') {
        const numberIndex = filterProperties.findIndex(
          (currentProperty) =>
            currentProperty.propertyName === property.target.name
        );
        if (property.target?.id === 'range1') {
          properties[numberIndex].range1 = null;
        }
        if (property.target?.id === 'range2') {
          properties[numberIndex].range2 = null;
        }
      } else {
        properties = filterProperties.filter(
          (currentProperty) =>
            currentProperty.propertyName !== property.target.name
        );
      }
    }
    changeFilterProperties(properties);
  };

  const clearFilters = () => {
    for (const header of columns) {
      for (const input of document.getElementsByName(header.field)) {
        input.value = '';
      }
    }
    changeFilterProperties([]);
  };

  // shows the hidden rows on click if there are distinct values
  const showRows = (index) => {
    const tableData = cloneData(tableRowData);
    tableData[index].showRows = !tableData[index]?.showRows;
    setTableRowData(tableData);
  };

  // for drag and drop
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newHeaderIds = Array.from(columns);
    var movedCol = newHeaderIds.find((header) => header.field === draggableId);
    newHeaderIds.splice(source.index, 1);
    newHeaderIds.splice(destination.index, 0, movedCol);
    const newColumn = [...newHeaderIds];
    setColumns(newColumn);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightgrey' : 'none',
    padding: grid,
    width: 250,
  });
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'var(--light-background)',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let headers = [];
    for (const col of columns) {
      headers.push(col.label);
    }
    headers = [headers];
    const body = tableRowData.map((data) => {
      const row = [];
      for (const col of columns) {
        row.push(data[col.field]);
      }
      return row;
    });

    const content = {
      startY: 50,
      head: headers,
      body: body,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save('report.pdf');
  };
  const goToNodeDetail = async (datum, fromEntities) => {
    await setEntity(datum);
    if (fromEntities) {
      history.push(
        `/${project._id}/analysisTree/node-details/${datum._id}?fromEntities=true&parentId=${datum.parent_node?._id}`
      );
    } else {
      history.push(
        `/${project._id}/analysisTree/node-details/${datum._id}?parentId=${datum.parent_node?._id}`
      );
    }
  };

  const editNodeDetail = async (datum) => {
    await setEntity(datum);
    history.push(
      `/${project._id}/analysisTree/node-details/${datum._id}?edit=true&parentId=${datum.parent_node?._id}`
    );
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const [nodeDeletionModal, openNodeDeletionModal] = useState(false);
  const toggleNodeDeletionModal = () =>
    openNodeDeletionModal((prevState) => !prevState);

  const [node, setNode] = useState(null);
  const deleteNode = (nodeToDelete) => {
    setNode(nodeToDelete);
    toggleNodeDeletionModal();
  };

  const [refTreeModWarningModal, openRefTreeModWarningModal] = useState(false);
  const toggleRefTreeModWarningModal = () =>
    openRefTreeModWarningModal((prevState) => !prevState);

  const [RefTreeCreation, openRefTreeCreation] = useState(false);
  const toggleRefTreeCreation = () =>
    openRefTreeCreation((prevState) => !prevState);

  const [editConfirm, setEditConfirm] = useState(false);
  const toggleEditConfirm = () => {
    setEditConfirm((prevState) => !prevState);
  };

  const [reftree, setReftree] = useState(null);
  const isAssetsPage =
    window.location.pathname.slice(-15) === 'entities/assets';
  const actionBtn = {};

  const actionButtons = (datum, index) => {
    return (
      <td>
        <ButtonGroup>
          <Button
            style={actionBtn}
            onClick={async () => {
              const url = `/${project._id}/analysisTree?focusNode=${datum?._id}`;
              await setEntity(datum);
              clearSearchIds();
              history.push(url);
            }}
            id={`showNodeButton${index}`}
          >
            <i className='fa fa-search' alt='Show Node' />
          </Button>
          <UncontrolledTooltip target={`showNodeButton${index}`}>
            Show Node
          </UncontrolledTooltip>
          <Button
            id={`detailsButton${index}`}
            style={actionBtn}
            onClick={() => {
              if (datum.owner.owner_type === 'reftree') {
                const reftreeDetails = reftrees.find(
                  (tree) => parseInt(tree._id) === parseInt(datum.owner.id)
                );
                setReftree(reftreeDetails);
                dispatch({
                  type: SET_NODE_DATA,
                  payload: datum,
                });
                goToNodeDetail(datum, true);
              } else {
                goToNodeDetail(datum);
              }
            }}
          >
            <i className='fa fa-info' alt='Show Node' />
          </Button>
          <UncontrolledTooltip target={`detailsButton${index}`}>
            Details
          </UncontrolledTooltip>
          {project?.participants?.find(
            (user) => parseInt(user.user_id) === parseInt(profile?.id)
          )?.role === ProjectRole.Owner.value ||
          project?.participants?.find(
            (user) => parseInt(user.user_id) === parseInt(profile?.id)
          )?.role === ProjectRole.Editor.value ? (
            <>
              <Button
                id={`editButton${index}`}
                style={actionBtn}
                onClick={() => {
                  setNode(datum);
                  if (datum.owner.owner_type === 'reftree') {
                    const reftreeEdit = reftrees.find(
                      (tree) => parseInt(tree._id) === parseInt(datum.owner.id)
                    );
                    setReftree(reftreeEdit);
                    if (reftreeEdit.tree_type === 'control') {
                      toggleEditConfirm();
                    } else {
                      toggleRefTreeModWarningModal();
                    }
                  } else {
                    editNodeDetail(datum);
                  }
                }}
              >
                <i className='fa fa-edit' />
              </Button>
              <UncontrolledTooltip target={`editButton${index}`}>
                Edit
              </UncontrolledTooltip>
            </>
          ) : null}

          {project?.participants?.find(
            (user) => parseInt(user.user_id) === parseInt(profile?.id)
          )?.role === ProjectRole.Owner.value ||
          project?.participants?.find(
            (user) => parseInt(user.user_id) === parseInt(profile?.id)
          )?.role === ProjectRole.Editor.value ? (
            <>
              <Button
                id={`deleteButton${index}`}
                style={actionBtn}
                className='btn-danger'
                onClick={() => deleteNode(datum)}
              >
                <i className='fa fa-trash' />
              </Button>
              <UncontrolledTooltip target={`deleteButton${index}`}>
                Delete
              </UncontrolledTooltip>
            </>
          ) : null}
        </ButtonGroup>
      </td>
    );
  };
  return (
    <>
      {columns?.length > 0 ? (
        <>
          <div style={{ marginBottom: '1rem', textAlign: 'right' }}>
            <Button
              className='btn-add'
              style={{
                marginLeft: isAssetsPage ? 10 : 'calc(50% - 70px)',
                width: 140,
              }}
              onClick={() => exportPDF()}
            >
              Export
            </Button>
            <Button onClick={() => toggleFilter()}>Filter</Button>
          </div>
          <Table style={{ background: 'none' }}>
            <DragDropContext
              onDragEnd={onDragEnd}
              style={{ position: 'sticky' }}
            >
              <thead>
                {' '}
                <Droppable droppableId='droppable' direction='horizontal'>
                  {(provided, snapshot) => (
                    <tr
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {columns.map((item, index) => (
                        <Draggable
                          key={item?.field}
                          draggableId={item?.field}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <td
                                id={item.field}
                                className='table-header'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Link
                                  onClick={() => {
                                    requestSort(item);
                                    getClassNamesFor(item);
                                  }}
                                  style={{
                                    color: '#003558',
                                  }}
                                >
                                  {item?.label}
                                </Link>{' '}
                                {sortConfig.key === item &&
                                  (sortConfig.direction === ascending ? (
                                    <FontAwesomeIcon
                                      icon={faArrowDown}
                                    ></FontAwesomeIcon>
                                  ) : (
                                    <FontAwesomeIcon icon={faArrowUp} />
                                  ))}
                              </td>
                              {item.field === 'assignment' ? (
                                <UncontrolledTooltip
                                  placement='right'
                                  target='assignment'
                                >
                                  A: Analysis Tree R: Reference Tree C:
                                  Catalogue Control The number in brackets
                                  indicates the number of visual displays of the
                                  corresponding node in the analysis tree.
                                </UncontrolledTooltip>
                              ) : null}
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <td
                        className='table-header'
                        style={{
                          padding: grid * 2,
                          margin: `0 0 ${grid}px 0`,
                          background: 'var(--light-background)',
                        }}
                      >
                        Actions
                      </td>
                    </tr>
                  )}
                </Droppable>
              </thead>
            </DragDropContext>

            <tbody>
              <tr hidden={showFilter}>
                {columns.map((field) => (
                  <>
                    <td style={{ paddingTop: 10, paddingBottom: 0 }}>
                      <div style={{ width: '100%' }}>
                        <InputOptions
                          type={field?.type}
                          name={field?.field}
                          inputChange={propertyChange}
                          options={field?.enum}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <Row
                          style={{
                            marginLeft: 20,
                            marginTop: 35,
                          }}
                        >
                          <Input
                            type='checkbox'
                            onChange={(event) => requestDistinct(field, event)}
                          />
                          Distinct values
                        </Row>
                      </div>
                    </td>
                  </>
                ))}

                <td>
                  <Button
                    id='clearFilter'
                    onClick={clearFilters}
                    style={actionBtn}
                  >
                    <i className='fa fa-times' />
                  </Button>
                  <UncontrolledTooltip target={'clearFilter'}>
                    Clear Filter
                  </UncontrolledTooltip>
                </td>
              </tr>

              {Array.isArray(tableRowData) &&
                tableRowData.map((datum, index) => (
                  <>
                    <tr key={index}>
                      {Array.isArray(columns)
                        ? columns.map((header) => (
                            <>
                              <td id={header.field}>
                                {typeof datum[header.field] === 'object'
                                  ? null
                                  : datum[header.field]?.toString()}
                              </td>
                              {header.field === 'assignment' ? (
                                <UncontrolledTooltip
                                  placement='right'
                                  target='assignment'
                                >
                                  A: Analysis Tree R: Reference Tree C:
                                  Catalogue Control The number in brackets
                                  indicates the number of visual displays of the
                                  corresponding node in the analysis tree.
                                </UncontrolledTooltip>
                              ) : null}
                            </>
                          ))
                        : null}
                      {actionButtons(datum, index)}
                      {datum.hiddenRows?.length > 0 && (
                        <td>
                          <Button onClick={() => showRows(index)}>
                            <FontAwesomeIcon
                              icon={datum.showRows ? faAngleUp : faAngleDown}
                            />
                          </Button>
                        </td>
                      )}
                    </tr>
                    {datum.showRows ? (
                      <>
                        {datum.hiddenRows.map((row, index) => (
                          <tr>
                            {columns.map((header) => (
                              <td id={header.field}>
                                {typeof row[header.field] === 'object'
                                  ? null
                                  : row[header.field]?.toString()}
                              </td>
                            ))}
                            {actionButtons(row, index)}
                          </tr>
                        ))}
                      </>
                    ) : null}
                  </>
                ))}
            </tbody>
          </Table>{' '}
        </>
      ) : (
        <h1>No columns to display. Please select some columns</h1>
      )}
      <NodeDeletionModal
        modal={nodeDeletionModal}
        toggle={toggleNodeDeletionModal}
        nodeData={node}
      />
      <RefTreeModificationModal
        type={RefTreeModType.Editing}
        modal={refTreeModWarningModal}
        toggle={toggleRefTreeModWarningModal}
        nodeData={node}
        fromEntities={true}
        nextToggle={toggleRefTreeCreation}
        confirmEdit={() => editNodeDetail(node)}
      />
      <CopyReftreeModal
        modal={RefTreeCreation}
        toggle={toggleRefTreeCreation}
        node={node}
        reftree={reftree}
        goToEdit={() => editNodeDetail(node)}
      />
      <EditConfirm
        toggle={toggleEditConfirm}
        modal={editConfirm}
        confirmEdit={() => editNodeDetail(node)}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  project: state.project.project,
  profile: state.profile.profile,
  reftrees: state.profile.userReftrees,
});
export default connect(mapStateToProps, { setEntity, clearSearchIds })(
  EntityTable
);
