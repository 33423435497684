import axios from '../../Axios/axios';
import {
  clearNodeParams,
  clearTreeParams,
  deleteEntityInTree,
  GetNodeIndexes,
  GetNodeWithIndexes,
  idMapping,
  InsertNodeToTree,
  RemoveNodeFromTree,
  setChildren,
  SetTreeLayout,
  updateEntityValueInTree,
  updateNodeChildrenInTree,
  UpdateReferenceTreeFromRoot,
} from '../../components/helpers/AnalysisTreeHelper';
import {
  copyPasteTemplate as copyMoveTemplate,
  entityUpdateTemplate,
} from '../../components/helpers/genericHelper';
import {
  CopyMoveType,
  EntityType,
  LikelihoodEstimation,
  LikelihoodMethod,
  OwnerType,
  RefTreeActionType,
  RefTreeModType,
} from '../../constants/Enums';
import store from '../store';
import { setAlert } from './alert';
import { entityDispatches } from './entities';
import { reloadAnalysisTree } from './projects';
import {
  ADD_NEW_REFTREE,
  CLEAR_REFTREE_MOD_PARAMS,
  CLEAR_SEARCH_IDS,
  DISPLAY_REFERENCE_TREES,
  LOAD_ASSETS,
  LOAD_ATTACKTREE,
  LOAD_CONTROLS,
  LOAD_ENTITIES,
  LOAD_THREATS,
  LOAD_VULNERABILITIES,
  SELECT_SUBTREE,
  SET_CONTROL_CATALOGS,
  SET_IMPACT_LEVEL_VIEWS,
  SET_PROJECT_CONTROL_CATALOG,
  SET_PROJECT_REFTREE_CATALOG,
  SET_REFTREES,
  SET_REFTREE_CATALOGS,
  SET_REFTREE_FEASIBILITY_VIEW,
  SET_REFTREE_MOD_PARAMS,
  SET_TREE_LAYOUT,
  TOGGLE_PLACEMENT_REVIEWS,
  TOGGLE_POPOVER,
  TOGGLE_REFTREE_MOD_WARNING,
  TOGGLE_TREE_ANIMATIONS,
  UNSELECT_SUBTREE,
  UPDATE_SEARCH_CRITERIA,
  UPDATE_SEARCH_IDS,
  VIEW_SELECTION_CHANGE,
} from './types';

//Toggles the attack tree popover displays
export const togglePopover = () => (dispatch) => {
  dispatch({
    type: TOGGLE_POPOVER,
  });
};

//Toggles the review buttons for attack tree nodes
//Adjusts the backend data accordingly as well
export const toggleReview =
  (entity, placementId = undefined) =>
  async (dispatch) => {
    const state = store.getState();
    let analysistree = Object.assign({}, state.analysistree.analysistree);
    const treeLayout = Object.assign({}, state.analysistree.treeLayout);
    const updatedEntity = Object.assign({}, entity);
    clearNodeParams(updatedEntity);
    let patchObject = undefined;
    if (placementId === undefined) {
      updatedEntity.review_state = !entity.review_state;
      patchObject = entityUpdateTemplate(entity.entity_type, {
        review_state: !entity.review_state,
      });
    } else {
      const updatedPlacementReviews = Object.assign(
        [],
        entity.reftree_placement_review_state
      );
      const reviewIndex = updatedPlacementReviews.findIndex(
        (placement) => placement.starting_node_id === placementId
      );
      //Toggles placement review state
      entity.reftree_placement_review_state[reviewIndex].review_state =
        !entity.reftree_placement_review_state[reviewIndex].review_state;
      //Updated patch object
      patchObject = entityUpdateTemplate(entity.entity_type, {
        reftree_placement_review_state:
          entity.reftree_placement_review_state[reviewIndex],
      });
    }
    axios.entity
      .patch(entity._id, patchObject)
      .then(() => {
        analysistree = updateEntityValueInTree(analysistree, updatedEntity);
        SetTreeLayout(analysistree, treeLayout);
        dispatch({
          type: LOAD_ATTACKTREE,
          payload: analysistree,
        });
      })
      .catch((error) => {
        dispatch(
          setAlert(
            `Error reviewing ${
              entity.entity_type
            } encountered. Error message: ${
              error?.response?.data?.msg ?? error?.response?.data?.message
            }`,
            'danger'
          )
        );
      });
  };

export const setEstimationMethodAutomatic = async (
  entity,
  analysistree,
  dispatch
) => {
  let patchObject = undefined;
  patchObject = entityUpdateTemplate(entity.entity_type, {
    likelihood_estimation_method:
      LikelihoodEstimation[LikelihoodMethod.Automatically],
  });
  if (entity.owner.owner_type === OwnerType.RefTree) {
    patchObject.change_reftree_version = false;
  }
  await axios.entity
    .patch(entity._id, patchObject)
    .then((response) => {
      const changedObjects = response.data;
      TreeObjectUpdate(changedObjects, dispatch, analysistree);
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error changing likelihood estimation. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const setControlCheckbox = (nodeData, payload) => async (dispatch) => {
  const state = store.getState();
  const analysistree = Object.assign({}, state.analysistree.analysistree);
  const treeLayout = Object.assign({}, state.analysistree.treeLayout);
  const patchObject = entityUpdateTemplate(EntityType.control, payload);

  if (nodeData.owner.owner_type === OwnerType.RefTree) {
    patchObject.change_reftree_version = false;
  }
  axios.entity
    .patch(nodeData._id, patchObject)
    .then((response) => {
      const changedObjects = response.data;
      TreeObjectUpdate(changedObjects, dispatch, analysistree);
      SetTreeLayout(analysistree, treeLayout);
      dispatch({
        type: LOAD_ATTACKTREE,
        payload: analysistree,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error updating control. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const updateComment =
  (entity, comment, index = null) =>
  async (dispatch) => {
    const state = store.getState();
    const analysistree = Object.assign({}, state.analysistree.analysistree);

    if (index === null) {
      const profile = state.profile.profile;

      const newComment = {
        user_id: profile.id,
        text: comment,
      };

      entity.comments.push(newComment);
    } else {
      if (entity.comments[index].text === comment) {
        return;
      }
      entity.comments[index].text = comment;
    }

    const updatedAttacktree = updateEntityValueInTree(analysistree, entity);

    dispatch({
      type: LOAD_ATTACKTREE,
      payload: updatedAttacktree,
    });
  };

export const deleteNode = (nodeData) => async (dispatch) => {
  const state = store.getState();
  const analysisTree = Object.assign({}, state.analysistree.analysistree);
  const refTreeModification = state.analysistree.refTreeModificationParams;
  //If it is a root node of a reftree, use a different route to remove the instance
  if (
    nodeData.owner.id !== nodeData?.parent?.owner?.id &&
    nodeData.entity_type !== EntityType.asset
  ) {
    await deleteReftreeInstance(
      nodeData.owner.id,
      nodeData._id,
      nodeData.parent._id,
      dispatch,
      analysisTree
    );
    dispatch({
      type: LOAD_ATTACKTREE,
      payload: analysisTree,
    });
  } else if (
    refTreeModification?.type === RefTreeModType.Deletion &&
    refTreeModification.params.node._id === nodeData._id
  ) {
    const rootNode = await ModificationAction(
      refTreeModification.action,
      refTreeModification.type,
      refTreeModification.params,
      dispatch
    );
    deleteEntityNode(dispatch, rootNode, analysisTree, refTreeModification);
  } else {
    deleteEntityNode(dispatch, nodeData, analysisTree);
  }
};

const deleteEntityNode = async (
  dispatch,
  nodeData,
  analysistree,
  ModAction = undefined
) => {
  axios.entity
    .delete(nodeData._id)
    .then((response) => {
      //Reattachment only occurs if reftree version has been updatedS
      if (ModAction !== undefined && response.data?.new_version !== undefined) {
        reattachRefTree(
          nodeData.owner.id,
          response.data.new_version,
          dispatch,
          nodeData?.reftreeParent
        );
        return;
      }
      const changedObjects = response.data.updated_entities;
      TreeObjectUpdate(changedObjects, dispatch, analysistree);

      analysistree = deleteEntityInTree(analysistree, nodeData);
      dispatch({
        type: LOAD_ATTACKTREE,
        payload: analysistree,
      });

      const state = store.getState();
      let entities = Object.assign([], state.entities.entities);

      if (entities.length > 0) {
        entities = entities.filter(
          (entity) => parseInt(entity._id) !== parseInt(nodeData._id)
        );
      }
      dispatch({
        type: LOAD_ENTITIES,
        payload: entities,
      });

      let loadType = undefined;
      let entityPayload = undefined;
      let entityList = undefined;
      switch (nodeData.entity_type) {
        case EntityType.asset:
          loadType = LOAD_ASSETS;
          entityList = state.assets.assets;
          break;
        case EntityType.threat:
          loadType = LOAD_THREATS;
          entityList = state.threats.threats;
          break;
        case EntityType.vulnerability:
          loadType = LOAD_VULNERABILITIES;
          entityList = state.vulnerabilities.vulnerabilities;
          break;
        case EntityType.control:
          loadType = LOAD_CONTROLS;
          entityList = state.controls.controls;
          break;
        default:
          break;
      }

      entityPayload = entityList.filter(
        (entity) => parseInt(entity._id) !== parseInt(nodeData._id)
      );
      dispatch({
        type: loadType,
        payload: entityPayload,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error deleting node. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const changeViewSelection = (selectionData) => async (dispatch) => {
  const selectionObject = {
    noControls: selectionData.noControls,
    implementedControls: selectionData.implementedControls,
    proposedControls: selectionData.proposedControls,
    allControls: selectionData.allControls,
  };

  dispatch({
    type: VIEW_SELECTION_CHANGE,
    payload: selectionObject,
  });
};

export const changeSearchObject = (searchObject) => async (dispatch) => {
  dispatch({
    type: UPDATE_SEARCH_CRITERIA,
    payload: searchObject,
  });
};

export const clearSearchIds = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_IDS,
  });
};

export const setSearchIds = (ids) => async (dispatch) => {
  dispatch({
    type: UPDATE_SEARCH_IDS,
    payload: ids,
  });
};

export const addSearchId = (id) => async (dispatch) => {
  const state = store.getState();
  let ids = Object.assign([], state.analysistree.searchIds);

  ids = ids.push(id);

  dispatch({
    type: UPDATE_SEARCH_IDS,
    payload: ids,
  });
};

export const cutSubtree = (subtree) => async (dispatch) => {
  dispatch({
    type: SELECT_SUBTREE,
    payload: {
      type: CopyMoveType.Move,
      subtree: subtree,
    },
  });
};

export const copySubtree = (subtree) => async (dispatch) => {
  dispatch({
    type: SELECT_SUBTREE,
    payload: {
      type: CopyMoveType.Copy,
      subtree: subtree,
    },
  });
};

//RefTreeModParams contains ref tree modification parameters, in case user wants to alter ref tree before pasting
export const pasteSubtree =
  (parent, RefTreeModParams = undefined) =>
  async (dispatch) => {
    const state = store.getState();
    const analysistree = Object.assign({}, state.analysistree.analysistree);
    const selectionType = state.analysistree.SelectedType;
    const selectedSubtree = state.analysistree.SelectedSubtree;
    const refTreeModification = state.analysistree.refTreeModificationParams;

    if (
      refTreeModification?.type === RefTreeModType.Transfer &&
      refTreeModification.params.node.owner.id !== parent.owner.id
    ) {
      reftreeTransfer(
        refTreeModification.params.node.parent,
        analysistree,
        refTreeModification,
        parent,
        dispatch
      );
      return;
    } else if (
      refTreeModification?.type === RefTreeModType.Transfer &&
      refTreeModification.params.node.owner.id === parent.owner.id
    ) {
      return;
    }
    //Removes data parameters from react-d3-tree package
    clearTreeParams(selectedSubtree);
    //Creates patch template for parent
    const payload = copyMoveTemplate(
      selectionType,
      selectedSubtree._id,
      parent._id
    );

    if (
      refTreeModification?.type === RefTreeModType.Cut &&
      refTreeModification.params.node._id === selectedSubtree._id
    ) {
      const newSubtree = await ModificationAction(
        refTreeModification.action,
        refTreeModification.type,
        refTreeModification.params,
        dispatch
      );
      payload.sourceId = newSubtree._id;
    }
    if (
      RefTreeModParams !== undefined &&
      parent.owner.owner_type === OwnerType.RefTree
    ) {
      const newParent = await ModificationAction(
        RefTreeModParams.action,
        RefTreeModParams.type,
        RefTreeModParams.params,
        dispatch
      );
      payload.destinationParentId = newParent._id;
    }

    axios.entity
      .post('', payload)
      .then((response) => {
        if (
          refTreeModification?.type === RefTreeModType.Cut &&
          refTreeModification.params.node._id === selectedSubtree._id
        ) {
          switch (refTreeModification.action) {
            case RefTreeActionType.NewSubtree:
            case RefTreeActionType.Edit:
              dispatch(reloadAnalysisTree());
              dispatch({
                type: UNSELECT_SUBTREE,
              });
              break;
            default:
              break;
          }
          return;
        }

        if (RefTreeModParams !== undefined) {
          if (RefTreeModParams.action === RefTreeActionType.NewSubtree) {
            //TODO: attach new version on this parent
          }
          dispatch(reloadAnalysisTree());
          dispatch({
            type: UNSELECT_SUBTREE,
          });
          return;
        }

        if (selectionType === CopyMoveType.Move) {
          //Removes node from previous location
          RemoveNodeFromTree(selectedSubtree._id, analysistree);
          //Applies to new location
          InsertNodeToTree(analysistree, selectedSubtree, parent._id);
        }
        if (selectionType === CopyMoveType.Copy) {
          const responseObject = response.data;
          const updatedSubtree = Object.assign(
            {},
            responseObject.changed_entities[responseObject.root_id]
          );
          const entities = Object.assign([], state.entities.entities);
          setChildren(
            [updatedSubtree],
            responseObject.changed_entities,
            false,
            entities
          );
          InsertNodeToTree(analysistree, updatedSubtree, parent._id);
        }
        dispatch({
          type: UNSELECT_SUBTREE,
        });
        dispatch({
          type: LOAD_ATTACKTREE,
          payload: analysistree,
        });
      })
      .catch((error) => {
        setAlert(
          error?.response?.data?.msg ?? error?.response?.data?.message,
          'danger'
        );
      });
  };

export const toggleReferenceTreeView = (bool) => async (dispatch) => {
  dispatch({
    type: DISPLAY_REFERENCE_TREES,
    payload: bool,
  });
};

export const addReferenceTreeToNode =
  (node, refTree, parentEstimationChange, projectCatalog = undefined) =>
  async (dispatch) => {
    const state = store.getState();
    const analysistree = Object.assign({}, state.analysistree.analysistree);

    if (parentEstimationChange) {
      await setEstimationMethodAutomatic(node, analysistree, dispatch);
    }
    let rootNode = refTree.current_version;
    let refTreeId = refTree._id;
    if (projectCatalog !== undefined) {
      const newReftree = await axios.refTree
        .put(`${refTree._id}/usage/`, {
          catalog_id: projectCatalog._id,
        })
        .then((response) => {
          const reftreeCopy = response.data.reftree_copy;
          let updatedProjectCatalog = undefined;
          if (response.data.reftree_copy.tree_type === 'control') {
            const controlCatalogs = Object.assign(
              [],
              state.profile.userControlCatalogs
            );
            updatedProjectCatalog = controlCatalogs.find(
              (cat) => cat._id === projectCatalog._id
            );
            updatedProjectCatalog.children = [
              ...updatedProjectCatalog.children,
              reftreeCopy._id,
            ];
            dispatch({ type: SET_CONTROL_CATALOGS, payload: controlCatalogs });
          } else if (response.data.reftree_copy.tree_type === 'vulnerability') {
            const reftreeCatalogs = Object.assign(
              [],
              state.profile.userRefTreeCatalogs
            );
            updatedProjectCatalog = reftreeCatalogs.find(
              (cat) => cat._id === projectCatalog._id
            );
            updatedProjectCatalog.children = [
              ...updatedProjectCatalog.children,
              reftreeCopy._id,
            ];
            dispatch({ type: SET_REFTREE_CATALOGS, payload: reftreeCatalogs });
          }

          const changedObjects = response?.data?.changed_entities;
          if (typeof changedObjects === 'object') {
            TreeObjectUpdate(changedObjects, dispatch, analysistree);
          }

          dispatch({
            type: ADD_NEW_REFTREE,
            payload: reftreeCopy,
          });

          //add reftree to catalog list
          return response.data.reftree_copy;
        });
      rootNode = newReftree.current_version;
      refTreeId = newReftree._id;
    }
    AttachRefTreeNodeToEntity(
      node,
      rootNode,
      refTreeId,
      analysistree,
      dispatch
    );
  };

// Create new ref tree
export const addReferenceTree = (formData, node) => async (dispatch) => {
  const payload = { name: formData.name };
  if (formData?.description !== undefined) {
    payload.description = formData?.description;
  }
  payload.catalog_id = parseInt(formData.catalog);

  //Create reftree
  return axios.refTree
    .put('', payload)
    .then(async (response) => {
      const newRefTree = response.data.new_reftree;
      const updatedCatalog = response.data.catalog;
      await AddEntityToReftree(
        newRefTree,
        updatedCatalog,
        node,
        dispatch,
        response.data.new_reftree.tree_type
      );
      return newRefTree;
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error creating Reference Tree. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const AddEntityToReftree = async (
  refTree,
  catalog,
  node,
  dispatch,
  treeType
) => {
  const state = store.getState();
  const project = state.project.project;
  const refTrees = Object.assign([], state.profile.userReftrees);
  const analysisTree = Object.assign({}, state.analysistree.analysistree);
  const userRefTreeCatalogs = Object.assign(
    [],
    state.profile.userRefTreeCatalogs
  );
  const userControlCatalogs = Object.assign(
    [],
    state.profile.userControlCatalogs
  );

  //Updates the project reference tree default catalog if it matches updated catalog
  if (treeType === 'control') {
    if (catalog?.project_id === project._id) {
      const controlCatalogs = Object.assign([], state.project.control_catalogs);
      const controlIndex = controlCatalogs.findIndex(
        (controlCatalog) => controlCatalog._id === catalog._id
      );
      controlCatalogs.splice(controlIndex, 1, controlCatalogs);
      //replace old catalog with new one
      dispatch({
        type: SET_PROJECT_CONTROL_CATALOG,
        payload: controlCatalogs,
      });
    }

    //Update specific catalog of user
    const index = userControlCatalogs.findIndex(
      (refCatalog) => refCatalog._id === catalog._id
    );
    userControlCatalogs.splice(index, 1, catalog);
    dispatch({
      type: SET_CONTROL_CATALOGS,
      payload: userControlCatalogs,
    });
  } else {
    if (catalog?.project_id === project._id) {
      const reftreeCatalogs = Object.assign([], state.project.reftree_catalogs);
      const reftreeindex = reftreeCatalogs.findIndex(
        (reftreeCatalog) => reftreeCatalog._id === catalog._id
      );
      reftreeCatalogs.splice(reftreeindex, 1, catalog);
      //replace old catalog with new one
      dispatch({
        type: SET_PROJECT_REFTREE_CATALOG,
        payload: reftreeCatalogs,
      });
    }

    //Updates the reference tree catalog of the user
    const index = userRefTreeCatalogs.findIndex(
      (refCatalog) => refCatalog._id === catalog._id
    );
    userRefTreeCatalogs.splice(index, 1, catalog);
    dispatch({
      type: SET_REFTREE_CATALOGS,
      payload: userRefTreeCatalogs,
    });
  }

  //Updates user's reftree list
  refTrees.push(refTree);
  dispatch({
    type: SET_REFTREES,
    payload: refTrees,
  });

  const nodePayload = copyMoveTemplate('add_to_reftree', node._id, refTree._id);
  //Add node to reftree(copy)
  axios.entity
    .post('', nodePayload)
    .then((response) => {
      UpdateReferenceTreeFromRoot(
        analysisTree,
        response.data.root_id,
        refTree._id
      );
      dispatch({
        type: LOAD_ATTACKTREE,
        payload: analysisTree,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error attaching root node to Reference Tree. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

const AttachRefTreeNodeToEntity = async (
  parentNode,
  rootId,
  reftreeId,
  analysisTree,
  dispatch,
  oldNode = undefined,
  newIndex = undefined
) => {
  const state = store.getState();
  let requestString = parentNode !== null ? parentNode._id : '';
  const refTreeModification = state.analysistree.refTreeModificationParams;
  if (
    refTreeModification?.type === RefTreeModType.Addition &&
    parentNode._id === refTreeModification.params.node._id
  ) {
    //Double check if root node is correct
    const newRootNode = await ModificationAction(
      refTreeModification.action,
      refTreeModification.type,
      refTreeModification.params,
      dispatch
    );
    requestString = newRootNode._id;
  }
  const addPayload = {
    entity_id: requestString,
    reftree_version: rootId,
  };
  //Attach reftree starting node to entity
  axios.refTree
    .post(`${reftreeId}/usage/`, addPayload)
    .then((response) => {
      const changedObjects = { ...response.data?.updated_entities };
      const reftreeEntities = response.data?.reftree_entities;
      if (reftreeEntities !== undefined) {
        //Gets root reftree entity
        const reftreeRoot = response.data.root_node;
        if (newIndex !== undefined) {
          reftreeEntities[reftreeRoot].index = newIndex;
        }
        //lupdates parent node with new children
        parentNode.children =
          parentNode?.children === undefined
            ? [reftreeRoot]
            : [reftreeRoot, ...parentNode.children];
        setChildren([parentNode], reftreeEntities);
        analysisTree = updateNodeChildrenInTree(analysisTree, parentNode);
      }

      if (typeof changedObjects === 'object') {
        TreeObjectUpdate(changedObjects, dispatch, analysisTree);
      }
      // Delete entity node
      if (oldNode !== undefined) {
        deleteEntityNode(dispatch, oldNode, analysisTree);
      }

      dispatch({
        type: LOAD_ATTACKTREE,
        payload: analysisTree,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error attaching Reference Tree to parent node. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const SetRefTreeParams =
  (type, action, nodeData) => async (dispatch) => {
    const paramdata = Object.assign({}, nodeData);
    dispatch({
      type: CLEAR_REFTREE_MOD_PARAMS,
    });
    dispatch({
      type: SET_REFTREE_MOD_PARAMS,
      payload: {
        type: type,
        action: action,
        params: { node: paramdata },
      },
    });
  };

export const ClearRefTreeParams = () => async (dispatch) => {
  dispatch({ type: CLEAR_REFTREE_MOD_PARAMS });
};

//returns new node for action
export const ModificationAction = async (
  action,
  type,
  params,
  dispatch,
  analysisTree = undefined
) => {
  switch (action) {
    case RefTreeActionType.Edit:
      //Return same id(no action is made)
      return params.node;
    case RefTreeActionType.NewSubtree:
      const parentEntity = GetNodeIndexes(params.node, true)?.projectEntityId;
      const replaceSuccess = await axios.refTree
        .delete(
          `${params?.node?.owner?.id}/usage/?method=replace&entity_id=${parentEntity}`
        )
        .then(async (response) => {
          return response.data;
        });
      idMapping(parentEntity, replaceSuccess.id_mapping, analysisTree);
      const changedObjects = replaceSuccess?.changed_entities;
      if (typeof changedObjects === 'object') {
        TreeObjectUpdate(changedObjects, dispatch, analysisTree);
      }
      return {
        _id: replaceSuccess.id_mapping[params.node._id],
      };
    case RefTreeActionType.NewReftree:
      return newReftreeModificationAction(params, dispatch, analysisTree);
    default:
      return undefined;
  }
};

export const newReftreeModificationAction = async (
  params,
  dispatch,
  analysisTree,
  deleteInstance = true
) => {
  //Get root node of reftree instance and parent, and indexes to locate original node
  const roots = GetNodeIndexes(params.node, true);
  const projectEntityRoot = roots.projectEntityId;
  const refTreeRoot = roots.refId;

  //Create copy of subtree
  const copyPayload = copyMoveTemplate(
    CopyMoveType.Copy,
    refTreeRoot,
    projectEntityRoot
  );
  const copyResponse = await axios.entity
    .post('', copyPayload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error creating reference tree instance copy. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

  //Create subtree from copy entities, and obtain original(updated) root node
  const subtree = Object.assign(
    {},
    copyResponse.changed_entities[copyResponse.root_id]
  );
  setChildren([subtree], copyResponse.changed_entities);
  const newRootNode = GetNodeWithIndexes(subtree, roots.indexes);
  //Remove old reftree instance
  const deletePayload = {
    method: 'remove',
    entity_id: projectEntityRoot,
  };
  if (deleteInstance) {
    await deleteReftreeInstance(
      params.node.owner.id,
      params.node._id,
      deletePayload.entity_id,
      dispatch,
      analysisTree
    );
    dispatch({
      type: LOAD_ATTACKTREE,
      payload: analysisTree,
    });
  }

  return {
    ...newRootNode,
    root_id: copyResponse.root_id,
  };
};

export const deleteReftreeInstance = async (
  reftreeId,
  rootNodeId,
  entityParentId,
  dispatch,
  analysisTree
) => {
  await axios.refTree
    .delete(`${reftreeId}/usage/?entity_id=${entityParentId}&method=remove`)
    .then((response) => {
      if (response?.id_mapping !== undefined) {
        idMapping(entityParentId, response?.id_mapping, analysisTree);
      }

      const changedObjects = response.data?.changed_entities;
      if (typeof changedObjects === 'object') {
        TreeObjectUpdate(changedObjects, dispatch, analysisTree);
      }

      analysisTree = deleteEntityInTree(
        analysisTree,
        { _id: rootNodeId },
        entityParentId
      );
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error deleting reference tree instance. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

export const toggleModificationWarning = () => async (dispatch) => {
  dispatch({
    type: TOGGLE_REFTREE_MOD_WARNING,
  });
};

export const reattachRefTree = async (
  reftreeId,
  versionId,
  dispatch,
  entityId = undefined
) => {
  const payload = {
    new_version: versionId,
  };
  if (entityId) {
    payload.entity_id = entityId;
  }
  await axios.refTree.patch(`${reftreeId}/usage/`, payload).catch((error) => {
    dispatch(
      setAlert(
        `Error updating reference tree version. Error message: ${
          error?.response?.data?.msg ?? error?.response?.data?.message
        }`,
        'danger'
      )
    );
  });

  dispatch(reloadAnalysisTree());
};

export const copyRefTree = async (formData, reftree, node) => {
  const { dispatch } = store;
  const payload = { name: formData.name };
  if (formData?.description !== undefined) {
    payload.description = formData?.description;
  }
  payload.catalog_id = parseInt(formData.catalog);
  const state = store.getState();
  const project = state.project.project;
  const entities = state.entities.entities;
  const refTreeModificationParams =
    state.analysistree.refTreeModificationParams;
  const newNode = createNewNode(entities, project, node, node.parent_node);
  //Create reftree
  axios.refTree
    .put('', payload)
    .then((response) => {
      const newRefTree = response.data.new_reftree;
      const updatedCatalog = response.data.catalog;
      const newPayload = {
        ...refTreeModificationParams,
        reftree: newRefTree,
        catalog: updatedCatalog,
        params: { node: newNode },
      };
      dispatch({
        type: SET_REFTREE_MOD_PARAMS,
        payload: newPayload,
      });
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error copying Reference Tree. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });
};

const createNewNode = (entities, project, newNode, rootParent = undefined) => {
  const parent = entities.find((entity) =>
    entity.children.some((child) => parseInt(child) === parseInt(newNode._id))
  );
  if (parent) {
    if (parent.owner.owner_type === OwnerType.Project) {
      if (rootParent === undefined) {
        newNode.parent = parent;
      } else {
        newNode.parent = rootParent;
        rootParent = undefined;
      }
    } else {
      newNode.parent = parent;
    }
    newNode.parent = createNewNode(
      entities,
      project,
      newNode.parent,
      rootParent
    );
  } else {
    newNode.parent = project;
  }
  return newNode;
};

export const togglePlacementReviews = () => (dispatch) => {
  dispatch({
    type: TOGGLE_PLACEMENT_REVIEWS,
  });
};

export const changeReftreeFeasibilityView = (type) => (dispatch) => {
  dispatch({
    type: SET_REFTREE_FEASIBILITY_VIEW,
    payload: type,
  });
};

export const updateImpactLevelView = (value) => (dispatch) => {
  const state = store.getState();
  const impactViews = Object.assign([], state.analysistree.impactViews);

  if (impactViews.includes(value)) {
    const index = impactViews.findIndex((view) => view === value);
    impactViews.splice(index, 1);
    if (impactViews.length === 0) {
      impactViews.push('0');
    }
  } else {
    impactViews.push(value);
  }
  dispatch({
    type: SET_IMPACT_LEVEL_VIEWS,
    payload: impactViews,
  });
};

export const loadReftreeFromId = (id) => async (dispatch) => {
  const reftree = await axios.refTree
    .get(id)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error getting Reference Tree. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

  if (reftree) {
    dispatch({
      type: ADD_NEW_REFTREE,
      payload: reftree,
    });
  }
};

export const toggleTreeAnimations = () => async (dispatch) => {
  dispatch({
    type: TOGGLE_TREE_ANIMATIONS,
  });
};

export const changeTreeLayout = (tree) => async (dispatch) => {
  dispatch({
    type: SET_TREE_LAYOUT,
    payload: tree,
  });
};

export const reftreeTransfer = async (
  parent,
  analysisTree,
  refTreeModification,
  newParent,
  dispatch
) => {
  await axios.refTree
    .delete(
      `${refTreeModification.params.node.owner.id}'/usage/?entity_id=${parent._id}
        &method=remove`
    )
    .then((response) => {
      if (response?.id_mapping !== undefined) {
        idMapping(parent._id, response?.id_mapping, analysisTree);
      }

      const changedObjects = response.data?.changed_entities;
      if (typeof changedObjects === 'object') {
        TreeObjectUpdate(changedObjects, dispatch, analysisTree);
      }

      analysisTree = deleteEntityInTree(
        analysisTree,
        { _id: refTreeModification.params.node._id },
        parent._id
      );
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error removing previous reference tree instance. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

  let requestString = newParent !== null ? newParent._id : '';
  if (
    refTreeModification?.type === RefTreeModType.Addition &&
    newParent._id === refTreeModification.params.node._id
  ) {
    //Double check if root node is correct
    const newRootNode = await ModificationAction(
      refTreeModification.action,
      refTreeModification.type,
      refTreeModification.params,
      dispatch
    );
    requestString = newRootNode._id;
  }
  const addPayload = {
    entity_id: requestString,
    reftree_version: refTreeModification.params.node._id,
  };
  //Attach reftree starting node to entity
  await axios.refTree
    .post(`${refTreeModification.params.node.owner.id}/usage/`, addPayload)
    .then((response) => {
      const changedObjects = { ...response.data?.updated_entities };
      const reftreeEntities = response.data?.reftree_entities;
      if (reftreeEntities !== undefined) {
        //Gets root reftree entity
        const reftreeRoot = parseInt(Object.keys(reftreeEntities)[0]);
        if (refTreeModification.params.node.index !== undefined) {
          reftreeEntities[reftreeRoot].index =
            refTreeModification.params.node.index;
        }
        //lupdates parent node with new children
        newParent.children =
          newParent?.children === undefined
            ? [reftreeRoot]
            : [reftreeRoot, ...newParent.children];
        setChildren([newParent], reftreeEntities);
        analysisTree = updateNodeChildrenInTree(analysisTree, newParent);
      }

      if (typeof changedObjects === 'object') {
        TreeObjectUpdate(changedObjects, dispatch, analysisTree);
      }
    })
    .catch((error) => {
      dispatch(
        setAlert(
          `Error adding new Reference Tree instance to parent node. Error message: ${
            error?.response?.data?.msg ?? error?.response?.data?.message
          }`,
          'danger'
        )
      );
    });

  dispatch({
    type: LOAD_ATTACKTREE,
    payload: analysisTree,
  });
  dispatch(ClearRefTreeParams());
};

const TreeObjectUpdate = (changedObjects, dispatch, analysisTree) => {
  if (typeof changedObjects === 'object') {
    Object.entries(changedObjects).map((entityArray) => {
      const entityObject = entityArray[1];
      entityObject._id = parseInt(entityArray[0]);
      return entityDispatches(
        dispatch,
        analysisTree,
        entityObject,
        undefined,
        true
      );
    });
  }
};
